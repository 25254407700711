import { Asterisk } from '../input-field/modules';
import ValidationMessage from '../validation-message';

function Label({ label, required, labelConfig }) {
    return <>
        {label}
        {required && <Asterisk />}

        {labelConfig.enableUpdateButton &&
            <span className='blank-btn text-orange fw400 ml-auto fs14' onClick={labelConfig.buttonOnClick}>
                <u>{labelConfig.buttonName}</u>
            </span>
        }
    </>;
}

function CustomSelect({ children, errors, touched, name, placeholder, required, label, labelClass, subLabel, subLabelClass, inputSubLabelClass, inputSubLabel, wrapperClass, inputWrapperClass, labelConfig, footer: Footer, value, options, onSelect, ...restProps }) {
    return (
        <div className={wrapperClass}>
            {label &&
                labelConfig.inPTag
                ? <p className={labelClass} htmlFor={name}>
                    <Label label={label} required={required} labelConfig={labelConfig} />
                </p>
                : <label className={labelClass} htmlFor={name}>
                    <Label label={label} required={required} labelConfig={labelConfig} />
                </label>
            }
            {subLabel &&
                <label className={subLabelClass}>{subLabel}</label>
            }
            <div className={inputWrapperClass}>
                {inputSubLabel &&
                    <label className={inputSubLabelClass}>{inputSubLabel}</label>
                }
                <select
                    value={value}
                    onChange={(event) => {
                        onSelect(event.target.value);
                    }}
                    {...restProps}
                >
                    {options.map(op => {
                        return <option value={op.value}> {op.label}</option>;
                    })}
                </select>
                {children || null}
            </div>
            {!!(errors?.[name] && touched?.[name]) && <ValidationMessage message={errors[name]} />}

            {Footer && <Footer />}
        </div>
    );
}

CustomSelect.defaultProps = {
    disabled: false,
    label: '',
    labelClass: '',
    subLabelClass: 'text-text',
    placeholder: '',
    required: false,
    wrapperClass: 'form-in',
    inputWrapperClass: 'f-in',
    labelConfig: { enableUpdateButton: false, buttonName: '', inPTag: false, buttonOnClick: () => { } }
};

export default CustomSelect;
