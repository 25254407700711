import { Modal, ModalBody } from 'react-bootstrap';
import ClaimEth from '../../../../wallet-transactions/claim/cliam';
import { truncateToDecimalDigits } from '../../../../../utils';
import { constants } from '../../../../../helpers';

/**
 * Component for displaying the claim ETH modal.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {boolean} props.isOpen - Indicates whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal close.
 * @param {Object} props.commonReducer - The common reducer data.
 * @param {Function} props.onContributeAnother - Function to handle contributing to another collective.
 * @returns {JSX.Element} - Rendered Component.
 */
function Component({ isOpen, onClose, commonReducer, onContributeAnother }) {
    return (
        <Modal className=' claim-modal' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                <div>
                    <i className='eth-icon'></i>
                </div>
                <h5 className='f20 mb0'>Claim ETH</h5>
            </Modal.Header>
            <ModalBody>
                <p className="lg mb24">You can contribute to another collective (no gas fee) or claim your ETH.</p>
                <p className="text-text lg m8">Your Claimable Contribution</p>

                <h5 className="m24">
                    <i className="etherium-icon lg va-2 mr4"></i>
                    {commonReducer.modal.collective?.goal?.myClaim
                        ? truncateToDecimalDigits({
                            number: commonReducer.modal.collective?.goal?.myClaim,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY,
                        })
                        : '-'}
                </h5>

                <button className="btn lg w-100 mb18" onClick={onContributeAnother}>
                    Contribute to another Collective
                </button>
                <ClaimEth details={commonReducer.modal.collective} />
                {/* Uncomment the below line to enable Claim ETH button */}
                {/* <button className="link "><u>Claim ETH</u></button> */}
            </ModalBody>
        </Modal>
    );
}

// Export the Component as the default export
export default Component;
