import cheerio from "cheerio";
import { useState } from "react";
import { getDataApi } from "../../api/methods";
import { WebPlaceholder } from "../../assets/images/images";
import { constants } from "../../helpers";
import useHandleCalls from "./useHandleCalls";

// Initial details for the website preview
const initialDetails = Object.freeze({
  title: "No Title",
  description: "No Description",
  image: WebPlaceholder,
});

/**
 * Custom hook for fetching website metadata and generating a preview.
 * @param {string} url - The URL of the website to fetch metadata from.
 * @returns {Object} - An object with the website metadata.
 */
function useWebsitePreview(url) {
  const [metadata, setMetadata] = useState(Object.assign({}, initialDetails));

  // Function to fetch website metadata using an API call.
  const fetchWebsiteMetadata = async () => {
    try {
      if (!url) return false;

      const response = await getDataApi({
        path: constants.API_PATHS.WEBSITE_PREVIEW,
        data: { url },
      });

      const html = response.data;
      const $ = cheerio.load(html);

      // Extract title, description, and image from the fetched HTML.
      const title = $("head title").text().trim();
      const description = $('head meta[name="description"]').attr("content");
      const image =
        $('head meta[property="og:image"]').attr("content") ||
        $("img").attr("src");

      return {
        title,
        description,
        image,
      };
    } catch (error) {
      console.error("Error fetching website metadata:", error);
      return null;
    }
  };

  // Function to fetch data and update the website metadata.
  const fetchData = async () => {
    const websiteMetadata = await fetchWebsiteMetadata();
    if (websiteMetadata) {
      const isValidImage = websiteMetadata.image
        ? websiteMetadata.image.startsWith("http://") ||
          websiteMetadata.image.startsWith("https://")
        : false;

      setMetadata({
        image: isValidImage ? websiteMetadata.image : WebPlaceholder,
        title: websiteMetadata.title || "No Title",
        description: websiteMetadata.description || "No Description",
      });
    } else {
      setMetadata(Object.assign({}, initialDetails));
    }
  };

  // Use the useHandleCalls custom hook to handle API calls with debounce.
  useHandleCalls(fetchData, [url]);

  return { metadata };
}

export default useWebsitePreview;
