"use client";
import {
  getContract,
} from "viem";
import messages from "../../../helpers/messages.js";
import { errorAlert } from "../../../utils";
import {
  collectionNFTCrowdfundAbi,
  collectiveCrowdfundFactoryAbi,
} from "../scripts";
import addresses from "../scripts/utils/addresses.json";
import config from "../../../Config.js";

const { DEPLOYED_COLLECTION_NFT_IMPL_ADDRESS, DEPLOYED_COLLECTIVE_CF_FACTORY_ADDRESS } = addresses[config["NETWORK"]];

function useCreateCollectionNFTCollective({
  _collectiveTitleName,
  _nftContractAddress,
  _fundraiseGoal,
  _crowdFundDuration,
  _lowerLimitInvestment,
  _upperLimitInvestment,
  _initialContributor,
  _initialContribution,
  _voteDuration,
  _vetoDuration,
  _passThresholdBps,
  _sellingPlatform,
  _sellingDuration,
  _sellingPrice,
  creator,
  walletClient,
  publicClient,
  onIsSubmitting,
}) {

  const callCreateCollectionNFTCrowdfund = async () => {
    try {
      const collectiveFactory = getContract({
        address: DEPLOYED_COLLECTIVE_CF_FACTORY_ADDRESS,
        abi: collectiveCrowdfundFactoryAbi,
        publicClient,
        walletClient
      });
      const collectionNFTIMPL = getContract({
        address: DEPLOYED_COLLECTION_NFT_IMPL_ADDRESS,
        abi: collectionNFTCrowdfundAbi,
        publicClient,
        walletClient
      });

      const opts = collectionNFTIMPL.CollectionNFTCrowdfundOptions = {
        collectiveTitleName: _collectiveTitleName,
        nftContractAddress: _nftContractAddress,
        fundraiseGoal: _fundraiseGoal,
        crowdFundDuration: 900,
        lowerLimitInvestment: _lowerLimitInvestment,
        upperLimitInvestment: _upperLimitInvestment,
        initialContributor: _initialContributor,
        initialContribution: _initialContribution,
        // feeRecipient: "0x73C6D4A841dAb46BF11F7eBa1E379cD087B95CE4",
        // feeBps: 250,
        governanceOpts: {
          voteDuration: _voteDuration,
          vetoDuration: _vetoDuration,
          passThresholdBps: _passThresholdBps,
          // sellingPlatform: _sellingPlatform,
          // sellingDuration: _sellingDuration,
          // sellingPrice: _sellingPrice,
        }
      };

      // const opts = {
      //   collectiveTitleName: "Name",
      //   crowdFundDuration: "86400",
      //   fundraiseGoal: "2000000000000",
      //   governanceOpts: {
      //     voteDuration: "604800",
      //     vetoDuration: "86400",
      //     passThresholdBps: "8000",
      //   },
      //   initialContribution: "400000000000",
      //   initialContributor: "0x14e01052120d71c8ad1b223cE237011C29E4110C",
      //   lowerLimitInvestment: "60000000000",
      //   nftContractAddress: "0xf4910c763ed4e47a585e2d34baa9a4b611ae448c",
      //   // nftTokenId:
      //   //   "9442143354734013196175422139429806940928267843094252125628337928942726938625",
      //   upperLimitInvestment: "400000000000",
      // };
      console.log("callCreateCollectionNFTCrowdfund-opts", opts);

      // if (opts.upperLimitInvestment <= (20n * opts.fundraiseGoal) / 100n &&
      //   opts.initialContribution >= (25n * opts.fundraiseGoal) / 1000n && opts.initialContribution <= (20n * opts.fundraiseGoal) / 100n &&
      //   opts.governanceOpts.passThresholdBps <= BigNumber(1e4) && opts.governanceOpts.passThresholdBps >= BigNumber(5e3)) {

      const createCollectionNFTCollective_tx = await collectiveFactory.write.createCollectionNFTCrowdfund({
        args: [opts], value: opts.initialContribution
      });
      console.log("callCreateCollectionNFTCrowdfund-createCollectionNFTCollective_tx", createCollectionNFTCollective_tx);

      return createCollectionNFTCollective_tx;
      // const txReceipt = await publicClient.waitForTransactionReceipt(
      //   { hash: createCollectionNFTCollective_tx }
      // );
      // console.log("txReceipt", txReceipt);
      // try {
      //     let eventSelector = getEventSelector("CollectionNFTCrowdfundCreated");
      //     const logs = txReceipt.logs;
      //     const filteredLog = logs.filter((log) => log.topics[0] == eventSelector);
      //     const decodedEvent = decodeEventLog({
      //         abi: collectiveCrowdfundFactoryAbi,
      //         ...filteredLog
      //     })
      //     console.log("decodedEvent", decodedEvent);
      // } catch (error) {
      //     console.log("problem in decoding event", error);
      // }
      // }
    } catch (error) {
      console.log("callCreateCollectionNFTCrowdfund-error", error);
      onIsSubmitting(false);
      errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
    }
  };

  return { callCreateCollectionNFTCrowdfund };
}

export default useCreateCollectionNFTCollective;
