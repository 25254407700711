import { Country } from "country-state-city";
import { GoogleAuthProvider } from 'firebase/auth';
import { useCallback, useEffect, useState } from 'react';
import config from "../../../../../Config.js";
import constants from '../../../../../helpers/constants.js';
import { onSetCanResendOtp, onToggleModal } from '../../../../../redux/features/commonSlice/index.js';
import { userRegistration } from '../../../../../redux/services/auth.js';
import { capitalizeFirstLetter, gmtOffsetToUTC } from '../../../../../utils';
import { useAppDispatch, useAppNavigate, useAppSelector, useFingerprint, useRefreshPage } from '../../../../custom-hooks';
import getFormikValidationSchema from '../../../../formik-validations';
import Component from './Component';
import { handleGoogleLogin } from './googleLogin.js';

const initialState = {
    username: '',
    email: '',
    password: '',
    phoneNumber: '',
    countryCode: 'us',
    isShowingPassword: false,
    enableNotifications: false,
    acceptTerms: false,
    referralCode: ''
};

function SignUp(props) {
    const commonReducer = useAppSelector(state => state.common);
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const deviceId = useFingerprint();
    const countries = Country.getAllCountries();
    const { onRefreshViewCollectivePage } = useRefreshPage();

    const googleProvider = new GoogleAuthProvider();

    const [countryName, setCountryName] = useState('');

    googleProvider.setCustomParameters({
        display: 'popup',
    });

    // const signInWithGoogle = () => {
    //     return signInWithPopup(authentication, googleProvider)
    //         .then((res) => {
    //             console.log(res.user)
    //             return res.user;
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //             // Utils.alert(
    //             //     Constants.alertType.error,
    //             //     firebaseErrorMessages[error.code] ||
    //             //     error.message.replace('Firebase: ', '').replace(/\(auth.*\)\.?/, '')
    //             // );
    //             return error.message;
    //         });
    // };

    // const handleGoogleLogin = async () => {
    //     const response = await signInWithGoogle();
    //     if (response.providerData) {
    //         const request = {
    //             email: response.providerData[0].email || "",
    //             referralCode: commonReducer?.modal?.referral?.code,
    //             socialId: response.providerData[0].uid,
    //             deviceId: deviceId,
    //             deviceToken: "string",
    //             notify: false,
    //             callback: (response) => {
    //                 if (response?.statusCode === 200 || response?.statusCode === 201) {
    //                     dispatch(onToggleModal());
    //                     navigate(constants.BROWSER_ROUTES.DISCOVER);
    //                     if (response.message) {
    //                         successAlert("Success!");
    //                     }
    //                 } else if (response?.data?.message) {
    //                     errorAlert(response?.data?.message)
    //                 }
    //             },
    //         };
    //         dispatch(googleAuth(request));
    //     }
    // };

    const schema = getFormikValidationSchema('signUp');
    const { signUp: userDetails, referral } = useAppSelector(state => state.common.modal);
    const [detail, setDetails] = useState(JSON.parse(JSON.stringify(initialState)));

    const handleSubmit = useCallback((data, { setSubmitting, setErrors }) => {
        // disabled save changes before trigger api call
        setSubmitting(true);

        const dataToSave = {
            "userName": data.username,
            "email": data.email,
            "password": data.password,
            "deviceId": deviceId,
            // "deviceToken": "string",
            pushNotificationStatus: data.enableNotifications,
            notify: false,
            callback: (response) => {
                if (response?.statusCode === 201) {
                    dispatch(onToggleModal({ modalType: constants.MODAL.SIGN_UP_VERIFY_EMAIL }));

                    // allowing to resend otp
                    setTimeout(() => {
                        dispatch(onSetCanResendOtp({ canResendOtp: true }));
                    }, constants.RESEND_OTP_TIME_LIMIT.REGISTER);
                } else if (response.data.type === 'EMAIL_ALREADY_EXIST' && response.data.message) {
                    setErrors({ email: capitalizeFirstLetter(response.data.message) });
                } else if (response.data.type === "USER_ALREADY_EXIST" && response.data.message) {
                    setErrors({ username: capitalizeFirstLetter(response.data.message) });
                }
                // enabled save changes after api success
                setSubmitting(false);
            }
        };
        if (data.countryCode && data.phoneNumber) {
            dataToSave.countryCode = data.countryCode;
            dataToSave.fullMobileNo = String(data.phoneNumber);
        }
        if (data.referralCode) {
            dataToSave.referralCode = data.referralCode;
        }
        if (countryName) {
            dataToSave.country = countryName;
        }
        dispatch(userRegistration(dataToSave));
    }, [countryName, deviceId, dispatch]);

    const handleContentModal = useCallback((modalType) => {
        dispatch(onToggleModal({ modalType }));
    }, [dispatch]);

    useEffect(() => {
        if (userDetails.userId) {
            setDetails({
                username: userDetails.username,
                email: userDetails.email,
                password: userDetails.password,
                phoneNumber: userDetails.phoneNumber,
                countryCode: userDetails.countryCode,
                enableNotifications: userDetails.enableNotifications,
                acceptTerms: userDetails.acceptTerms
            });
        }
    }, [userDetails]);

    useEffect(() => {
        if (referral && Object.keys(referral).length) {
            setDetails({
                ...userDetails,
                referralCode: referral.code || config.REFERRAL_CODE,
                email: referral.email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referral]);

    useEffect(() => {
        if (countries?.length) {
            const offset = new Date().getTimezoneOffset();
            const utcOffset = gmtOffsetToUTC(offset);
            const val = countries.find(country => country.timezones?.[0]?.gmtOffsetName.includes(utcOffset));
            setCountryName(val.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countries]);

    return <Component
        {...props}
        initialState={detail}
        schema={schema}
        dispatch={dispatch}
        deviceId={deviceId}
        navigate={navigate}
        commonReducer={commonReducer}
        onContentModal={handleContentModal}
        onSubmit={handleSubmit}
        handleGoogleLogin={handleGoogleLogin}
        onRefreshViewCollectivePage={onRefreshViewCollectivePage}
    />;
}

export default SignUp;
