import { useMemo } from "react";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { addButtonLoader } from "../../../utils";
import { ConnectWallet } from "../../components";
import { useUpdateVotingDelegation } from "../../custom-hooks";
import useDelegateVoteCollectionNFTEthCollective from "./useDelegateVoteCollectionNFTEthCollective";
import useDelegateVoteSingleNFTEthCollective from "./useDelegateVoteSingleNFTEthCollective";

function VotingDelegation(props) {
  const {
    isCollectionType,
    isDisabledSubmit,
    isSubmitting,
    votingDelegationId,
    resetForm,
    onIsSubmitting,
  } = props;

  const { data: walletClient } = useWalletClient();
  const { data: publicClient } = usePublicClient();

  const { address } = useAccount();

  const buttonClassName = useMemo(() => {
    return addButtonLoader({
      className: "btn icon w-100",
      isLoading: isSubmitting,
    });
  }, [isSubmitting]);

  const { addUpdateVotingDelegation } = useUpdateVotingDelegation({
    votingDelegationId,
    resetForm,
    onIsSubmitting,
  });

  const hookProps = {
    ...props,
    walletClient,
    publicClient,
  };

  const { callUpdateOnlyDelegate: callUpdateOnlyDelegateCollectionNFT } =
    useDelegateVoteCollectionNFTEthCollective(hookProps);
  const { callUpdateOnlyDelegate: callUpdateOnlyDelegateSingleNFT } =
    useDelegateVoteSingleNFTEthCollective(hookProps);

  const callUpdateOnlyDelegate = isCollectionType
    ? callUpdateOnlyDelegateCollectionNFT
    : callUpdateOnlyDelegateSingleNFT;

  if (!address) {
    return (
      <ConnectWallet
        type="voting-delegation"
        disabled={isDisabledSubmit}
        buttonClassName={buttonClassName}
      />
    );
  }

  return (
    <button
      className={buttonClassName}
      disabled={isDisabledSubmit}
      onClick={() => {
        onIsSubmitting(true);
        callUpdateOnlyDelegate().then((result) => {
          addUpdateVotingDelegation(result);
        });
      }}
    >
      Save Changes
    </button>
  );
}

export default VotingDelegation;
