import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Notifications from "../../../../common/components/user-profile/modules/notification";
import { ViewOutsideClick, useAppDispatch, useAppSelector } from "../../../../common/custom-hooks";
import { constants } from "../../../../helpers";
import { onToggleModal } from "../../../../redux/features/commonSlice";

function NavigationBar({ isOpen, onToggleMenu, onForceReRender }) {
    const dispatch = useAppDispatch();
    const { isloggedin } = useAppSelector(state => state.auth);

    const navLinks = useMemo(() => constants.DISCOVER.NAVBAR.LINKS({ profile: isloggedin }), [isloggedin]);

    const [navType, setNavType] = useState(navLinks.EVENTS.title);

    const handleSetNavType = useCallback((type) => {
        setNavType(type || '');
    }, []);

    const handleNavTypeOnClick = useCallback((event, link) => {
        event.preventDefault();
        if (link) {
            if (link.title === navType) {
                onToggleMenu(true);
                return false;
            }

            if (link.canAccess) {
                handleSetNavType(link.title);
                onToggleMenu(true);
            } else {
                // const profileLink = navLinks.PROFILE || navLinks.DASHBOARD;
                if (link.title === navLinks.PROFILE.title || link.title === navLinks.DASHBOARD.title) {
                    onForceReRender();
                    dispatch(onToggleModal({ modalType: constants.MODAL.SIGN_UP }));
                }
            }
        }
    }, [dispatch, navType, navLinks, onForceReRender, onToggleMenu, handleSetNavType]);

    useEffect(() => {
        if (window.location.pathname) {
            const link = Object.values(navLinks).find(nav => {
                return nav.path === window.location.pathname ||
                    nav.subMenu?.find(smenu => smenu.path === window.location.pathname);
            });
            if (link?.canAccess) {
                handleSetNavType(link.title);
            } else {
                handleSetNavType();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    return <div className='navigation'>
        <ViewOutsideClick isOpen={isOpen} onElementClick={() => onToggleMenu(true)}>
            <nav className='nav'>
                {Object.values(navLinks).map(link => {
                    if (!link) return null;

                    return <li key={link.title} onClick={(e) => handleNavTypeOnClick(e, link)}>
                        <Link className={(link.title === navType) ? "active" : ''} to={link.canAccess ? link.path : '#'}>
                            {link.title}
                        </Link>
                    </li>;
                })}

                {isloggedin &&
                    <li>
                        <Notifications />
                    </li>
                }
            </nav>
        </ViewOutsideClick>
    </div>;
}

export default NavigationBar;
