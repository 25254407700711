/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import Component from './Component';
import { constants } from '../../../../../helpers';
import { Onboarding1, Onboarding2, Onboarding3 } from '../../../../../assets/images/images';

const carouselItems = [
    {
      id: 1,
      image: Onboarding1,
      heading: "Collect",
      subHeading: "Collect the NFTs you’ve always wanted"
    },
    {
        id: 2,
        image: Onboarding2,
        heading: "Co-Own",
        subHeading: `Share ownership of any NFT with as little as .01 ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`
      },
      {
        id: 3,
        image: Onboarding3,
        heading: "Earn",
        subHeading: "Your NFT can be used to earn money by selling and more through MyMetaFi"
      },
  ];

function OnBoarding(props) {
    const [index, setIndex] = useState(0);

    const handleNext = () => {
      setIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    };
  
    const handleSkip = () => {
      // Assuming you want to skip 2 items, you can adjust this as needed
      setIndex(() => (2) % carouselItems.length);
    };

    return <Component
        {...props}
        carouselItems={carouselItems}
        index={index}
        onNext={handleNext}
        onSkip={handleSkip}
    />;
}

export default OnBoarding;
