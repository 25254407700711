import { useEffect } from 'react';
import { constants } from '../../../../../helpers';
import { useAppNavigate } from '../../../../custom-hooks';
import Component from './Component';

let timer = null;

function ResetPasswordSuccess(props) {
    const navigate = useAppNavigate();

    useEffect(() => {
        timer = setTimeout(() => {
            navigate(constants.BROWSER_ROUTES.DISCOVER);
        }, 3 * 1000);
    }, [navigate]);

    return <Component {...props} timer={timer} />;
}

export default ResetPasswordSuccess;
