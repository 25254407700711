import { regex } from "../helpers";

/**
 * Checks if the given URL matches an OpenSea collection URL pattern.
 * 
 * @param {string} value - The URL to be checked.
 * @returns {boolean} - Whether the URL matches the pattern or not.
 */
export function checkOpenSeaUrl(value) {
  return (
    // regex.OPENSEA_COLLECTION_URL.test(value) ||
    regex.TESTNETS_OPENSEA_COLLECTION_URL.test(value)
  );
}

/**
 * Checks if the given URL matches an OpenSea specific NFT URL pattern.
 * 
 * @param {string} value - The URL to be checked.
 * @returns {boolean} - Whether the URL matches the pattern or not.
 */
export function checkSpecificNftUrl(value) {
  return (
    // regex.OPENSEA_SPECIFIC_NFT_URL.test(value) ||
    regex.TESTNETS_OPENSEA_SPECIFIC_NFT_URL.test(value)
  );
}
