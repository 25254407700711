/**
 * Stores a value in the browser's localStorage.
 *
 * @param {string} key - The key under which the value will be stored.
 * @param {any} value - The value to be stored.
 */
export function localStorageSet(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    // Handle errors if the browser's localStorage is full or disabled
    console.error("Error storing data in localStorage:", error);
  }
}

/**
 * Retrieves a value from the browser's localStorage.
 * 
 * @param {string} key - The key of the value to retrieve.
 * @returns {any|string} - The retrieved value or an empty string if an error occurs.
 */
export function localStorageGet(key) {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : "";
  } catch (error) {
    // Handle errors if the data cannot be parsed
    console.error("Error retrieving data from localStorage:", error);
    return "";
  }
}

/**
 * Removes a value from the browser's localStorage.
 * 
 * @param {string} key - The key of the value to be removed.
 */
export function localStorageRemove(key) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    // Handle errors if the key cannot be removed
    console.error("Error removing data from localStorage:", error);
  }
}
