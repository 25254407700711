import { Asterisk } from "../../../../common/components";
import { CategoriesView } from './modules';

function NFTCategory({ categories, onSelectCategory }) {
    const categoriesViewProps = {
        allCategories: categories,
        onSelectCategory
    };

    return <div className='collect-in nft-cate'>
        <p className='lg m20'>NFT Category<Asterisk /> </p>

        <div className='form-row'>
            <div className='col-md-6'>
                <CategoriesView {...categoriesViewProps} categories={categories.slice(0, Math.ceil(categories.length / 2))} />
            </div>
            <div className='col-md-6'>
                <CategoriesView {...categoriesViewProps} categories={categories.slice(Math.ceil(categories.length / 2))} />
            </div>
        </div>
    </div>;
}

export default NFTCategory;
