import { useMemo } from "react";
import { useAccount } from "wagmi";
import useReduxStore from "./useReduxStore";

function useCustomWallet() {
  const account = useAccount();
  const { authReducer } = useReduxStore();

  const isWalletConnected = useMemo(() => {
    return !!account.address && authReducer.isloggedin;
  }, [account.address, authReducer.isloggedin]);

  return { address: account.address, isWalletConnected };
}

export default useCustomWallet;
