import { useMemo } from "react";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
// import { constants } from "../../../helpers";
// import {
//   onToggleModal,
//   setReadyForBuyNft,
// } from "../../../redux/features/commonSlice";
import { getDataApi } from "../../../api/methods";
import { constants, messages } from "../../../helpers";
import { addButtonLoader, errorAlert } from "../../../utils";
import { ConnectWallet } from "../../components/index.js";
import {
  // useAppDispatch,
  useBuyNft,
  // useReduxStore
} from "../../custom-hooks";
import useBuyFromCollection from "./useBuyFromCollection";
import useBuySingle from "./useBuySingle";

function BuyNft(props) {
  const {
    isCollectionType,
    isDisabledSubmit,
    data,
    isSubmitting,
    buttonConfig,
    // buyNftList,
    isValidPrice,
    onIsSubmitting,
  } = props;

  // const dispatch = useAppDispatch();
  // const { commonReducer } = useReduxStore();
  const { data: walletClient } = useWalletClient();
  const { data: publicClient } = usePublicClient();

  const { address } = useAccount();

  const buttonClassName = useMemo(() => {
    return addButtonLoader({
      className: "btn icon w-100 lg",
      isLoading: isSubmitting,
    });
  }, [isSubmitting]);

  const { onBuyNft } = useBuyNft({
    data,
    onIsSubmitting,
  });

  const hookProps = {
    ...props,
    walletClient,
    publicClient,
  };

  const { callBuy: callBuySingleNFT } = useBuySingle(hookProps);
  const { callBuy: callBuyCollectionNFT } = useBuyFromCollection(hookProps);

  const callBuy = isCollectionType ? callBuyCollectionNFT : callBuySingleNFT;

  // const disabledBuyNft = useMemo(() => {
  //   return (
  //     window.location.pathname !== constants.BROWSER_ROUTES.BUY_NFT &&
  //     !commonReducer.isReadyForBuyNft &&
  //     (!isCollectionType ||
  //       (isCollectionType && (buyNftList?.length === 1 || !buyNftList?.length)))
  //   );
  // }, [isCollectionType, buyNftList, commonReducer]);

  if (!address) {
    return (
      <ConnectWallet
        type="buy-nft"
        disabled={isDisabledSubmit}
        buttonConfig={buttonConfig}
      />
    );
  }

  return (
    <div>
      <button
        className={buttonClassName}
        disabled={isDisabledSubmit}
        onClick={() => {
          // if (disabledBuyNft) {
          //   dispatch(
          //     setReadyForBuyNft({
          //       selectedNft: props.selectedNft,
          //       collectiveDetails: props.details,
          //     })
          //   );
          //   dispatch(
          //     onToggleModal({
          //       modalType: constants.MODAL.TOTAL_CONTRIBUTION_VIEW,
          //     })
          //   );
          // } else {
          getDataApi({
            path: constants.API_PATHS.USER_COLLECTIVE_CHECK_NFT,
            data: {
              contractAddress: props.data.contractAddress || "",
              tokenId: props.data.tokenId || "",
              callback: (response) => {
                onIsSubmitting(true);
                const statusCode =
                  response?.statusCode || response?.data?.statusCode;
                const message = response?.message || response?.data?.message;
                if (statusCode === 200) {
                  if (isValidPrice) {
                    callBuy().then((result) => {
                      onBuyNft(result);
                    });
                  } else {
                    onIsSubmitting(false);
                    errorAlert(messages.COLLECTIVE.NFT_VALUE_EXCEEDS_GOAL);
                  }
                } else if (message) {
                  onIsSubmitting(false);
                  errorAlert(message);
                }
              },
            },
          });
          // }
        }}
      >
        {/* {disabledBuyNft && !isDisabledSubmit && isValidPrice
          ? "Ready to Buy"
          : buttonConfig.title} */}
        {buttonConfig.title}
      </button>
    </div>
  );
}

export default BuyNft;
