import React, { useCallback } from 'react';
import { constants } from '../../../../../helpers';
import { onSetCanResendOtp, onToggleModal } from '../../../../../redux/features/commonSlice';
import { forgotPassword } from '../../../../../redux/services/auth';
import { capitalizeFirstLetter } from '../../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../custom-hooks';
import Component from './Component';

function SentEmailToResetPassword(props) {
    const dispatch = useAppDispatch();
    const commonReducer = useAppSelector(state => state.common);

    const handleSubmit = useCallback((data, { setSubmitting, setErrors }) => {
        if (!(commonReducer.modal?.forgotPassword?.email)) return false;

        // disabled save changes before trigger api call
        setSubmitting(true);

        dispatch(onSetCanResendOtp({ canResendOtp: false }));

        const request = {
            email: commonReducer.modal.forgotPassword.email,
            callback: (response) => {

                if (response.statusCode === 200) {
                    dispatch(onToggleModal({ modalType: constants.MODAL.FORGOT_PASSWORD_VERIFY_EMAIL }));

                    setTimeout(() => {
                        dispatch(onSetCanResendOtp({ canResendOtp: true }));
                    }, constants.RESEND_OTP_TIME_LIMIT.FORGOT_PASSWORD);

                } else {
                    setErrors({ email: capitalizeFirstLetter(response.data.message) });
                }

                // enabled save changes after api success
                setSubmitting(false);
            }
        };
        dispatch(forgotPassword(request));
    }, [dispatch, commonReducer.modal]);

    return <Component
        {...props}
        email={commonReducer.modal?.forgotPassword?.email || ''}
        onSubmit={handleSubmit}
    />;
}

export default SentEmailToResetPassword;
