import { constants } from "../../../../../../helpers";

function CategoriesView({ allCategories, categories, onSelectCategory }) {
    return categories.map((cat) => {
        return <div key={cat._id} className='checks'>
            <div
                className='checkbox'
                onChange={(event) => {
                    event.stopPropagation();
                    const temp = JSON.parse(JSON.stringify(allCategories));
                    const index = temp.findIndex(item => item._id === cat._id);
                    if (index > -1) {
                        temp[index].isChecked = !temp[index].isChecked;
                        if (!temp[index]?.isChecked) {
                            for (let scat of temp[index].subCategoryData) {
                                scat.isChecked = false;
                            }
                        }
                    }
                    onSelectCategory(temp);
                }}
            >
                <input
                    type="checkbox"
                    checked={!!cat.isChecked}
                />
                <label>{cat.name}</label>
            </div>
            <div>
                {cat.subCategoryData?.filter(subCat => subCat.status === constants.COLLECTIVE.NFT_CATEGORY_STATUS.ACTIVE)?.map((scat) => {
                    return <div
                        key={scat.name}
                        className='checkbox'
                        onChange={(event) => {
                            event.stopPropagation();
                            const temp = allCategories.slice();
                            const index = temp.findIndex(item => item._id === cat._id);
                            const subIndex = temp[index].subCategoryData.findIndex(sitem => (sitem.name === scat.name && sitem.status === constants.COLLECTIVE.NFT_CATEGORY_STATUS.ACTIVE));
                            if (subIndex > -1) {
                                if (!temp[index].subCategoryData[subIndex].isChecked) {
                                    temp[index].isChecked = true;
                                }
                                temp[index].subCategoryData[subIndex].isChecked = !temp[index].subCategoryData[subIndex].isChecked;
                            }
                            onSelectCategory(temp);
                        }}
                    >
                        <input
                            type="checkbox"
                            checked={!!scat.isChecked}
                        />
                        <label>{scat.name}</label>
                    </div>;
                })}
            </div>
        </div>;
    });
}

export default CategoriesView;
