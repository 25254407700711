import { useEffect } from 'react';
import { onToggleModal, setRefreshVotingandCommentDetails } from '../../../../../redux/features/commonSlice';
import { useAppDispatch } from '../../../../custom-hooks';
import Component from './Component';

/**
 * AddCommentSuccessfully component to handle successful comment addition.
 *
 * This component handles the success scenario after a comment is successfully added.
 * It toggles the modal, refreshes voting and comment details, and manages the timing of the refresh.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @returns {JSX.Element} - Rendered AddCommentSuccessfully component.
 */
function AddCommentSuccessfully(props) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Set a timeout to close the modal and refresh voting and comment details after 3 seconds
        setTimeout(() => {
            dispatch(onToggleModal());
            dispatch(setRefreshVotingandCommentDetails(true));

            // Set another timeout to stop refreshing after 1 second
            setTimeout(() => {
                dispatch(setRefreshVotingandCommentDetails(false));
            }, 1000);
        }, 3 * 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Render the Component with the necessary props
    return <Component {...props} />;
}

// Export the Component as the default export
export default AddCommentSuccessfully;
