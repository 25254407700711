import { useAppSelector } from "./redux";

function useReduxStore() {
  // Use the useAppSelector hook to access the Redux store state
  const state = useAppSelector((state) => state);

  // Return an object containing selected pieces of state
  return {
    authReducer: state.auth,
    commonReducer: state.common,
  };
}

export default useReduxStore;
