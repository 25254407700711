import { useMemo } from "react";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { messages } from "../../../helpers";
import { addButtonLoader, errorAlert } from "../../../utils";
import { ConnectWallet } from "../../components";
import { useAddCollective } from "../../custom-hooks";
import useCreateCollectionNFTCollective from "./useCreateCollectionNFTCollective";
import useCreateSingleNFTCollective from "./useCreateSingleNFTCollective";

function CreateCollective(props) {
  const {
    _nftContractAddress,
    isCollectiveType,
    isSubmitting,
    isDisabledSubmit,
    data,
    userId,
    resetForm,
    categories,
    setCategories,
    onIsSubmitting,
  } = props;

  const { data: walletClient } = useWalletClient();
  const { data: publicClient } = usePublicClient();

  const { address } = useAccount();

  const buttonClassName = useMemo(() => {
    return addButtonLoader({ className: "btn lg", isLoading: isSubmitting });
  }, [isSubmitting]);

  const { addCollectiveDetails } = useAddCollective({
    data,
    userId,
    resetForm,
    categories,
    setCategories,
    onIsSubmitting,
  });

  const hookProps = {
    ...props,
    walletClient,
    publicClient,
  };

  const { callCreateSingleNFTCrowdfund } =
    useCreateSingleNFTCollective(hookProps);
  const { callCreateCollectionNFTCrowdfund } =
    useCreateCollectionNFTCollective(hookProps);

  const callCreateCollection = isCollectiveType
    ? callCreateCollectionNFTCrowdfund
    : callCreateSingleNFTCrowdfund;

  if (!address) {
    return (
      <ConnectWallet
        type="create-collective"
        disabled={isDisabledSubmit}
        buttonClassName={buttonClassName}
      />
    );
  }

  return (
    <div className="text-center mt40">
      <button
        className={buttonClassName}
        disabled={isDisabledSubmit}
        onClick={() => {
          if (!_nftContractAddress) {
            errorAlert(messages.COLLECTIVE.UNABLE_TO_RETRIEVE_FROM_OPENSEA);
            return false;
          }

          onIsSubmitting(true);
          callCreateCollection().then((result) => {
            addCollectiveDetails(result);
          });
        }}
      >
        + Contribute and Launch
      </button>
    </div>
  );
}

export default CreateCollective;
