/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import FormikWrapper from '../../../formik-wrapper';
import InputField from '../../../input-field';

function UpdateEmailComponent({ isOpen, initialState, schema, onClose, onSubmit }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
        {({ values, errors, isSubmitting, touched, handleSubmit }) => {
            const isDisabledSubmit = !values.email || isSubmitting;

            return <Form>
                <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <h5 className='m6'>Update Email</h5>
                        <p className='m20 text-text'>Enter your new email to continue.</p>
                        <InputField
                            name='email'
                            label='Email'
                            placeholder="Enter Email Address"
                            errors={errors}
                            touched={touched}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btns'>
                            <button className='btn lg' disabled={isDisabledSubmit} onClick={handleSubmit}>
                                Send OTP
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default UpdateEmailComponent;
