import { Link, useLocation } from "react-router-dom";
import { constants } from "../../../helpers";

function HomeLink({ children }) {
    const location = useLocation();

    // Check if the current path is the "Discover" page
    const isHome = location.pathname === constants.BROWSER_ROUTES.DISCOVER;

    // If the current path is the "Discover" page, render a simple text instead of the link
    if (isHome) {
        return <span>{children || null}</span>;
    }

    // If not on the "Discover" page, render the link as usual
    return <Link to={constants.BROWSER_ROUTES.DISCOVER}>{children || null}</Link>;
};

export default HomeLink;
