import React from 'react';
import Modal from 'react-bootstrap/Modal';

const Component = ({ isOpen, onClose, onVetoProposal }) => {
    return (
        <Modal className="alert-modal" show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb12' closeButton onClick={onClose}>
                <Modal.Title className='mb0 h5'>Veto Proposal?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Are you sure you want to exercise your Veto Power?
                    All associated votes will be permanently lost,
                    the proposal will be canceled & no longer be accessible to collective members.
                </p>
            </Modal.Body>

            <Modal.Footer>
                <div>
                    <button type='button' className='btn-white mr12' onClick={onClose}>Cancel</button>
                    <button type='button' className='btn' onClick={onVetoProposal} >Confirm</button>
                </div>
            </Modal.Footer>

        </Modal>
    )
}
export default Component;


