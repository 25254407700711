// import { useMemo } from "react";
import { constants } from "../../../helpers";
import {
    formattedDecimalValue,
    // setDecimalValue
} from "../../../utils";
// import { useReduxStore } from "../../custom-hooks";

function MinContributionCheckbox({ value }) {
    // const { authReducer } = useReduxStore();

    // const minDollars = useMemo(() => {
    //     const dollars = setDecimalValue({
    //         value: (value || 0) * (authReducer.goalDetails?.minGoalInUSD || 0),
    //         limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
    //     });

    //     return dollars;
    // }, [value, authReducer]);

    return <label className='fw500'>
        Contribute ${constants.COLLECTIVE.LIMIT_IN_DOLLAR.MIN_CONTRIBUTION}{value > 0 ? ` (${formattedDecimalValue(value)} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}) ` : ' '}of the crowdfunding goal to launch the collective
    </label>;
}

export default MinContributionCheckbox;
