// Import the Component from the same directory
import Component from './Component';

// Define the functional component 'CollectiveContributionAdded' which renders the 'Component' with passed props
function CollectiveContributionAdded(props) {
    // Render the 'Component' with the provided props
    return <Component {...props} />;
}

// Export the 'CollectiveContributionAdded' component as the default export
export default CollectiveContributionAdded;
