import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import {
    Web3Button, Web3Modal,
    // useWeb3Modal
} from '@web3modal/react';
// import { useEffect } from 'react';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { arbitrum, mainnet, polygon } from 'wagmi/chains';

function HomePage() {
    return <Web3Button />;
}

const chains = [arbitrum, mainnet, polygon];
const projectId = 'a4df10d0e8e2051ebe3a58976e5fcfd2';

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 2, chains }),
    publicClient
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

// Open modal
// interface Options {
//     route?: "Account" | "ConnectWallet" | "Help" | "SelectNetwork";
// }

function App() {
    // const { isOpen, open, close, setDefaultChain } = useWeb3Modal();

    // useEffect(async () => {
    //     await open(options ?: Options);
    // }, []);

    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <HomePage />
            </WagmiConfig>

            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>
    );
}

export default App;
