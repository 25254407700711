/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { GreenCheck, confett } from '../../../../../../../assets/images/images';
import { constants } from '../../../../../../../helpers';
import FormikWrapper from '../../../../../formik-wrapper';

function Component({ isOpen, isCopy, initialState, shareLink, commonReducer, schema, onClose, onOpenModal, onGoHome, onSubmit, onViewCollective, onCopy }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
        {({ values, errors, isSubmitting, touched, handleSubmit }) => {
            return <Form>
                <Modal className='creative-launch' show={isOpen} onHide={onClose} backdrop="static">
                    <video className="confetti" autoPlay muted playsInline>
                        <source src={confett} type="video/mp4" />
                    </video>
                    <Modal.Header >
                        <figure><img src={GreenCheck} alt='Green Check' /></figure>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className='m8'>Collective Launch in Progress</h5>
                        <p className='m0 text-text'>Your request to launch a collective has been initiated with a <b>{commonReducer.modal?.collective?.contribute || 0} {constants.COLLECTIVE.PRICE.SYMBOL.ETH} </b> contribution, We'll notify you once it's successfully launched.</p>

                        <div>
                            <p className='text-left lg m12'>Share</p>

                            <div className='form-in left-icon right-icon'>
                                <input
                                    type='text'
                                    placeholder='Enter link'
                                    disabled
                                    value={shareLink}
                                />
                                <span className='icon-left'><i className='link-icon'></i></span>
                                <span className='icon-right'><button className='btn sm' onClick={() => !isCopy && onCopy()}>{isCopy ? 'Copied' : 'Copy'}</button></span>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btns'>
                            <button className='btn-white lg' onClick={onGoHome}>Back to home</button>
                            <button className='btn lg' onClick={onViewCollective}>View Collective</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default Component;
