/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useMemo, useState } from "react";
import { constants } from "../../../helpers";
import { onToggleModal } from "../../../redux/features/commonSlice";
import { useAppDispatch, useAppNavigate, useAppSelector, useUserProfileDetails } from "../../custom-hooks";
import Component from './Component';

function Header(props) {
    const navigate = useAppNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();
    const authReducer = useAppSelector(state => state.auth);
    const { details,onFetchUserDetails } = useUserProfileDetails();
   
    const handleToggleMenu = useCallback((value) => {
        setIsOpen(!value);
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleToggleModal = useCallback(() => {
        dispatch(onToggleModal({ modalType: constants.MODAL.SIGN_UP }));
    }, [dispatch]);

    useEffect(() => {
        function handleScroll() {
            setScrollPosition(window.scrollY);
        }

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [details]);

    const elementClass = useMemo(() =>
        scrollPosition > 50 ? 'scrolled' : '',
        [scrollPosition]
    );

    const handleClickNew =()=>{
        onFetchUserDetails();
    }

    const routes = constants.BROWSER_ROUTES;

    const isNotAllowed = [routes.REFERRAL].find(route => route === window.location.pathname);

    const isHomePage = ![
        routes.ADD_COLLECTIVE,
        routes.OPEN_COLLECTIVE_DETAILS,
        routes.DASHBOARD,
        routes.SEARCH,
        routes.VIEW_COLLECTIVE,
        routes.ADD_NEW_PROPOSAL,
        routes.BUY_NFT,
        routes.ABOUTUS,
        routes.TERMS_AND_CONDITIONS,
        routes.PRIVACY_POLICY,
        routes.RANKINGS,
        routes.MY_COLLECTIVES,
        routes.EVENTS,
    ].find(route => route === window.location.pathname);

    if (isNotAllowed) {
        return null;
    }

    // add nav-active in header when click on ham-btn
    return <Component
        {...props}
        authReducer={authReducer}
        isHomePage={isHomePage}
        isOpen={isOpen}
        navigate={navigate}
        dispatch={dispatch}
        elementClass={elementClass}
        onToggleMenu={handleToggleMenu}
        onToggleModal={handleToggleModal}
        details={details}
        onClickNew={handleClickNew} 
    />;
}

export default Header;
