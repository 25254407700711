import React from 'react'
import { ProgressBar } from "react-bootstrap";

const Loader = () => {
  return (
    <div className="nft-cards">
    <div>
        <div className='nft-card loader'>
            <figure className='img'></figure>

            <div className='text'>

            </div>

            <div className='card-details'>
                <div className='progress-div'>

                    <ProgressBar now={60} />
                </div>

                <div className='lower'>
                    <p><span></span> </p>
                    <p><span></span> </p>

                    <p><span></span> </p>
                </div>
            </div>
        </div>
          </div>
          <div>
        <div className='nft-card loader'>
            <figure className='img'></figure>

            <div className='text'>

            </div>

            <div className='card-details'>
                <div className='progress-div'>

                    <ProgressBar now={60} />
                </div>

                <div className='lower'>
                    <p><span></span> </p>
                    <p><span></span> </p>

                    <p><span></span> </p>
                </div>
            </div>
        </div>
          </div>
          <div>
        <div className='nft-card loader'>
            <figure className='img'></figure>

            <div className='text'>

            </div>

            <div className='card-details'>
                <div className='progress-div'>

                    <ProgressBar now={60} />
                </div>

                <div className='lower'>
                    <p><span></span> </p>
                    <p><span></span> </p>

                    <p><span></span> </p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Loader