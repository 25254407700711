import { ProfileDp } from "../../../assets/images/images";
import ReactSelect from "../react-select";

// Custom Option Component
const CustomOption = ({ innerProps, label, data }) => {
    return <div {...innerProps} className='d-flex vote-drop'>
        <figure><img src={data.userData?.profilePicture || ProfileDp} alt={label} style={{ width: '30px', height: '30px', marginRight: '10px' }} /></figure>
        <div className=''>
            <p className='fw600'>{label}</p>
        </div>
    </div>;
};

function Tooltip() {
    return <>
        Voting Delegation
        <div className='tooltip-div ml6'>
            <i className='info-line-icon trig'></i>
            <div className='text'>
                <ul>
                    <li>If the collective is successful, who should control your votes?</li>
                    <li>Delegating your vote to the Leader is   recommended. You can change your     delegation at any time.</li>
                </ul>
            </div>
        </div>
    </>;
}

function VotingDelegationDropdown({ isH6Tag, options, authReducer, leaderOption, commonReducer, value, delegationDetails, onChange }) {
    return <div className='form-in pt12'>
        {isH6Tag ?
            <h6 className=''>
                <Tooltip />
            </h6>
            : <h5 className=''>
                <Tooltip />
            </h5>
        }
        <div className='f-in vote-delegation-drop'>
            <img src={value?.userData.profilePicture || ProfileDp} className="drop-img" alt={value?.userData?.userName} />
            <ReactSelect
                options={options}
                value={value}
                components={{ Option: CustomOption }}
                getOptionLabel={(option) => option.userData?.userName || ''}
                getOptionValue={(option) => option.votingDelegationId || ''}
                onSelect={onChange}
                placeholder={delegationDetails.myDelagation?.delegatedUsername || authReducer?.username||  'Select'}
            />
            {(value?.userData?.userName === authReducer?.username) &&
                <span className='icon-right fs12 mr32 text-text'>(you)</span>
            }
        </div>
    </div>;
}

export default VotingDelegationDropdown;
