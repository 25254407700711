/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { useAppDispatch, useCustomModals, useReduxStore } from '../../../../custom-hooks';
import { onToggleModal } from '../../../../../redux/features/commonSlice';
import { constants } from '../../../../../helpers';

function Component({ isOpen, onClose, index, onNext, onSkip, authReducer }) {
    const dispatch = useAppDispatch()
    const { onOpenModal } = useCustomModals();
    const { commonReducer } = useReduxStore();

    return <Modal className='voter-info collection-info' show={isOpen} onHide={onClose} backdrop="static">
        <Modal.Header className='pb0' closeButton>
        </Modal.Header>
        <Modal.Body >
            <Carousel activeIndex={index}>

                {!(commonReducer?.modal?.isDiscordLogin || commonReducer?.modal?.isTwitterLogin) ? <Carousel.Item className='text-center'>
                    <div className='mb28'><i className='caution-icon caution-yellow-icon'></i></div>

                    <div>
                        <h5 className='m6'>The Leader Has Not Connected Their Social Accounts</h5>
                        <p>The leader of this Collective has not connected any social accounts to MyMetaFi. This can make it hard to verify who they are. Are you sure you still want to contribute?</p>
                    </div>
                </Carousel.Item> : null}
                {commonReducer?.modal?.totalVolume < 50 ?
                    <Carousel.Item className='text-center'>
                        <div className='mb28'><i className='caution-icon caution-yellow-icon'></i></div>

                        <div>
                            <h5 className='m6'>Collection value is below 50 ETH</h5>
                            <p className='px-lg-4'>This NFT collection has less than 50ETH sales volume. Are you sure you want to proceed?</p>
                        </div>
                    </Carousel.Item> : null}

            </Carousel>

        </Modal.Body>

        <Modal.Footer>
            <div className='btns'>
                {index === 2 ?
                    <div className='text-center '>
                        <button className='btn w-200' onClick={() => { dispatch(onToggleModal()) }}>Continue</button>
                    </div>
                    :
                    <>
                        <div className='btns px-lg-3'>
                            <button className='btn-white' onClick={() => { dispatch(onToggleModal()) }}>No</button>
                            <button className='btn' onClick={() => {
                                if (commonReducer?.modal?.totalVolume < 50 && index === 0 && !(commonReducer?.modal?.isDiscordLogin || commonReducer?.modal?.isTwitterLogin ) ) {
                                    onNext();
                                } else {
                                    onOpenModal(constants.MODAL.ADD_CONTRIBUTION)
                                }

                            }}>Yes</button>
                        </div>
                        {/* <button className='btn-white skip' onClick={() =>  onOpenModal(constants.MODAL.ADD_CONTRIBUTION)}>Yes</button>
                        <button className='btn' onClick={onNext}>No</button> */}
                    </>
                }
            </div>
        </Modal.Footer>
    </Modal>
}

export default Component;
