/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */

import Component from './Component';
import { Proposal1, Proposal2, Proposal3, Proposal4 } from '../../../../../assets/images/images';
import { useState } from 'react';

const carouselItems = [
  {
    id: 1,
    image: Proposal1,
    alt: 'Image 1',
    heading: "Anyone can Create a Proposal",
    subHeading: "The Leader or Collector can create a proposal to sell or govern an NFT"
  },
  {
    id: 2,
    image: Proposal2,
    alt: 'Image 2',
    heading: "Voting Period",
    subHeading: "There is a voting period for how long each Collector has to vote."
  },
  {
    id: 3,
    image: Proposal3,
    alt: 'Image 3',
    heading: "Acceptance Threshold",
    subHeading: "Your NFT can be used to earn money by selling and more through MyMetaFi"
  },
  {
    id: 4,
    image: Proposal4,
    alt: 'Image 4',
    heading: "Voting",
    subHeading: "You can vote Accept, Decline or Abstain"
  },
];

function Proposal(props) {

  const [index, setIndex] = useState(0);

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
  };

  const handleSkip = () => {
    // Assuming you want to skip 2 items, you can adjust this as needed
    setIndex(() => (3) % carouselItems.length);
  };

  return <Component
    {...props}
    carouselItems={carouselItems}
    index={index}
    onNext={handleNext}
    onSkip={handleSkip}
  />;
}

export default Proposal;
