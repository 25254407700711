import { useCallback, useState } from 'react';
import Component from './Component';
import { constants } from '../../../../../helpers';
import { useHandleCalls, useReduxStore } from '../../../../custom-hooks';
import { getDataApi } from '../../../../../api/methods';

function ViewCommentAndVote(props) {
    const { commonReducer } = useReduxStore();
    const [commentList, setCommentList] = useState(constants.PAGINATION_DATA);
    const [currentPage, setCurrentPage] = useState(1);
    const [votingList, setVotingList] = useState(constants.PAGINATION_DATA);
    const [currentPageVoting, setCurrentPageVoting] = useState(1);

    const handleFetchComments = useCallback(
        (page) => {
            const query = {
                proposalId: commonReducer.modal.proposal?.proposalId,
                pageNo: page || currentPage,
                limit: 5,
                loaderType: constants.LOADER.TYPE.TABLE,
                callback: (response) => {
                    setCommentList({
                        list: page === 1 ? response.data.data : [...commentList.list, ...response.data.data],
                        total: response.data.totalPage,
                        pageNo: response.data.pageNo,
                        totalCount: response.data.total
                    });
                }
            }
            getDataApi({ path: constants.API_PATHS.GET_COMMENTS_LIST, data: query });
        }, [currentPage, commentList.list,commonReducer.modal.proposal.proposalId]);

    useHandleCalls(handleFetchComments, [], 'comment_list');

    const handleLodeMore = () => {
        if (commentList.total === currentPage) {
            return;
        }
        let pageNoCount = currentPage + 1
        setCurrentPage(currentPage + 1);
        handleFetchComments(pageNoCount)
    };

    const handleFetchVotes = useCallback(
        (page) => {
            const query = {
                proposalId: commonReducer.modal.proposal?.proposalId,
                pageNo: page || currentPageVoting,
                limit: 5,
                loaderType: constants.LOADER.TYPE.TABLE,
                callback: (response) => {
                    setVotingList({
                        list: page === 1 ? response.data.data : [...votingList.list, ...response.data.data],
                        total: response.data.totalPage,
                        pageNo: response.data.pageNo,
                        totalCount: response.data.total
                    });
                }
            }
            getDataApi({ path: constants.API_PATHS.GET_VOTING_LIST, data: query });
        }, [currentPageVoting, votingList.list,commonReducer.modal.proposal.proposalId]);

    useHandleCalls(handleFetchVotes, [], 'voting_list');

    const handleLodeMoreVotes = () => {
        if (votingList.total === currentPageVoting) {
            return;
        }
        let pageNoCount = currentPageVoting + 1
        setCurrentPageVoting(currentPageVoting + 1);
        handleFetchVotes(pageNoCount)
    };

    return <Component {...props}
        commentList={commentList}
        onLodeMore={handleLodeMore}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        votingList={votingList}
        onLoadVotes={handleLodeMoreVotes}
        currentPageVoting={currentPageVoting}
        commonReducer={commonReducer}
    />;
}

export default ViewCommentAndVote;
