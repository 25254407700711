"use client";

import {
  getContract,
} from "viem";
import messages from "../../../helpers/messages.js";
import { errorAlert } from "../../../utils/index.js";
import {
  collectiveCrowdfundFactoryAbi,
  singleNFTCrowdfundAbi,
} from "../scripts/index.js";
import addresses from "../scripts/utils/addresses.json";
import config from "../../../Config.js";

const { DEPLOYED_COLLECTIVE_CF_FACTORY_ADDRESS, DEPLOYED_SINGLE_NFT_IMPL_ADDRESS } = addresses[config["NETWORK"]];

function useCreateSingleNFTCollective({
  _collectiveTitleName,
  _nftContractAddress,
  _nftTokenId,
  _fundraiseGoal,
  _crowdFundDuration,
  _lowerLimitInvestment,
  _upperLimitInvestment,
  _initialContributor,
  _initialContribution,
  _voteDuration,
  _vetoDuration,
  _passThresholdBps,
  _sellingPlatform,
  _sellingDuration,
  _sellingPrice,
  creator,
  walletClient,
  publicClient,
  onIsSubmitting
}) {

  const callCreateSingleNFTCrowdfund = async () => {
    try {
      const collectiveFactory = getContract({
        address: DEPLOYED_COLLECTIVE_CF_FACTORY_ADDRESS,
        abi: collectiveCrowdfundFactoryAbi,
        publicClient,
        walletClient
      });
      const singleNFTIMPL = getContract({
        address: DEPLOYED_SINGLE_NFT_IMPL_ADDRESS,
        abi: singleNFTCrowdfundAbi,
        publicClient,
        walletClient
      });

      const opts = singleNFTIMPL.SingleNFTCrowdfundOptions = {
        collectiveTitleName: _collectiveTitleName,
        nftContractAddress: _nftContractAddress,
        nftTokenId: _nftTokenId,
        fundraiseGoal: _fundraiseGoal,
        crowdFundDuration: 900,
        lowerLimitInvestment: _lowerLimitInvestment,
        upperLimitInvestment: _upperLimitInvestment,
        initialContributor: _initialContributor,
        initialContribution: _initialContribution,
        governanceOpts: {
          voteDuration: _voteDuration,
          vetoDuration: _vetoDuration,
          passThresholdBps: _passThresholdBps,
          // sellingPlatform: _sellingPlatform,
          // sellingDuration: _sellingDuration,
          // sellingPrice: _sellingPrice,
        }
      };

      // if (opts.upperLimitInvestment <= (20n * opts.fundraiseGoal) / 100n &&
      //   opts.initialContribution >= (25n * opts.fundraiseGoal) / 1000n && opts.initialContribution <= (20n * opts.fundraiseGoal) / 100n &&
      //   opts.governanceOpts.passThresholdBps <= BigNumber(1e4) && opts.governanceOpts.passThresholdBps >= BigNumber(5e3)) {
      console.log("callCreateSingleNFTCrowdfund-opts", opts);

      const createSingleNFTCollective_tx = await collectiveFactory.write.createSingleNFTCrowdfund({
        args: [opts], value: opts.initialContribution
      });
      console.log("callCreateSingleNFTCrowdfund-createSingleNFTCollective_tx", createSingleNFTCollective_tx);

      // const txReceipt = await publicClient.waitForTransactionReceipt(
      //   { hash: createSingleNFTCollective_tx }
      // );
      return createSingleNFTCollective_tx;
      // try {
      //     let eventSelector = getEventSelector("SingleNFTCrowdfundCreated");
      //     const logs = txReceipt.logs;
      //     const filteredLog = logs.filter((log) => log.topics[0] == eventSelector);
      //     const decodedEvent = decodeEventLog({
      //         abi: collectiveCrowdfundFactoryAbi,
      //         ...filteredLog
      //     })
      //     console.log("decodedEvent", decodedEvent);
      // } catch (error) {
      //     console.log("problem in decoding event", error);
      // }
      // }
    } catch (error) {
      console.log("callCreateSingleNFTCrowdfund-error", error);
      onIsSubmitting(false);
      errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
    }
  };

  return { callCreateSingleNFTCrowdfund };
}

export default useCreateSingleNFTCollective;
