/**
 * Note:
 * modal based on component name (Note: you can check in folder - all in one modal -> modals)
 * mandatory to export from the modal component (PATH => /home/appinventiv/frontend/src/common/components/all-in-one-modal/modals/index.js)
 * add new element in modal type of MODAL -> visit -> constants file (/home/appinventiv/frontend/src/helpers/constants.js)
 */

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback } from 'react';
import { onToggleModal } from '../../../redux/features/commonSlice';
import { useAppDispatch } from '../../custom-hooks';
import * as modals from './modals';

function AllInOneModal({ isOpen, type, onForceReRender }) {
    const dispatch = useAppDispatch();

    const handleOpenModal = useCallback((modalType) => {
        dispatch(onToggleModal({ modalType }));
    }, [dispatch]);

    const handleClose = useCallback(() => {
        dispatch(onToggleModal());
    }, [dispatch]);

    const Component = modals[type];

    if (!isOpen || !Component) {
        return null;
    }

    return <Component isOpen={isOpen} onClose={handleClose} onOpenModal={handleOpenModal} onForceReRender={onForceReRender} />;
}

export default AllInOneModal;
