/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios';
import { Form } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import {
    LoginSocialGoogle
} from 'reactjs-social-login';
import config from '../../../../../Config';
import { Logo } from '../../../../../assets/images/images';
import { constants } from '../../../../../helpers';
import { InputField, PasswordValidationView, PhoneNumber } from '../../../../components';
import FormikWrapper from '../../../formik-wrapper';

function SignUpComponent({ isOpen, initialState, dispatch, onRefreshViewCollectivePage, navigate, schema, onClose, onOpenModal, onSubmit, handleGoogleLogin, deviceId, commonReducer, onContentModal }) {

    const loginSuccessHandle = async (provider, data) => {
        if (data) {
            const response = await axios.get(
                `${config.GOOGLE_LOGIN_USER_INFO}?access_token=${data.access_token}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            // console.log(response.data, "RRRRR")
            let res = response.data;
            handleGoogleLogin(dispatch,onRefreshViewCollectivePage, deviceId, commonReducer, navigate, res);
        }
    };

    return <FormikWrapper
        enableReinitialize
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => {
            const isDisabledSubmit = !(
                values.username &&
                values.email &&
                values.password &&
                values.acceptTerms
            ) || isSubmitting;

            return <Form>
                <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        <figure><img src={Logo} alt="Logo" /></figure>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className='m6'>Sign Up</h5>
                        <p className='m20 text-text'>Enter your details to continue Signing up.</p>

                        <InputField
                            name='username'
                            label='Username'
                            required
                            placeholder="Enter User name"
                            errors={errors}
                            touched={touched}
                        />

                        <InputField
                            name='email'
                            label='Email'
                            required
                            placeholder="Enter email address"
                            errors={errors}
                            touched={touched}
                        />

                        <InputField
                            type={values.isShowingPassword ? 'text' : 'password'}
                            name='password'
                            label='Password'
                            required
                            placeholder="Enter password"
                            wrapperClass='form-in right-icon'
                            errors={errors}
                            touched={touched}
                        >
                            <span
                                className='icon-right'
                                onClick={() => setFieldValue('isShowingPassword', !values.isShowingPassword)}
                            >
                                <i className={values.isShowingPassword ? 'eye-closed-icon' : "eye-icon"}></i>
                            </span>

                            <PasswordValidationView name="password" values={values} errors={errors} touched={touched} />
                        </InputField>

                        <PhoneNumber
                            type='number'
                            name='phoneNumber'
                            label='Phone Number (optional)'
                            placeholder="Enter Phone Number"
                            errors={errors}
                            touched={touched}
                            countryCode={values.countryCode}
                            onCodeChange={(value) => setFieldValue('countryCode', value)}
                        />

                        {/* {values.referralCode &&
                            <InputField
                                name='referralCode'
                                label='Referral Code'
                                disabled
                            />
                        } */}

                        <div>
                            <div className="checkbox short">
                                <input type="checkbox" checked={values.acceptTerms} onChange={() => setFieldValue('acceptTerms', !values.acceptTerms)} />
                                <label>
                                    Agree to&nbsp;<span
                                        role='button'
                                        className='link'
                                        onClick={() => {
                                            // onContentModal('TermsOfUse');
                                            onContentModal();
                                            navigate(constants.BROWSER_ROUTES.TERMS_AND_CONDITIONS)
                                        }}
                                    >Terms of Use
                                    </span>&nbsp;and&nbsp;<span
                                        className='link'
                                        role='button'
                                        onClick={() => {
                                            // onContentModal("PrivacyPolicy");
                                            onContentModal();
                                            navigate(constants.BROWSER_ROUTES.PRIVACY_POLICY)
                                        }}
                                    >Privacy Policy</span>&nbsp;
                                </label>
                            </div>
                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <div className='w-100'>
                            <Button
                                className='btn lg w-100'
                                disabled={isDisabledSubmit}
                                onClick={handleSubmit}
                            >
                                Sign up
                            </Button>
                            <br />

                            <LoginSocialGoogle
                                client_id={config.GOOGLE_CLIENT_ID}
                                // onLoginStart={onLoginStart}
                                onResolve={({ provider, data }) => {
                                    loginSuccessHandle(provider, data);
                                }}
                                onReject={(err) => {
                                    console.log(err);
                                }}
                                cookie_policy='single_host_origin'
                                scope='openid profile email'
                            >
                                <button
                                    className='btn-white lg w-100 mt12'
                                // disabled={isDisabledSubmit}
                                >
                                    <i className='google-icon mr10'></i>
                                    Sign up with Google
                                </button>
                            </LoginSocialGoogle>
                        </div>

                        <p className='mt16 text-text'>
                            Already have an account ? {" "}
                            <a href='#' className='link' onClick={() => onOpenModal(constants.MODAL.LOGIN)}>
                                Sign in
                            </a>!
                        </p>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default SignUpComponent;
