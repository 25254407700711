import { ConnectButton } from "@rainbow-me/rainbowkit";

function BuyNft({ disabled, buttonConfig, onSetConnectingWallet }) {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return <button
                  className={buttonConfig.className}
                  disabled={disabled}
                  onClick={(event) => {
                    openConnectModal(event);
                    onSetConnectingWallet(true);
                  }}
                >
                  {buttonConfig.title}
                </button>;
              }
              if (chain.unsupported) {
                return <button
                  className={buttonConfig.className}
                  disabled={disabled}
                  onClick={openChainModal}
                >
                  {buttonConfig.title}
                </button>;
              }

              return <button
                className={buttonConfig.className}
                disabled={disabled}
                onClick={openAccountModal}
              >
                {buttonConfig.title}
              </button>;
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}

export default BuyNft;
