// Importing images from the "../assets/images/images" module
import { Game1, Game2, Game3, Nft1, smLogo } from "../assets/images/images";

// Array of featured games
export const featuredGames = [
  {
    id: 1,
    image: Game1,
    title: "Delysium",
    subTitle: "First Person Shooter",
  },
  {
    id: 2,
    image: Game2,
    title: "Taunt Battle world",
    subTitle: "First Person Shooter",
  },
  {
    id: 3,
    image: Game3,
    title: "Undead Blocks",
    subTitle: "First Person Shooter",
  },
];

// Array of collectives
export const collectives = [
  // Collective 1
  {
    id: 1,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 2,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 3,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 4,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 5,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 6,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 7,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 8,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 9,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
];

// Array of featured collectives
export const featuredCollectives = [
  // Featured Collective 1
  {
    id: 1,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    completed_percentage: "91% APR",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 2,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    completed_percentage: "91% APR",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
  {
    id: 3,
    image1: Nft1,
    image2: smLogo,
    title: "Bored Ape Yacht Club",
    author: "Nethero",
    completed_percentage: "91% APR",
    progress_percentage: 60,
    pending_days: 29,
    pending_value: 50.69,
    total_collectors: 77,
  },
];

// Array of collector rankings
export const collectorRankings = [
  // Collector Ranking 1
  {
    id: 1,
    image: smLogo,
    title: "Pewdiepie Chadicorn",
    price: 170.0,
  },
  {
    id: 2,
    image: smLogo,
    title: "Pewdiepie Chadicorn",
    price: 170.0,
  },
  {
    id: 3,
    image: smLogo,
    title: "Pewdiepie Chadicorn",
    price: 170.0,
  },
  {
    id: 4,
    image: smLogo,
    title: "Pewdiepie Chadicorn",
    price: 170.0,
  },
];

// Array of collective rankings
export const collectiveRankings = [
  // Collective Ranking 1
  {
    id: 1,
    image: Game1,
    title: "UB Veteran Pack",
    subTitle: "GamerSpeak",
    progress_percentage: 100,
    price: 150.0,
    time: "6 Minutes ago",
  },
  {
    id: 2,
    image: Game1,
    title: "UB Veteran Pack",
    subTitle: "GamerSpeak",
    progress_percentage: 60,
    price: 150.0,
    time: "6 Minutes ago",
  },
  {
    id: 3,
    image: Game1,
    title: "UB Veteran Pack",
    subTitle: "GamerSpeak",
    progress_percentage: 60,
    price: 150.0,
    time: "6 Minutes ago",
  },
  {
    id: 4,
    image: Game1,
    title: "UB Veteran Pack",
    subTitle: "GamerSpeak",
    progress_percentage: 60,
    price: 150.0,
    time: "6 Minutes ago",
  },
];
