import React from "react";
import { ProgressBar } from "react-bootstrap";
import { calculateCompletionPercent, calculateRemainingTime, setDecimalValue } from "../../../../../utils";
import createdPlaceholder from "../../../../../assets/images/created-placeholder.png";
import activityLogo from "../../../../../assets/images/activityLogo.png";
import { constants } from "../../../../../helpers";
import Loader from "../../../../../common/components/loader";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { renderTooltip } from "../../../../../utils/tooltip";
import { NameTooltip } from "../../../../../common/components";

const CollectiveCard = ({ details, navigate, noDataFound, isFetching, result, loadMore, loadLess }) => {
  return (
    <>
      {details && details?.length && !isFetching ? (
        <div className="nft-cards">
          {
            details?.map((collective) => {
              const completionPercentage = collective?.contributionGoal?.amount
                ? calculateCompletionPercent({ totalContribution: collective?.contributeData?.totalContribution, amount: collective?.contributionGoal?.amount })
                : 0;
              const isGoalCompleted = completionPercentage >= 100;

              return (
                <div className="cursor-pointer" key={collective.id} onClick={() =>
                  navigate(
                    `${constants.BROWSER_ROUTES.VIEW_COLLECTIVE}?collectiveId=${collective._id}`
                  )
                }>
                  <div className="nft-card">
                    <figure className="img">
                      <img
                        src={collective?.status === constants.COLLECTIVE.STATUS.ACQUIRED || collective?.status === constants.COLLECTIVE.STATUS.SOLD ? collective?.buyNftDetails?.image ? collective.buyNftDetails?.image : createdPlaceholder : collective?.image ? collective?.image : createdPlaceholder}
                        alt="Nft1"
                      />
                    </figure>

                    <div className="text">
                      <figure>
                        <img
                          src={collective.image ? collective.image : activityLogo}
                          alt="smLogo"
                        />
                      </figure>

                      <div className="title">
                        <div className="title-name">
                          <NameTooltip name={collective.title} />

                          <span className="by-name">
                          with <b className="fw400">{collective?.user_detail?.userName ? collective?.user_detail?.userName : 'NA'}</b>
                          </span>
                        </div>
                        <div className="reward-icons">
                          {collective.utilityAvailable?.length ? collective.utilityAvailable?.length < 5 ? collective.utilityAvailable.map(() => {
                            return (
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >

                                <i className="reward-icon"></i>

                              </OverlayTrigger>
                            )
                          }) : <><OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                          >

                            <i className="reward-icon"></i>

                          </OverlayTrigger> <span className='fw600 text-orange'> x5</span></> : null}
                        </div>
                      </div>
                    </div>

                    <div className="card-details">
                      <div className="progress-div">
                        <p>{completionPercentage || 0}% Contributed</p>
                        {/* <p>{isGoalCompleted ? setDecimalValue({ value: completionPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE }) : setDecimalValue({ value: completionPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE })}% Contributed</p> */}
                        <ProgressBar
                          className={`lg ${isGoalCompleted ? "green" : ""}`}
                          now={completionPercentage}
                        />
                      </div>

                      <div className="lower">
                        <p>
                          <span> {collective.created ? calculateRemainingTime(
                            {
                              created: collective.created,
                              period: collective.contributionPeriod,
                              isCompleted: collective.status === constants.COLLECTIVE.STATUS.ACQUIRED,
                              endTimeStamp: collective?.collective_close_timing
                            }
                          ) : 0} </span> Left
                        </p>
                        <p className="">
                          <i className="etherium-icon"></i>{" "}
                          <span>
                            {collective.status === constants.COLLECTIVE.STATUS.ACQUIRED || collective.status === constants.COLLECTIVE.STATUS.CLOSED
                              ? '0.00'
                              : collective?.contributionGoal?.amount ? setDecimalValue({ value: collective?.contributionGoal?.amount - collective?.contributeData?.totalContribution, formatted: true }) === 0 ? "0.00" : setDecimalValue({ value: collective?.contributionGoal?.amount - collective?.contributeData?.totalContribution, formatted: true }) : '-'
                            }

                          </span>{" "}
                          Left

                          {/* <p className="tooltip-div">
                            <p className="price trig"><i className="etherium-icon"></i> <span className="trunc">{collective.status === constants.COLLECTIVE.STATUS.  ACQUIRED
                                    ? '0.00'
                                    : collective?.contributionGoal?.amount ? setDecimalValue({ value: collective?.contributionGoal?.amount - collective?.contributeData?.totalContribution, formatted: true }) : '-'
                                  }
                                </span>
                            </p>

                                <div className='p text xs fw500'>  <span>
                                  < i className="etherium-icon"></i> {collective.status === constants.COLLECTIVE.STATUS.ACQUIRED
                                    ? '0.00'
                                    : collective?.contributionGoal?.amount ? setDecimalValue({ value: collective?.contributionGoal?.amount - collective?.contributeData?.totalContribution, formatted: true }) : '-'
                                  }

                                </span>{" "}
                                Left</div>

                          </p> */}
                        </p>

                        <p>
                          <span>{collective.status === constants.COLLECTIVE.STATUS.CLOSED ? 0 :collective?.contributeData?.totalCollectors || 0}</span>{" "}
                          collectors
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
      ) : isFetching ? <Loader /> : noDataFound()}
      {result?.length === 9 ? <div className="text-center mob-btn">
        <button className={`btn-white lg w-200 ${isFetching ? "spinner" : ""}`} onClick={() => loadMore()}>
          {isFetching ? "" : "View More"}
        </button>
      </div> : ''}

      {result?.length < 9 && !(details?.length < 9) ? <div className="text-center mob-btn">
        <button className={`btn-white lg w-200 ${isFetching ? "spinner" : ""}`} onClick={() => loadLess()}>
          {isFetching ? "" : "View Less"}
        </button>
      </div> : ''}
    </>
  );
};

export default CollectiveCard;
