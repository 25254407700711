/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from 'react-bootstrap';

import { useCustomModals } from '../../../../custom-hooks';
import { constants } from '../../../../../helpers';

function Component({ isOpen, onClose }) {
    const { onOpenModal } = useCustomModals();

    return <Modal className='voter-info collection-info' show={isOpen} onHide={onClose} >
        <Modal.Header className='pb0' closeButton>
        </Modal.Header>
        <Modal.Body className='text-center'>
            <div className='mb28'><i className='caution-icon caution-yellow-icon'></i></div>

            <div>
                <h5 className='m6'> You have not connected your Social Account.</h5>
                <p>To enhance your trust and credibility on MyMetaFi, please connect to your X (formerly Twitter) account in your profile.</p>
            </div>
        </Modal.Body>

        <Modal.Footer>
            <div className='btns px-lg-3'>
                <button className='btn' onClick={() => onOpenModal(constants.MODAL.COLLECTIVE_PREVIEW)}>OK</button>
            </div>

        </Modal.Footer>
    </Modal>
}

export default Component;
