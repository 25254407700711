import { useCallback, useEffect } from "react";
import { getDataApi } from "../../api/methods";
import { constants } from "../../helpers";
import { setVotingDelegationList } from "../../redux/features/commonSlice";
import { useAppDispatch } from "./redux";
import useHandleCalls from "./useHandleCalls";
import useReduxStore from "./useReduxStore";
import { localStorageGet } from "../../utils";

/**
 * Custom hook for managing voting delegation data.
 * @param {Object} options - Hook options.
 * @param {string} options.collectiveId - ID of the collective.
 * @returns {Object} - An object with the votingDelegationList.
 */
function useVotingDelegation({ collectiveId }) {
  const dispatch = useAppDispatch();
  const { commonReducer } = useReduxStore();

  // Callback function to fetch the voting delegation list.
  const onFetchVotingDelegationList = useCallback(() => {
    if (localStorageGet('accessToken')) {
      if (!collectiveId) {
        return;
      }

      const request = {
        collectiveId,
        pageNo: 1,
        limit: 10,
        callback: (response) => {
          if (response?.statusCode === 200 && response.data) {
            dispatch(setVotingDelegationList(response.data));
          }
        },
      };
      getDataApi({
        path: constants.API_PATHS.VOTING_DELEGATION_LIST,
        data: request,
      });
    }
  }, [collectiveId, dispatch]);

  // Use the useHandleCalls custom hook to handle API calls with debounce.
  useHandleCalls(
    onFetchVotingDelegationList,
    [collectiveId],
    "fetchVotingDelegationList"
  );

  useEffect(() => {
    if (commonReducer.refreshVotingDelegationList) {
      onFetchVotingDelegationList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonReducer.refreshVotingDelegationList]);
}

export default useVotingDelegation;
