/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useReduxStore } from "../../../../custom-hooks";
import DiscordLoginComponent from "../../../discord-login";
import TwitterLoginComponent from "../../../twitter-login";
// import Notifications from "../notification";
import ProfileSettings from "../settings";




function SocialLinkComponent({ details }) {
  const { authReducer } = useReduxStore();

  return (
    <div className="profile-btns left">
      <div>
        <div className="social-list">

          {details?.isTwitterLogin || authReducer.isTwitterLogin ? (
            <a href={details?.twitterProfileUrl || authReducer?.twitterProfileUrl} target="_blank">
              <i className="twitter-icon"></i>
            </a>
          ) : (
            <TwitterLoginComponent type="icon" />
          )}
          {(details?.isDiscordLogin || authReducer.isDiscordLogin) ? (
            <a href={details?.discordProfileUrl} target="_blank">
              <i className="discord-lg-icon"></i>
            </a>
          ) : (
            <DiscordLoginComponent type="icon" />
          )}
          {/* <a href="javascript:;" onClick={handleLogin}>
            <i className="discord-lg-icon"></i>
          </a> */}
          {/* TODO: client wants to remove */}
          {/* <a href='javascript:;'><i className='telegram-icon'></i></a> */}
        </div>
      </div>

      {/* <Notifications /> */}
      <ProfileSettings />
    </div>
  );
}

export default SocialLinkComponent;
