import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getDataApi } from "../../api/methods";
import { constants } from "../../helpers";
import useHandleCalls from "./useHandleCalls"; // Assuming this is another custom hook
import { localStorageGet, setCollectiveDetails } from "../../utils";
/**
 * Custom hook to fetch details for buying NFTs.
 * @returns {Object} - An object containing fetched details and a function to fetch the details.
 */
function useFetchBuyNftDetails() {
  const [searchParams] = useSearchParams();
  const collectiveId = searchParams.get("collectiveId") || "";

  // Initialize state to hold fetched details
  const [details, setDetails] = useState({
    buyNftList: [],
    collectiveDetails: {},
    isContributed: false,
    totalContribution: {}
  });

  // Callback to fetch details based on collectiveId
  const handleFetchDetails = useCallback(() => {
    if (localStorageGet('accessToken')) {

      if (!collectiveId) return false;

      // Fetch data using the getDataApi function
      getDataApi({
        path: constants.API_PATHS.CHOOSE_NFT_FOR_BUY,
        data: {
          collectiveId,
          callback: (response) => {
            if (response.statusCode === 200 && response.data) {
              // Set collective details using setCollectiveDetails utility function
              const collectiveDetails = setCollectiveDetails({
                data: response.data.collectiveDetails || {},
                goal: {
                  collectors: response.data.totalContribution,
                },
              });

              // Update state with fetched details
              setDetails({
                buyNftList: response.data.buyNftList || [],
                collectiveDetails,
                isContributed: !!response.data.isContributed,
                totalContribution: response.data.totalContribution?.[0] || {},
              });
            }
          },
        },
      });
    }
  }, [collectiveId]);

  // Use the useHandleCalls custom hook to handle fetching details
  useHandleCalls(handleFetchDetails, [collectiveId], "fetchDetails");

  return { details, handleFetchDetails };
}

export default useFetchBuyNftDetails;
