"use client";
import { encodeFunctionData } from "viem";
import messages from "../../../helpers/messages.js";
import { errorAlert } from "../../../utils/alert.js";
import { singleNFTCrowdfundAbi } from "../scripts/index";
import { seaportExchangeAbi } from "../scripts/index.js";
import { getBasicOrderParameters } from "./Helpers.js";
import addresses from "../scripts/utils/addresses.json";
import config from "../../../Config.js";

const { SEAPORT_EXCHANGE_ADDRESS } = addresses[config["NETWORK"]];

function useBuySingle({
  collectiveCrowdfundAddress,
  data,
  walletClient,
  onIsSubmitting,
}) {
  const callBuy = async () => {
    try {
      const {
        BasicOrderParameters,
        price,
        // fixedGovernanceOpts
      } = await getBasicOrderParameters({
        collectiveCrowdfundAddress,
        contractAddress: data.contractAddress,
        tokenId: data.tokenId,
      });

      console.log(
        "getBasicOrderParameters-BasicOrderParameters",
        BasicOrderParameters
      );

      const aData = Object.keys(BasicOrderParameters)?.length
        ? encodeFunctionData({
            abi: seaportExchangeAbi,
            functionName: "fulfillBasicOrder_efficient_6GL6yc",
            args: [BasicOrderParameters],
          })
        : "";

      const txHash = await walletClient.writeContract({
        address: collectiveCrowdfundAddress,
        abi: singleNFTCrowdfundAbi,
        functionName: "buy",
        args: [
          SEAPORT_EXCHANGE_ADDRESS,
          price,
          aData,
          // fixedGovernanceOpts
        ],
        // chainId: goerli.id,
        // gas: 6000000,
        onSettled(data, error) {
          console.log("Settled", { data, error });
        },
      });
      return txHash;
      // let reciept = await publicClient.waitForTransactionReceipt({
      //     confirmation: 2,
      //     hash: txHash,
      // })

      // console.log("reciept", reciept);

      // const settledPrice = await publicClient.readContract({
      //     address: collectiveCrowdfundAddress,
      //     abi: singleNFTCrowdfundAbi,
      //     functionName: "settledPrice",
      // })
      // console.log("settledPrice", settledPrice);
      // console.log("txhash", txHash);
    } catch (error) {
      console.log("callBuy-error", error);
      onIsSubmitting(false);
      errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
    }
  };

  return { callBuy };
}

export default useBuySingle;
