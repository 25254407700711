import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDataApi, putDataApi } from '../../api/methods';
import { constants } from '../../helpers';

export const getNotificationList = createAsyncThunk(constants.API_PATHS.GET_NOTIFICATION_LIST, (payload) =>
getDataApi({ path: constants.API_PATHS.GET_NOTIFICATION_LIST, data: payload })
);

export const readAllNotification = createAsyncThunk(constants.API_PATHS.READ_ALL_NOTIFICATION, (payload) =>
  putDataApi({ path: constants.API_PATHS.READ_ALL_NOTIFICATION, data: payload })
);

export const readNotification = createAsyncThunk(constants.API_PATHS.READ_NOTIFICATION, (payload) =>
getDataApi({ path: constants.API_PATHS.READ_NOTIFICATION, data: payload })
);
