import { useMemo } from 'react';
import { ReactRange } from '../../../../common/components';
import { formatVetoPeriod } from '../../../../utils';

function Governance({ values, governance, onSetFieldValue, pageName }) {
    const proposalExpirationOptions = useMemo(() => {
        const list = [];
        if (governance.maxProposalExpiration) {
            let i = 1;
            while (i <= governance.maxProposalExpiration) {
                list.push({ label: String(i), value: i });
                i++;
            }
        }
        return list;
    }, [governance]);

    return <div className='collect-in default'>
        <p className='lg fw600'>{pageName === "AddCollective" ? "Default Governance" : "Governing Proposal Form"}</p>

        <div className='d-flex'>
            <div>
                <p>
                    Acceptance Threshold
                    <div className="tooltip-div ml6 va0">
                        <i className="info-line-icon trig"></i>
                        <div className="text">
                            The percentage of votes required to pass a proposal
                        </div>
                    </div>
                </p>
                <h6>{values.acceptanceThreshold}%</h6>
            </div>

            <div>
                <p>
                    Veto Period
                    <div className="tooltip-div ml6 va0">
                        <i className="info-line-icon trig"></i>
                        <div className="text">
                            The length of time the Leader has to Veto an accepted proposal
                        </div>
                    </div>
                </p>
                <h6>{formatVetoPeriod(values?.vetoPeriod)}</h6>
            </div>

            <div>
                <p>
                    Proposal Expiration
                    <div className="tooltip-div ml6 va0">
                        <i className="info-line-icon trig"></i>
                        <div className="text">
                            The length of time a proposal is able to be voted on.
                        </div>
                    </div>
                </p>
                <h6>{formatVetoPeriod(parseFloat(values.proposalExpiration || 0) * 24)}</h6>
            </div>
        </div>
        {pageName === "AddCollective" &&
            <div className='checkbox mb12'>
                <input
                    type="checkbox"
                    checked={values.enableCustomizeGovernance}
                    onChange={() => { onSetFieldValue('enableCustomizeGovernance', !values.enableCustomizeGovernance); }}
                />
                <label className='text-orange fw500'>Customize Governance</label>
            </div>
        }
        {values.enableCustomizeGovernance &&
            <>
                <p className='lg fw500 m4'>Acceptance Threshold</p>

                <div className='range-div purple'>
                    <p>{values.acceptanceThreshold}%, Easier to pass</p>
                    <ReactRange
                        min={governance.acceptanceThreshold}
                        value={values.acceptanceThreshold}
                        disabled={!values.enableCustomizeGovernance}
                        backgroundColor='#7625FF'
                        onChange={(value) => {
                            onSetFieldValue('acceptanceThreshold', value);
                        }}
                    />
                </div>

                <p className='lg fw500 m4'>Veto Period</p>

                <div className='range-div grey'>
                    <p>{values.vetoPeriod} hours</p>
                    <ReactRange
                        value={values.vetoPeriod}
                        disabled={!values.enableCustomizeGovernance}
                        min={governance.minVetoPeriod}
                        max={governance.maxVetoPeriod}
                        step={6}
                        backgroundColor='#5C72B0'
                        onChange={(value) => {
                            onSetFieldValue('vetoPeriod', parseInt(value));
                        }}
                    />
                </div>

                <p className='lg fw500 m4'>Proposal Timeframe</p>

                <div className='form-in right-text'>
                    <select
                        disabled={!values.enableCustomizeGovernance}
                        value={values.proposalExpiration}
                        onChange={(event) => {
                            onSetFieldValue('proposalExpiration', event.target.value);
                        }}
                    >
                        {proposalExpirationOptions.map(op => (
                            <option value={op.value}> {op.label} {op.value === 1 ? 'Day' : 'Days'}</option>
                        ))}
                    </select>
                    <span className='icon-right'><p className='fs12'>DAYS</p></span>
                </div>
            </>
        }
    </div>;
}

export default Governance;
