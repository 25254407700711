/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import Component from './Component';
import { constants } from '../../../../../helpers';
import { useReduxStore } from '../../../../custom-hooks';

const carouselItems = [
    {
      id: 1,
      heading: "Collect",
      subHeading: "Collect the NFTs you’ve always wanted"
    },
  {
    id: 2,
    heading: "Co-Own",
    subHeading: `Buy fractional ownership of any NFT with as little as .01 ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`
  }
  ];

function ContributeWarning(props) {
  const { authReducer } = useReduxStore();
    const [index, setIndex] = useState(0);

    const handleNext = () => {
      setIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    };

    const handleSkip = () => {
      // Assuming you want to skip 2 items, you can adjust this as needed
      setIndex(() => (2) % carouselItems.length);
    };

    return <Component
        {...props}
        carouselItems={carouselItems}
        index={index}
        onNext={handleNext}
      onSkip={handleSkip}
      authReducer={authReducer}
    />;
}

export default ContributeWarning;
