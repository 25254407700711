import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Asterisk, ValidationMessage } from "../../../../common/components";
import { messages } from "../../../../helpers";

function checkEmptyProposal(htmlString) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, 'text/html');
    return !parsedHtml.body.textContent.trim();
}

function Proposal({ values, proposalError, onSetFieldValue, onSetProposalError }) {
    const quillStyle = {
        // Set the desired styling of the editor
    };

    return <div className='collect-in'>
        <p className='lg m6'>Proposal<Asterisk /> </p>
        <p className='italic text-black m4'>Include at least these four pieces of information:</p>
        <ul className='info-ul'>
            <li>Your background - What makes you a suitable leader?</li>
            <li>NFT highlights - What are the most compelling statistics about this NFT or Game?</li>
            <li>NFT utility - What utility does the NFT have that will generate value?</li>
            <li>Your plan - Do you plan to hold it for a certain period of time for certain reasons?</li>
        </ul>
        <ReactQuill
            style={quillStyle}
            theme="snow"
            value={values.proposal}
            onChange={(value) => {
                if (checkEmptyProposal(value)) {
                    onSetProposalError(messages.IS_REQUIRED('Proposal'));
                } else {
                    onSetProposalError('');
                }
                onSetFieldValue('proposal', value);
            }}
            onBlur={() => {
                if (checkEmptyProposal(values.proposal)) {
                    onSetProposalError(messages.IS_REQUIRED('Proposal'));
                } else {
                    onSetProposalError('');
                }
            }}
        />
        <ValidationMessage message={proposalError} />
    </div>;
}

export default Proposal;
