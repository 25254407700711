import { useCallback, useState } from "react";
import { getBasicAuthDataApi } from "../../../../api/methods";
import activityLogo from "../../../../assets/images/activityLogo.png";
import { useAppNavigate, useAppSelector, useHandleCalls, useLoginFlow, useReduxStore } from "../../../../common/custom-hooks";
import constants from "../../../../helpers/constants";
import { truncateToDecimalDigits } from "../../../../utils";

function CollectiveRankings() {
    const navigate = useAppNavigate();
    const [details, setDetails] = useState();
    const { authReducer } = useReduxStore();
    const { isloggedin } = useAppSelector(state => state.auth);
    const { onOpenLoginFlowModal } = useLoginFlow();

    const fetchTopCollectors = useCallback(() => {
        const query = {
            type: "TOP_COLLECTORS",
            loaderType: constants.LOADER.TYPE.TABLE,
            callback: (response) => {
                if (response?.statusCode === 200) {
                    if (response.data?.length) {
                        setDetails(response.data);
                    }
                }
            },
        };

        getBasicAuthDataApi({
            path: constants.API_PATHS.HOME_TOP_COLLECTORS_COLLECTIVES,
            data: query,
        });
    }, []);

    useHandleCalls(fetchTopCollectors, [], "fetchTopCollectorsForHome");

    return <section className='collector-ranks'>
        <div className='container'>
            <div className='heading'>
                <h2>Top Collectors</h2>

                <div className='ml-div web-btn'>
                    <button className='link' onClick={() => navigate('/rankings?tab=collector')}>View All</button>
                </div>
            </div>
            <div className='rank-cards'>
                {details?.length ? details.slice(0, 10).map((collective, index) => {
                    return <div key={collective.id}>
                        <div className='rank-card cursor-pointer' onClick={() => {
                            if (isloggedin) {
                                if (authReducer.userId === collective.userData._id) {
                                    navigate(`/profile`)
                                } else {
                                    navigate(`/someone-profile?userId=${collective.userData._id}`)
                                }
                            } else {
                                onOpenLoginFlowModal();
                            }
                        }} >
                            <div className='p'>#{index + 1}</div>

                            <figure>
                                <img src={collective.userData.profilePicture ? collective.userData.profilePicture : activityLogo} alt="smLogo" />
                            </figure>

                            <div className='text'>
                                <h6>{collective.userData.userName ? collective.userData.userName : 'NA'}</h6>
                                <div className='h6'><i className='etherium-icon'></i> {collective?.contribution ? truncateToDecimalDigits({
                                    number: collective.contribution,
                                    limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                                }) : 0}</div>
                            </div>

                            {/* <div className='ml-auto'>
                                <button className='btn-white'>Follow</button>
                            </div> */}
                        </div>
                    </div>;
                }) : <div className="text-center mx-auto">
                    <h6>No Top collectors Found </h6>
                    <p>List of all top 10 collectors will be shown here </p>
                </div>}
            </div>

            <div className='phn-btn'>
                <button className='btn-white' onClick={() => navigate('/rankings?tab=collector')}>View All</button>
            </div>
        </div>
    </section>;
}

export default CollectiveRankings;
