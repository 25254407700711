/**
 * Gets the value of a parameter from the current URL's query string.
 *
 * @param {string} key - The parameter key whose value is to be retrieved.
 * @returns {string|null} - The value of the parameter or null if not found.
 */
export function getParamValue(key) {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
}

/**
 * Opens a URL in a new browser tab.
 *
 * @param {Object} options - The options object.
 * @param {string} options.url - The URL to open in a new tab.
 */
export function openInNewTab({ url }) {
  if (!url) return;
  window.open(url, "_blank");
}
