import { useCallback, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getBasicAuthDataApi } from '../../../../api/methods';
import Loader from '../../../../common/components/loader';
import { useAppNavigate, useHandleCalls } from '../../../../common/custom-hooks';
import { constants } from "../../../../helpers";
import { calculateCompletionPercent, calculateRemainingTime, setDecimalValue } from '../../../../utils';
import { renderTooltip } from '../../../../utils/tooltip';
import createdPlaceholder from "../../../../assets/images/created-placeholder.png";
import activityLogo from "../../../../assets/images/activityLogo.png";
import { NameTooltip } from '../../../../common/components';

function FeaturedCollectives() {
    const navigate = useAppNavigate();
    const [isFetching, setIsFetching] = useState(true);
    const [details, setDetails] = useState([]);

    const fetchFeaturedData = useCallback(() => {
        setIsFetching(true);
        const query = {
            limit: 9,
            loaderType: constants.LOADER.TYPE.TABLE,
            featured: true,
            callback: (response) => {
                if (response?.statusCode === 200) {
                    setIsFetching(false);
                    if (response.data) {
                        setDetails(response.data?.data);
                    }
                }
            },
        };

        getBasicAuthDataApi({
            path: constants.API_PATHS.HOME_COLLECTIVE_LIST,
            data: query,
        });
    }, []);

    useHandleCalls(fetchFeaturedData, [], "fetchFeaturedData");


    return <section className='collectives'>
        <div className='container'>
            <div className='heading'>
                <h2>Featured Collectives</h2>
                {/*
                <div className='ml-div'>
                    <div className='btns-grp'>
                        <div><button className='btn-blank active'>Nearly Full <i className='hourglass-icon'></i></button></div>
                        <div><button className='btn-blank'>Recent <i className='clock-icon'></i></button></div>
                        <div><button className='btn-blank'>Full <i className='popper-icon'></i></button></div>
                    </div>
                </div> */}
            </div>
            <>
                {details && details?.length && !isFetching ? (
                    <div className="nft-cards">
                        {
                            details?.map((collective) => {
                                const completionPercentage = collective?.contributionGoal?.amount
                                    ? calculateCompletionPercent({ totalContribution: collective?.contributeData?.totalContribution, amount: collective?.contributionGoal?.amount })
                                    : 0;
                                const isGoalCompleted = completionPercentage >= 100;
                                return (
                                    <div className="cursor-pointer" key={collective.id} onClick={() =>
                                        navigate(
                                            `${constants.BROWSER_ROUTES.VIEW_COLLECTIVE}?collectiveId=${collective._id}`
                                        )
                                    }>
                                        <div className="nft-card">
                                            <figure className="img">
                                                <img
                                                    src={collective?.status === constants.COLLECTIVE.STATUS.ACQUIRED || collective?.status === constants.COLLECTIVE.STATUS.SOLD ? collective?.buyNftDetails?.image ? collective.buyNftDetails?.image : createdPlaceholder : collective?.image ? collective?.image : createdPlaceholder}
                                                    alt="Nft1"
                                                />
                                            </figure>

                                            <div className="text">
                                                <figure>
                                                    <img
                                                        src={collective.image ? collective.image : activityLogo}
                                                        alt="smLogo"
                                                    />
                                                </figure>

                                                <div className="title">
                                                    <div className="title-name">
                                                        <NameTooltip name={collective.title} />
                                                        <span className="by-name">
                                                        with <b className="fw400">{collective?.user_detail?.userName ? collective?.user_detail?.userName : 'NA'}</b>
                                                        </span>
                                                    </div>
                                                    <div className="reward-icons">
                                                        {collective.utilityAvailable?.length ? collective.utilityAvailable?.length < 5 ? collective.utilityAvailable.map(() => {
                                                            return (
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    delay={{ show: 250, hide: 400 }}
                                                                    overlay={renderTooltip}
                                                                >

                                                                    <i className="reward-icon"></i>

                                                                </OverlayTrigger>
                                                            );
                                                        }) : <> <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltip}
                                                        >

                                                            <i className="reward-icon"></i>

                                                        </OverlayTrigger> <span className='fw600 text-orange'> x5</span></> : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-details">
                                                <div className="progress-div">
                                                    <p>{completionPercentage || 0}% Contributed</p>
                                                    {/* <p>{isGoalCompleted ? setDecimalValue({ value: completionPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE }) : setDecimalValue({ value: completionPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE })}% Contributed</p> */}
                                                    <ProgressBar
                                                        className={`lg ${isGoalCompleted ? "green" : ""}`}
                                                        now={completionPercentage}
                                                    />
                                                </div>

                                                <div className="lower">
                                                    <p>
                                                        <span> {collective.created ? calculateRemainingTime(
                                                            {
                                                                created: collective.created,
                                                                period: collective.contributionPeriod,
                                                                isCompleted: collective.status === constants.COLLECTIVE.STATUS.ACQUIRED,
                                                                endTimeStamp: collective?.collective_close_timing
                                                            }
                                                        ) : 0} </span> Left
                                                    </p>
                                                    <p>
                                                        <i className="etherium-icon"></i>{" "}
                                                        <span>
                                                            {collective.status === constants.COLLECTIVE.STATUS.ACQUIRED || collective.status === constants.COLLECTIVE.STATUS.CLOSED
                                                                ? '0.00'
                                                                : collective?.contributionGoal?.amount ? setDecimalValue({ value: collective?.contributionGoal?.amount - collective?.contributeData?.totalContribution, formatted: true }) === 0 ? "0.00" : setDecimalValue({ value: collective?.contributionGoal?.amount - collective?.contributeData?.totalContribution, formatted: true }) : '-'
                                                            }

                                                        </span>{" "}
                                                        Left
                                                    </p>

                                                    <p>
                                                        <span>{collective.status === constants.COLLECTIVE.STATUS.CLOSED ? 0 : collective?.contributeData?.totalCollectors || 0}</span>{" "}
                                                        collectors
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                ) : isFetching ? <Loader /> : <div className="text-center mx-auto">
                    <h6>No Featured collectives Found </h6>
                    <p>List of all featured collectives will be shown here </p>
                </div>}

            </>
        </div>

        {/* <div className='text-center'>
                <button className='btn-white'>View More</button>
            </div> */}
    </section>;
}

export default FeaturedCollectives;
