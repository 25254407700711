import { Formik } from 'formik';

function FormikWrapper({ initialValues, enableReinitialize, validationSchema, onSubmit, children }) {
    return (
        <Formik
            enableReinitialize={!!enableReinitialize}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {children}
        </Formik>
    );
};

export default FormikWrapper;
