import { useEffect } from "react";

// A global object to store timer references for each key
const globalData = {};

/**
 * Custom hook to debounce and manage calls to a fetch data function.
 * @param {Function} fetchData - Function that fetches data.
 * @param {Array} deps - Dependency array to trigger the effect.
 * @param {string} key - Unique key for the global timer reference.
 */
function useHandleCalls(fetchData, deps = [], key = "fetchData") {
  useEffect(() => {
    // Clear any existing timer for the specified key
    if (globalData[key]) {
      clearTimeout(globalData[key]);
    }

    // Set a new timer to call the fetchData function after a delay
    globalData[key] = setTimeout(() => {
      fetchData();
    }, 500); // Adjust the delay time as needed

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]); // Trigger the effect when dependencies change

  // No return value is needed for this custom hook
}

export default useHandleCalls;
