import FullComponent from "./modules/full";
import NearlyComponent from "./modules/nearly";
import RecentComponent from "./modules/recent";

const Component = ({ activeTab, setActiveTab }) => {
  return (
    <section className="collectives">
      <div className="container">
        <div className="heading">
          <h2>Collectives</h2>

          <div className="ml-div">
            <div className="btns-grp">
              <div>
                <button
                  className={
                    activeTab === "created" ? "btn-blank active" : "btn-blank"
                  }
                  onClick={() => {
                    setActiveTab("created")
                  }}
                >
                  Recent <i className="clock-icon"></i>
                </button>
              </div>
              <div>
                <button
                  className={
                    activeTab === "nearlyFull"
                      ? "btn-blank active"
                      : "btn-blank"
                  }
                  onClick={() => {
                    setActiveTab("nearlyFull")
                  }}
                >
                  Nearly Full <i className="hourglass-icon"></i>
                </button>
              </div>

              <div>
                <button
                  className={
                    activeTab === "full" ? "btn-blank active" : "btn-blank"
                  }
                  onClick={() => {
                    setActiveTab("full")
                  }
                  }
                >
                  Full <i className="popper-icon"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        {activeTab === "full" && (
          <FullComponent  activeTab={activeTab} />
        )}
        {activeTab === "created" && (
          <RecentComponent  activeTab={activeTab}  />
        )}
        {activeTab === "nearlyFull" && (
          <NearlyComponent  activeTab={activeTab}  />
        )}


      </div>
    </section>
  );
};

export default Component;
