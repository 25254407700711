import { useCallback, useMemo } from "react";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { getDataApi } from "../../../api/methods";
import { constants, messages } from "../../../helpers";
import {
  addButtonLoader,
  capitalizeFirstLetter,
  errorAlert,
  getParamValue,
} from "../../../utils";
import { ConnectWallet } from "../../components/index.js";
import { useAddCollectiveContribution } from "../../custom-hooks";
import useCreateCollectionNFTCollective from "./useContributeInCollectionNFTEthCollective";
import useCreateSingleNFTCollective from "./useContributeInSingleNFTEthCollective";

function Contribute(props) {
  const {
    isCollectiveType,
    isDisabledSubmit,
    data,
    isSubmitting,
    resetForm,
    onIsSubmitting,
  } = props;
  const { data: walletClient } = useWalletClient();
  const { data: publicClient } = usePublicClient();

  const { address } = useAccount();

  const buttonClassName = useMemo(() => {
    return addButtonLoader({
      className: "btn icon w-100",
      isLoading: isSubmitting,
    });
  }, [isSubmitting]);

  const { addCollectiveContribution } = useAddCollectiveContribution({
    data,
    resetForm,
    onIsSubmitting,
  });

  const checkingCollectiveContribution = useCallback(async () => {
    const collectiveId = getParamValue("collectiveId");
    const step1 = await getDataApi({
      path: constants.API_PATHS.CHECK_CONTRIBUTION,
      data: { collectiveId },
    });
    const step2 = step1?.data || {};
    const dataToReturn = {
      totalCollectiveContribution: step2.totalCollectiveContribution || 0,
      totalCollectivePercentage: step2.totalcollectivePercentage || 0,
      totalContributionPercentage: step2.totalContributionPercentage || 0,
    };

    // if (dataToReturn.totalCollectivePercentage >= 100) {
    //   errorAlert(messages.COLLECTIVE.COLLECTIVE_GOAL_REACHED);
    //   return false;
    // }

    if (dataToReturn.totalContributionPercentage >= 20) {
      errorAlert(
        capitalizeFirstLetter(
          messages.COLLECTIVE.MAX_CONTRIBUTE_PERCENT({
            contributionGoal: data.contributionGoal,
          })
        ),
        false
      );
      return false;
    }

    const pendingContributionPercentage =
      20 - dataToReturn.totalContributionPercentage;

    const currentContributionPercentage =
      (data.contribute / data.contributionGoal) * 100;

    if (currentContributionPercentage > pendingContributionPercentage) {
      errorAlert(
        capitalizeFirstLetter(
          messages.COLLECTIVE.MAX_CONTRIBUTE_PERCENT({
            contributionGoal: data.contributionGoal,
          })
        ),
        false
      );
      return false;
    }

    return true;
  }, [data]);

  const hookProps = {
    ...props,
    walletClient,
    publicClient,
  };

  const { callContribute: callContributeInSingleNFT } =
    useCreateSingleNFTCollective(hookProps);
  const { callContribute: callContributeInCollectionNFT } =
    useCreateCollectionNFTCollective(hookProps);

  const callContribute = isCollectiveType
    ? callContributeInCollectionNFT
    : callContributeInSingleNFT;

  if (!address) {
    return (
      <ConnectWallet
        type="collective-contribute"
        disabled={isDisabledSubmit}
        buttonClassName={buttonClassName}
      />
    );
  }

  return (
    <button
      className={buttonClassName}
      disabled={isDisabledSubmit}
      onClick={async () => {
        onIsSubmitting(true);
        const canContribute = await checkingCollectiveContribution();
        if (canContribute) {
          callContribute().then((result) => {
            addCollectiveContribution(result);
          });
        } else {
          onIsSubmitting(false);
        }
      }}
    >
      <i className="wallet-white-icon" />
      Submit
    </button>
  );
}

export default Contribute;
