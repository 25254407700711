import { Modal } from 'react-bootstrap';
import { GreenCheck } from '../../../../../assets/images/images';
import { constants } from '../../../../../helpers';

function Component({ isOpen, timer, onClose, onOpenModal }) {
    return (
        <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header>
                <figure><img src={GreenCheck} alt="Logo" /></figure>
            </Modal.Header>

            <Modal.Body className='text-center'>
                <h5 className='m6'>Password Updated!</h5>
                <p className='m10 text-text'>Your password has been successfully updated</p>

            </Modal.Body>

            <Modal.Footer>
                <div className='btns'>
                    <button
                        className='btn lg'
                        onClick={() => {
                            onOpenModal(constants.MODAL.LOGIN);
                            clearTimeout(timer);
                        }}
                    >
                        Back to Sign In
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default Component;
