import { Modal } from 'react-bootstrap';
import { GreenCheck } from '../../../../../assets/images/images';

function Component({ isOpen, onClose }) {
    return (
        <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header closeButton>
                <figure><img src={GreenCheck} alt="Logo" /></figure>
            </Modal.Header>

            <Modal.Body className='text-center'>
                <h5 className='m6'>Verified Successfully</h5>
                <p className='m10 text-text'>Thank you for signing up with myMetaFi.
                </p>
            </Modal.Body>
        </Modal>
    );
}

export default Component;
