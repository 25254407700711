import { Asterisk } from '../../../../common/components';
import { constants } from '../../../../helpers';
import { UtilitiesView } from './modules';

const options = [
    { label: 'Staking', value: 'Staking' },
    { label: 'Airdrops', value: 'Airdrops' },
    { label: 'Licensing', value: 'Licensing' },
    { label: constants.COLLECTIVE.UTILITY_SELECTION.MEMBERSHIP, value: constants.COLLECTIVE.UTILITY_SELECTION.MEMBERSHIP },
    { label: 'Revenue Share', value: 'Revenue Share' },
    { label: 'Boosted in-game earnings', value: 'Boosted in-game earnings' },
    { label: constants.COLLECTIVE.UTILITY_SELECTION.LENDING, value: constants.COLLECTIVE.UTILITY_SELECTION.LENDING },
    { label: 'Free Mint', value: 'Free Mint' },
    { label: 'Royalties', value: 'Royalties' },
    { label: constants.COLLECTIVE.UTILITY_SELECTION.EXCLUSIVE_COMMUNITY_ACCESS, value: constants.COLLECTIVE.UTILITY_SELECTION.EXCLUSIVE_COMMUNITY_ACCESS },
    { label: 'Whitelisted', value: 'Whitelisted' }
];

function UtilityAvailable({ values, onSetFieldValue }) {

    const utilitiesViewProps = {
        values,
        onSetFieldValue
    };

    return <div className='collect-in nft-cate'>
        <p className='lg m20'>Rewards Available<Asterisk /> </p>

        <div className='form-row'>
            <div className='col-md-6'>
                <UtilitiesView {...utilitiesViewProps} utilities={options.slice(0, Math.ceil(options.length / 2))} />
            </div>
            <div className='col-md-6'>
                <UtilitiesView {...utilitiesViewProps} utilities={options.slice(Math.ceil(options.length / 2))} />
            </div>
        </div>

        {/* <p><i className='cart-icon mr6'></i> Selling Item Details</p> */}
    </div>;
}

export default UtilityAvailable;
