import { createSlice } from "@reduxjs/toolkit";
import { constants } from "../../../helpers";
import { userRegistration, verifyOtp } from "../../services/auth";
import { getModalActionType } from "./Helpers";

const initialLoadProgress = Object.freeze({
  isBuyNftLoading: false,
});

const initialSignUpDetails = Object.freeze({
  userId: "",
  email: "",
  username: "",
  password: "",
  countryCode: "",
  phoneNumber: "",
  enableNotifications: false,
  acceptTerms: false,
  wallet: {
    isConnect: false,
  },
});

const initialModalDetails = Object.freeze({
  openedModalType: "",
  canResendOtp: false,
  signUp: initialSignUpDetails,
  updateEmail: { email: "" },
  forgotPassword: { userId: "", email: "" },
  referral: { code: "", email: "" },
  collective: {},
  proposal: {},
  votersInfo: {},
  referralGoogle: {},
  collectorDetails: {},
  totalVolume: "",
  isTwitterLogin: false,
  isDiscordLogin: false,
  twitterProfileUrl: "",
  discordProfileUrl: "",
  totalContributionDetails: {},
});

const initialState = {
  isLoading: false,
  isTableLoading: false,
  modal: initialModalDetails,
  actionType: "",
  refreshCollectiveDetails: false,
  refreshDashboardDetails: false,
  votingDelegationList: [],
  refreshVotingandCommentDetails: false,
  refreshVotingDelegationList: false,
  refreshAllCollectorList: false,
  refreshProfileDetails: false,
  isReadyForBuyNft: false,
  loadProgress: initialLoadProgress,
  pageCount: 0
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    /** Setting loader state */
    setIsLoader(state, action) {
      state.isLoading = action.payload;
    },

    setIsTableLoader(state, action) {
      state.isTableLoading = action.payload;
    },

    /**
     * Toggle modal handling
     */
    onToggleModal(state, action) {
      const modalType = action.payload?.modalType || "";
      const actionType = getModalActionType(
        modalType,
        state.modal.openedModalType
      );

      if (modalType) {
        state.modal.openedModalType = modalType;
        state.actionType = actionType;
      } else {
        state.modal = initialModalDetails;
        state.actionType = "";
      }
    },

    // when you add any field so please don't forget to clear from here
    clearCommonReducerState() {
      return initialState;
    },

    onSetCanResendOtp(state, action) {
      state.modal.canResendOtp = !!action.payload?.canResendOtp;
    },

    /**
     * setting opening modal type
     */
    setActionType(state, action) {
      state.actionType = action.payload.type;
    },

    clearSignUpDetails(state) {
      state.modal.signUp = initialSignUpDetails;
    },

    /**
     *  forgot password flow
     */
    setForgotPasswordDetails(state, action) {
      const forgotPassword = {
        email: action.payload.email,
      };
      state.modal.forgotPassword = forgotPassword;
    },

    clearForgotPasswordDetails(state) {
      state.modal.forgotPassword = {};
    },

    /**
     *  Update email flow
     */
    setUpdateEmailDetails(state, action) {
      const updateEmail = {
        email: action.payload.email,
      };
      state.modal.updateEmail = updateEmail;
    },

    clearUpdateEmailDetails(state) {
      state.modal.updateEmail = {};
      state.actionType = "";
    },
    /**
     *  End of Update email flow
     */

    setIsWalletConnect(state, action) {
      const wallet = {
        isConnect: !!action.payload?.isConnect,
      };
      state.wallet = wallet;
    },

    /**
     *  Sign up using referral code flow
     */
    setReferralDetails(state, action) {
      const referralDetails = {
        code: action.payload.referralCode || "",
        email: action.payload.email || "",
      };
      state.modal.referral = referralDetails;
    },
    /**
     *  End of Sign up using referral code flow
     */

    /**
     *  Saving Collective details
     */
    setCollectiveDetails(state, action) {
      state.modal.collective = {
        ...(state.modal.collective || {}),
        ...action.payload,
      };
    },
    /**
     *  End of Saving Collective details
     */

    /**
     *  Saving Proposal details
     */
    setProposalDetails(state, action) {
      state.modal.proposal = {
        ...(state.modal.proposal || {}),
        ...action.payload,
      };
    },
    /**
     *  End of Saving Proposal details
     */
    /**
     *   Saving TotalVolume
     */
    setTotalVolume(state, action) {
      state.modal.totalVolume = action.payload.totalVolume;
      state.modal.isTwitterLogin = action.payload.isTwitterLogin;
      state.modal.isDiscordLogin = action.payload.isDiscordLogin;
      state.modal.twitterProfileUrl = action.payload.twitterProfileUrl;
      state.modal.discordProfileUrl = action.payload.discordProfileUrl;
    },

    /**
     *  End of Saving TotalVolume
     */

    /**
     *  Saving Voters details
     */
    setVoterInfo(state, action) {
      state.modal.votersInfo = {
        ...(state.modal.votersInfo || {}),
        ...action.payload,
      };
    },

    setReferralInfo(state, action) {
      state.modal.referralGoogle = {
        ...(state.modal.referralGoogle || {}),
        ...action.payload,
      };
    },
    /**
     *  End of Saving Voter details
     */

    setRefreshCollectiveDetails(state, action) {
      state.refreshCollectiveDetails = !!action.payload;
    },
    setRefreshDashbordDetails(state, action) {
      state.refreshDashboardDetails = !!action.payload;
    },
    setRefreshProfileDetails(state, action) {
      state.refreshProfileDetails = !!action.payload;
    },

    setRefreshVotingandCommentDetails(state, action) {
      state.refreshVotingandCommentDetails = !!action.payload;
    },

    setVotingDelegationList(state, action) {
      state.votingDelegationList = action.payload;
    },
    clearVotingDelegationList(state, action) {
      state.votingDelegationList = [];
    },
    setRefreshVotingDelegationList(state, action) {
      state.refreshVotingDelegationList = !!action.payload;
    },
    setRefreshAllCollectorList(state, action) {
      state.refreshAllCollectorList = !!action.payload;
    },

    /**
     *  Saving Collector details for modal view
     */
    setCollectorDetails(state, action) {
      const collectorDetails = { ...action.payload };
      state.modal.collectorDetails = collectorDetails;
    },
    /**
     *  End of Saving Collector details for modal view
     */

    /**
     *  Set ready for buy nft
     */
    setReadyForBuyNft(state, action) {
      state.isReadyForBuyNft = !!action.payload.isReady;
      state.modal.totalContributionDetails = { ...(action.payload || {}) };
    },
    /**
     *  End of Set ready for buy nft
     */

    /**
     *  Set progress loading for buy nft
     */
    setBuyNftProgressLoading(state, action) {
      state.loadProgress.isBuyNftLoading = !!action.payload;
    },
    /**
     *  End of Set progress loading for buy nft
     */

    setPageCount(state) {
      state.pageCount = state.pageCount + 1;
    },

    resetPageCount(state) {
      state.pageCount = 0;
    },
  },

  /**
   * extra reducers
   */
  extraReducers: (builder) => {
    builder.addCase(userRegistration.fulfilled, (state, action) => {
      const details = {};
      details.userId = action.payload?.data?._id || "";
      details.email = action.payload?.data?.email || "";
      details.username = action.meta?.arg?.userName || "";
      details.password = action.meta?.arg?.password || "";
      details.countryCode = action.meta?.arg?.countryCode || "";
      details.phoneNumber = action.meta?.arg?.fullMobileNo || "";
      details.enableNotifications =
        action.meta?.arg?.pushNotificationStatus || false;
      details.acceptTerms = true;

      // saving signup modal details
      state.modal.signUp = details;
    });

    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      if (state.actionType === constants.API_TYPE.FORGOT_PASSWORD) {
        const forgotPassword = { ...state.modal.forgotPassword };
        forgotPassword.userId = action.payload?.data?.userId || "";
        state.modal.forgotPassword = forgotPassword;
      }
    });
  },
});

export default commonSlice.reducer;
export const {
  setIsLoader,
  setIsTableLoader,
  onToggleModal,
  clearCommonReducerState,
  onSetCanResendOtp,

  setActionType,

  clearSignUpDetails,

  setUpdateEmailDetails,
  clearUpdateEmailDetails,

  setForgotPasswordDetails,
  clearForgotPasswordDetails,

  setIsWalletConnect,

  setReferralDetails,

  setCollectiveDetails,
  setRefreshCollectiveDetails,
  setRefreshDashbordDetails,

  setProposalDetails,
  setTotalVolume,

  setVoterInfo,
  setReferralInfo,
  setRefreshVotingandCommentDetails,
  setRefreshProfileDetails,

  setVotingDelegationList,
  clearVotingDelegationList,
  setRefreshVotingDelegationList,

  setRefreshAllCollectorList,

  setCollectorDetails,

  setReadyForBuyNft,

  setBuyNftProgressLoading,

  setPageCount,
  resetPageCount
} = commonSlice.actions;
