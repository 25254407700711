import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useCustomWallet, useLoginFlow, useReduxStore } from "../../custom-hooks";

function CustomConnectWallet({ buttonClassName, hasPermissionToView }) {
    const { authReducer } = useReduxStore();
    const { onOpenLoginFlowModal } = useLoginFlow();
    const { address } = useCustomWallet();

    if (address || !hasPermissionToView) {
        return null;
    }

    if (!authReducer.isloggedin) {
        return <button
            className={buttonClassName}
            onClick={onOpenLoginFlowModal}
        >
            Connect Wallet
        </button>;
    }

    return (
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                // Note: If your app doesn't use authentication, you
                // can remove all 'authenticationStatus' checks
                const ready = mounted && authenticationStatus !== "loading";
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus || authenticationStatus === "authenticated");

                return (
                    <div
                        className="w-100"
                        {...(!ready && {
                            "aria-hidden": true,
                            style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return <button
                                    className={buttonClassName}
                                    onClick={(event) => {
                                        openConnectModal(event);
                                    }}
                                >
                                    Connect Wallet
                                </button>;
                            }
                            if (chain.unsupported) {
                                return <button
                                    className={buttonClassName}
                                    onClick={openChainModal}
                                >
                                    Connect Wallet
                                </button>;
                            }

                            return <button
                                className={buttonClassName}
                                onClick={openAccountModal}
                            >
                                Connect Wallet
                            </button>;
                        })()}
                    </div>
                );
            }}
        </ConnectButton.Custom>
    );
}

CustomConnectWallet.defaultProps = {
    hasPermissionToView: true
};

export default CustomConnectWallet;
