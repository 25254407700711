import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Link } from "react-router-dom";
import { setDecimalValue } from "../../../../../utils";

function ProfileWallet({ balance, isConnected, onSetConnectingWallet }) {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Link
                    className='link ml-auto align-self-center fs15'
                    onClick={(event) => {
                      openConnectModal(event);
                      onSetConnectingWallet(true);
                    }}
                  >
                    <u>Connect Now</u>
                  </Link>
                );
              }
              if (chain.unsupported) {
                return (
                  // <div>
                  //   <button
                  //     type="button"
                  //     className='link ml-auto align-self-center fs15'
                  //     onClick={openChainModal}
                  //   >
                  //     Wrong network
                  //   </button>
                  // </div>
                  <Link
                    className='link ml-auto align-self-center fs15'
                    onClick={openChainModal}
                  >
                    <u>Wrong network</u>
                  </Link>
                );
              }
              return (
                <div>
                  <button
                    type="button"
                    className="rainbow-btn"
                    onClick={openAccountModal}
                  >
                    {isConnected
                      ? <>
                        <i className='etherium-icon' />
                        {setDecimalValue({ value: balance?.formatted, formatted: true })}
                      </>
                      : <span className='link ml-auto align-self-center fs15'>
                        <u>Connect Now</u>
                      </span>
                    }
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}

export default ProfileWallet;
