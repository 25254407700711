import { Link } from "react-router-dom";
import { constants } from "../../../../../helpers";

function CloseButton() {
    return <div className='ml-auto'>
        <Link to={constants.BROWSER_ROUTES.PROFILE}><i className='close-icon'></i></Link>
    </div>;
}

export default CloseButton;
