import { useMemo } from "react";
import { useGetVotingDelegationDetails, useReduxStore } from "../../custom-hooks";
import Component from "./Component";

function VotingDelegationDropdown(props) {
    const { authReducer, commonReducer } = useReduxStore();
    const { delegationDetails } = useGetVotingDelegationDetails();

    const leaderOption = useMemo(() => {
        return {
            userData: {
                userName: authReducer?.username || '',
                profilePicture: authReducer?.profilePicture || '',
                walletAddress: authReducer?.wallet?.address || '',
            },
            votingDelegationId: authReducer.userId || ''
        };
    }, [authReducer]);

    const options = useMemo(() => {
        const list = commonReducer?.votingDelegationList || [];
        let listToReturn = [];

        /**
         * Adding login user when its not coming from api
        */
        const isExistLoginUser = list.find(op => op.userData?.userName === leaderOption?.userData?.userName);
        if (isExistLoginUser) {
            listToReturn = list.slice();
        } else {
            listToReturn = [
                leaderOption,
                ...list
            ];
        }
        // End

        /**
         * hiding delegated user
        */
        if (delegationDetails.myDelagation?.delegatedUsername) {
            return listToReturn.filter(op => op.userData?.userName !== delegationDetails.myDelagation.delegatedUsername);
        }
        // End

        /**
         * hiding login user when it is self delegated
        */
        const isSelfDelegated = listToReturn.find(op => {
            if (!isExistLoginUser) {
                return (op.userData?.userName === authReducer.username);
            }
            return (op.userData?.userName === authReducer.username) && (op.delegatedUsername === authReducer.username);
        });
        if (isSelfDelegated) {
            return listToReturn.filter(op => op.userData?.userName !== authReducer.username);
        }
        // End

        return listToReturn;
    }, [commonReducer, leaderOption, delegationDetails.myDelagation, authReducer.username]);

    return <Component
        {...props}
        authReducer={authReducer}
        commonReducer={commonReducer}
        options={options}
        delegationDetails={delegationDetails}
    />;
}

export default VotingDelegationDropdown;
