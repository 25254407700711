import { useCallback, useState } from "react";
import { getDataApi } from "../../api/methods";
import { constants } from "../../helpers";
import { isProfileVerified } from "../../utils";
import { useAppSelector } from "./redux";
import useHandleCalls from "./useHandleCalls";

function useUserProfileDetails() {
  const { isloggedin } = useAppSelector((state) => state.auth);
  const [details, setDetails] = useState({});

  const handleUserDetails = useCallback(() => {
    if (isloggedin) {
      getDataApi({ path: constants.API_PATHS.GET_USER_PROFILE }).then(
        (response) => {
          if (response?.data) {
            setDetails({
              profilePicture: response.data.userData.profilePicture || "",
              username: response.data.userData.userName || "",
              isProfileVerified: isProfileVerified(response.data?.userData),
              created: response.data.userData.created || "",
              bio: response.data.userData.bio || "",
              collected: response.data.collected || 0,
              createdNo: response.data.created || 0,
              // twitter: response.data.userData.twitterProfileUrl || "",
              discordProfileUrl: response.data.userData.discordProfileUrl || "",
              totalSpent: response.data.total_spent || 0,
              borderImage: response.data.userData.borderImage || "",
              coverPicture: response.data.userData.coverPicture || "",
              // isTwitterLogin: !!response.data.isTwitterLogin,
              isDiscordLogin: !!response.data.userData.isDiscordLogin,
              isTwitterLogin: !!response.data.userData.isTwitterLogin,
              gas_credit: response.data.gas_credit || 0,
              twitterProfileUrl:
                response.data?.userData?.twitterProfileUrl || "",
              // isTelegramLogin: !!response.data.isTelegramLogin,
            });
          }
        }
      );
    }
  }, [isloggedin]);

  useHandleCalls(handleUserDetails, [isloggedin]);

  return { details, onFetchUserDetails: handleUserDetails };
}

export default useUserProfileDetails;
