import { useEffect } from 'react';
import { onToggleModal, setRefreshVotingandCommentDetails } from '../../../../../redux/features/commonSlice';
import { useAppDispatch } from '../../../../custom-hooks';
import Component from './Component';

/**
 * AddVoteSuccess component for handling the success after adding a vote.
 *
 * This component triggers the closing of the modal and updates voting and comment details.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @returns {JSX.Element} - Rendered AddVoteSuccess component.
 */
function AddVoteSuccess(props) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Automatically close the modal and update voting and comment details after a delay
        setTimeout(() => {
            dispatch(onToggleModal());
            dispatch(setRefreshVotingandCommentDetails(true));
            setTimeout(() => {
                dispatch(setRefreshVotingandCommentDetails(false));
            }, 1000);
        }, 3 * 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Render the Component
    return <Component {...props} />;
}

// Export the AddVoteSuccess component as the default export
export default AddVoteSuccess;
