import { constants } from "../helpers";

/**
 * Capitalizes the first letter of each word in a string.
 *
 * @param {string} str - The input string.
 * @returns {string} - The input string with each word's first letter capitalized.
 */
export function capitalizeFirstLetter(str) {
  if (!str || typeof str !== "string") return str;

  let words = str.split(" ");
  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    if (word.length > 0) {
      words[i] = word[0].toUpperCase() + word.slice(1);
    }
  }
  return words.join(" ");
}

/**
 * Converts a string to title case.
 *
 * @param {Object} options - Options for title casing.
 * @param {string} options.value - The input string.
 * @param {string} [options.splitBy=" "] - The character to split the string by.
 * @param {boolean} [options.convertToLower=true] - Whether to convert the rest of the letters to lowercase.
 * @returns {string} - The input string in title case.
 */
export function titleCase({ value, splitBy = " ", convertToLower = true }) {
  if (!value) return "";
  const temp = value.split(splitBy);
  return temp
    .map(
      (val) =>
        val.slice(0, 1).toUpperCase() +
        (convertToLower ? val.slice(1).toLowerCase() : val.slice(1))
    )
    .join(" ");
}

export function checkValidDecimals({
  event,
  limit = constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION,
}) {
  // Get the input value and remove any leading zeros
  let inputValue = event.target.value.replace(/^0+/, "");

  // Allow for negative values (optional minus sign)
  inputValue = inputValue.replace(/[^-0-9.]/g, "");

  // Ensure the input value is a valid number with up to the specified decimal digits limit
  return new RegExp(`^-?\\d*\\.?\\d{0,${limit}}$`).test(inputValue);
}
