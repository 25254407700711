import { useCallback, useState } from 'react';
import { useHandleCalls, useReduxStore } from '../../../../custom-hooks';
import Component from './Component';
import { constants } from '../../../../../helpers';
import { getDataApi } from '../../../../../api/methods';


function ContributeAnotherCollective(props) {
    const { commonReducer, authReducer } = useReduxStore();
   
    const [collectiveList, setCollectiveList] = useState(constants.PAGINATION_DATA);
    const [selected, setSelected] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading,setLoading]=useState(true)
    const handleIsSubmitting = useCallback((value) => {
        setIsSubmitting(value);
    }, []);

    const handleFetchSimilarCollective = useCallback(
        (page) => {
          
            const query = {
                collectiveId: commonReducer?.modal?.collective?.collectiveId,
                claimAmount: commonReducer?.modal?.collective?.goal?.myClaim,
                pageNo: page || currentPage,
                limit: 10,
                loaderType: constants.LOADER.TYPE.TABLE,
                callback: (response) => {
                    setCollectiveList({
                        list: page === 1 ? response.data.data : [...collectiveList.list, ...response.data.data],
                        total: response.data.totalPage,
                        pageNo: response.data.pageNo,
                        totalCount: response.data.total
                    });
                }
            }
            getDataApi({ path: constants.API_PATHS.SIMILAR_COLLECTIVE, data: query });
            setLoading(false)
        }, [commonReducer,collectiveList.list,currentPage]);

    useHandleCalls(handleFetchSimilarCollective, [], 'similar_collective');


    const handleSelectCollective = (item) => {
        setSelected(item)
    }

    const handleLodeMore = () => {
        if (collectiveList.total === currentPage) {
            return;
        }
        let pageNoCount = currentPage + 1
        setCurrentPage(currentPage + 1);
        handleFetchSimilarCollective(pageNoCount)
    };

    return <Component
        {...props}
        details={commonReducer?.modal?.collective}
        collectiveList={collectiveList}
        selected={selected}
        onSelectCollective={handleSelectCollective}
        votingDelegationId={authReducer.userId}
        isSubmitting={isSubmitting}
        onIsSubmitting={handleIsSubmitting}
        onLodeMore={handleLodeMore}
        currentPage={currentPage}
        isLoading={loading}
    />;
}

export default ContributeAnotherCollective;
