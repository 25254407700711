import { useCallback, useMemo } from 'react';
import { constants } from '../../../../../helpers';
import { setReadyForBuyNft } from '../../../../../redux/features/commonSlice';
import { setDecimalValue } from '../../../../../utils';
import { useAppDispatch, useReduxStore } from '../../../../custom-hooks';
import Component from './Component';

function TotalContributionView(props) {
    const dispatch = useAppDispatch();
    const { commonReducer } = useReduxStore();
    const { selectedNft, collectiveDetails } = commonReducer.modal?.totalContributionDetails || {};

    const contributionDetails = useMemo(() => {
        const totalContribution = setDecimalValue({ value: collectiveDetails.goal?.collectors?.[0]?.totalContribution });
        const nftPrice = parseFloat(selectedNft.amount || collectiveDetails.currentPrice || 0);
        const plaformFee = setDecimalValue({ value: (nftPrice || totalContribution) * constants.COLLECTIVE.PLATFORM_FEE_PERCENTAGE / 100 });
        const totalAmount = setDecimalValue({ value: nftPrice + plaformFee });
        const availableAmount = setDecimalValue({ value: totalContribution - plaformFee });

        return { totalContribution, nftPrice, plaformFee, totalAmount, availableAmount };
    }, [collectiveDetails, selectedNft]);

    const handleSubmit = useCallback(
        () => {
            dispatch(setReadyForBuyNft({ isReady: true }));
            props.onClose();
        },
        [dispatch, props]
    );

    return <Component
        {...props}
        contributionDetails={contributionDetails}
        onSubmit={handleSubmit}
    />;
}

export default TotalContributionView;
