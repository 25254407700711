// import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

// import { app1 } from "../../../../../firebaseConfig";

import config from "../../../../../Config";
import { constants } from "../../../../../helpers";
import { setSocialLoginInfo } from "../../../../../redux/features/authSlice";

import {
  onToggleModal,
} from "../../../../../redux/features/commonSlice";
import { googleAuth } from "../../../../../redux/services/auth";

import { errorAlert, successAlert } from "../../../../../utils";

// const googleProvider = new GoogleAuthProvider();

// googleProvider.setCustomParameters({
//   prompt: "select_account",
// });

// const signInWithGoogle = async () => {
//   const googleAuthentication = getAuth(app1);
//   return signInWithPopup(googleAuthentication, googleProvider)
//     .then((res) => {
//       console.log(res.user);
//       return res.user;
//     })
//     .catch((error) => {
//       console.log(error);
//       // Utils.alert(
//       //     Constants.alertType.error,
//       //     firebaseErrorMessages[error.code] ||
//       //     error.message.replace('Firebase: ', '').replace(/\(auth.*\)\.?/, '')
//       // );
//       return error.message;
//     });
// };

export const handleGoogleLogin = (
  dispatch,
  onRefreshViewCollectivePage,
  deviceId,
  commonReducer,
  navigate,
  res
) => {
  // const res = await signInWithGoogle();
  
  dispatch(setSocialLoginInfo({ data: res }));
  if (res) {
    const request = {
      email: res.email || "",
      referralCode: config.REFERRAL_CODE,
      socialId: res.sub,
      deviceId: deviceId || "string",
      deviceToken: "string",
      notify: false,
      callback: (response) => {
        if (response?.statusCode === 200 || response?.statusCode === 201) {
          // dispatch(setReferralInfo(res));
          dispatch(onToggleModal());
          setTimeout(() => {
            if (window.location.href.includes(constants.BROWSER_ROUTES.VIEW_COLLECTIVE)) {
                onRefreshViewCollectivePage();
            }
        }, 1000);
          // navigate(constants.BROWSER_ROUTES.DISCOVER);
          if (response.message) {
            successAlert(response.message);
          }
        } else if (response?.data?.message) {
          // dispatch(setReferralInfo(res));
          // if (response?.data?.type === "EMAILID_NOT_REGISTERED") {
          //   dispatch(onToggleModal({ modalType: constants.MODAL.REFERRAL }));
          // } else {
          //   dispatch(onToggleModal());
          //   errorAlert(response?.data?.message);
          // }
          dispatch(onToggleModal());
          errorAlert(response?.data?.message);
        }
      },
    };
    // if (referralId) {
    //   request.referralCode = referralId;
    // }
    dispatch(googleAuth(request));
  }
};
