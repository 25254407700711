/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown } from 'react-bootstrap';
import { bellIcon } from '../../../../../assets/images/images';
import { dateFormatter } from '../../../../../utils';

function NotificationComponent({ notificationList, onReadAllMsg, onReadNotification, onScroll, listInnerRef, onFetchNotification }) {
    const today = new Date().getDate();
    const currentTime = new Date().getTime();

    const unReadMsg = notificationList?.list?.length > 0 && notificationList?.list?.filter((noti) => noti.isRead === false).length;

    // function convertUTCDateToLocalDate(timing) {
    //     const timeZone = new Date().getTimezoneOffset()

    //     let localTimeZone=0
    //     if(timeZone < 0){
    //         localTimeZone = timing - new Date().getTimezoneOffset()*60*1000
    //       return localTimeZone
    //     }else{
    //         localTimeZone = timing + new Date().getTimezoneOffset()*60*1000
    //        return localTimeZone
    //     }
    // }
    // const sortList = notificationList?.list?.length > 0 ? notificationList?.list?.map(noti => noti?.scheduleNotification ? {...noti,timing:convertUTCDateToLocalDate(noti.timing)}:{...noti}):[];
    // const notifyList = sortList?.length > 0 && sortList.sort((a,b)=>b.timing - a.timing)
    // console.log(sortList,"sortList")

    return <div className='notification'>
        <Dropdown className='profile-dropdown' onClick={() => { onFetchNotification(1, true); }}>
            <Dropdown.Toggle className='profile-btn' id="dropdown-basic">
                <i className='bell-icon'></i>
                <span className={notificationList?.unreadCount === 0 ? '' : 'dot'}>{`${notificationList?.unreadCount === 0 ? '' : notificationList?.unreadCount}`}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu align="end" className='notification-drop'
                onScroll={onScroll}
                ref={listInnerRef}
                style={{ height: "50vh", overflowY: "auto" }}>
                <div className='noti-head'>
                    <p>Notifications {`(${notificationList?.unreadCount})`}</p>
                    {unReadMsg > 0 &&
                        < p role='button' onClick={onReadAllMsg}>Mark all as read <i className='check-round-icon'></i></p>
                    }
                </div>
                {notificationList?.list?.length > 0 && notificationList?.list?.map((data) => {
                    const createdDate = new Date(data?.timing).getDate();
                    // let localTime = 0
                    // if(data?.scheduleNotification){
                    //     localTime = convertUTCDateToLocalDate(data?.timing)
                    // }else{
                    //     localTime= data?.timing;
                    // }

                    return (
                        <>
                            {(createdDate <= currentTime) &&
                                <Dropdown.Item className={!data?.isRead ? "unread" : ""} key={data?._id} onClick={() => onReadNotification(data?._id, data?.scheduleNotification)}>
                                    <img src={bellIcon} alt='bellIcon' />
                                    <div className='text'>
                                        <p>{data?.description}</p>
                                        <p className='sm'>{today === createdDate ? "Today" : data.timing && dateFormatter({ date: data.timing, format: 'd MMM, yyyy' })} at {data.timing && dateFormatter({ date: data.timing, format: 'hh:mm a' })}</p>
                                    </div>
                                </Dropdown.Item>
                            }
                        </>
                    );
                })}
                {notificationList?.list?.length === 0 &&
                    <Dropdown.Item >
                        <div className='text'>
                            <p>No Notification Found</p>
                        </div>
                    </Dropdown.Item>
                }
            </Dropdown.Menu>
        </Dropdown>
    </div >;
}

export default NotificationComponent;
