/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { setRegistrationDetails } from '../../../../../redux/features/authSlice';
import { onSetCanResendOtp, onToggleModal } from '../../../../../redux/features/commonSlice';
import { verifyOtp } from '../../../../../redux/services/auth';
import { useAppDispatch, useAppNavigate, useAppSelector } from '../../../../custom-hooks';
import SignUpVerifyEmailComponent from './Component';

function SignUpVerifyEmail(props) {
    const { address } = useAccount();
    const { disconnect } = useDisconnect();
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();
    const commonReducer = useAppSelector(state => state.common);

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');

    const handleOtpChange = useCallback((value) => {
        // Only allow numbers
        const regex = /^[0-9\b]+$/;
        if (regex.test(value) || value === '') {
            setOtp(value);
            if (otpError !== '') {
                setOtpError('');
            }
        }
    }, [otpError]);

    const handleVerifyOtp = useCallback(() => {
        if (!(commonReducer.modal?.signUp?.email)) return false;

        const data = {
            type: constants.API_TYPE.REGISTER,
            email: commonReducer.modal.signUp.email,
            otp,
            callback: (response) => {
                if (response.statusCode === 200) {
                    setOtpError("");
                    dispatch(setRegistrationDetails({
                        accessToken: response.data?.accessToken || "",
                        userId: response.data?.userId || "",
                        username: response.data?.userName || "",
                        email: response.data?.email || "",
                    }));
                    if (address) {
                        disconnect();
                    }
                    dispatch(onToggleModal({ modalType: constants.MODAL.SIGN_UP_OTP_VERIFICATION_SUCCESS }));
                    navigate(constants.BROWSER_ROUTES.DISCOVER);
                } else if (response.data.type === "INVALID_OTP") {
                    setOtpError(response.data.message);
                } else if (response.data.type === "OTP_EXPIRED") {
                    setOtpError(response.data.message);
                    dispatch(onSetCanResendOtp({ canResendOtp: true }));
                } else {
                    setOtpError(response.message);
                }
            }
        };
        dispatch(verifyOtp(data));
    }, [
        address,
        commonReducer.modal,
        disconnect,
        otp,
        dispatch,
        navigate
    ]);

    const handleResendOtp = useCallback(() => {
        if (!(commonReducer.modal?.signUp?.email) || !(commonReducer.modal?.canResendOtp)) return false;

        dispatch(onSetCanResendOtp({ canResendOtp: false }));

        const data = {
            email: commonReducer.modal.signUp.email,
            notify: true,
            callback: () => {
                setTimeout(() => {
                    dispatch(onSetCanResendOtp({ canResendOtp: true }));
                },
                    constants.RESEND_OTP_TIME_LIMIT.REGISTER
                );
            }
        };
        postDataApi({ path: constants.API_PATHS.USER_RESEND_OTP, data });
    }, [commonReducer.modal, dispatch]);

    return <SignUpVerifyEmailComponent
        {...props}
        otp={otp}
        canResendOtp={!!(commonReducer.modal?.canResendOtp)}
        email={commonReducer.modal?.signUp?.email || ''}
        otpError={otpError}
        actionType={commonReducer.actionType}
        onSetOtp={handleOtpChange}
        onVerifyOtp={handleVerifyOtp}
        onResendOtp={handleResendOtp}
    />;
}

export default SignUpVerifyEmail;
