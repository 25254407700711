import { useCallback } from 'react';
import { putDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { onToggleModal } from '../../../../../redux/features/commonSlice';
import { capitalizeFirstLetter, successAlert } from '../../../../../utils';
import { useAppDispatch } from '../../../../custom-hooks';
import getFormikValidationSchema from '../../../../formik-validations';
import Component from './Component';

const initialState = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    isShowingOldPassword: false,
    isShowingNewPassword: false,
    isShowingConfirmNewPassword: false
};

function UpdatePassword(props) {
    const dispatch = useAppDispatch();
    const schema = getFormikValidationSchema('updatePassword');

    const handleSubmit = useCallback((data, { setSubmitting, setErrors }) => {
        // disabled save changes before trigger api call
        setSubmitting(true);

        const dataToSave = {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
            confirmPassword: data.confirmNewPassword,
            callback: (response) => {
                if (response.statusCode === 200) {
                    dispatch(onToggleModal());
                    props.onForceReRender();

                    if (response.message) {
                        successAlert(response.message);
                    }
                } else if (response.data?.message && response.data?.type === "SIMILAR_PASSWORD") {
                    setErrors({ newPassword: capitalizeFirstLetter(response.data.message) });
                } else if (response.data?.message && response.data?.type === "INVALID_OLD_PASSWORD") {
                    setErrors({ oldPassword: capitalizeFirstLetter(response.data.message) });
                }

                // enabled save changes after api success
                setSubmitting(false);
            }
        };
        putDataApi({ path: constants.API_PATHS.CHANGE_PASSWORD, data: dataToSave });
    }, [dispatch, props]);

    return <Component {...props} schema={schema} initialState={initialState} onSubmit={handleSubmit} />;
}

export default UpdatePassword;
