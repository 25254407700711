import { useEffect, useMemo, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { postDataApi } from "../../../api/methods";
import { constants } from "../../../helpers";
import { setWalletDetails } from "../../../redux/features/authSlice";
import { setIsWalletConnect } from "../../../redux/features/commonSlice";
import { errorAlert, getWalletIcon, successAlert } from "../../../utils";
import { useAppDispatch, useAppNavigate, useAppSelector } from "../../custom-hooks";
import * as modules from './modules';

function ConnectWallet(props) {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();
  const { disconnect } = useDisconnect();
  const authReducer = useAppSelector((state) => state.auth);
  const { address, connector: activeConnector } = useAccount();
  const WalletIcon = getWalletIcon(authReducer.wallet?.id);

  const [isConnectingWallet, setIsConnectingWallet] = useState(false);

  const isInvalidAddress = useMemo(
    () => authReducer.wallet.address ? address !== authReducer.wallet.address : false,
    [address, authReducer.wallet.address]
  );

  useEffect(() => {
    if (address && !authReducer.wallet.address) {
      postDataApi({
        path: constants.API_PATHS.CONNECT_WALLET,
        data: {
          walletAddress: address,
          notify: true,
          callback: (response) => {
            if (response && response.statusCode === 201) {
              dispatch(
                setWalletDetails({
                  isConnect: true,
                  address,
                })
              );
              if (["header", "profile-wallet"].includes(props.type)) {
                navigate(constants.BROWSER_ROUTES.WALLETS);
              }
            } else if (response.data.type === "WALLET_ALREADY_EXIST") {
              disconnect();
            }
          },
        },
      });
    } else if (address && !isInvalidAddress && isConnectingWallet) {
      successAlert("Wallet Connected Successfully");
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, props.type]);

  useEffect(() => {
    if (activeConnector?.id) {
      dispatch(
        setWalletDetails({
          id: activeConnector.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConnector]);

  useEffect(() => {
    if (address) {
      dispatch(setIsWalletConnect({ isConnect: true }));
    } else {
      dispatch(setIsWalletConnect({ isConnect: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (address && isInvalidAddress) {
      disconnect();
      errorAlert("Please use wallet which is connected with your mymetafi account.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidAddress, address]);

  let Component;
  switch (props.type) {
    case "header":
      Component = modules.Header;
      break;
    case "profile-wallet":
      Component = modules.ProfileWallet;
      break;
    case "create-collective":
      Component = modules.CreateCollective;
      break;
    case "collective-contribute":
      Component = modules.CollectiveContribute;
      break;
    case "voting-delegation":
      Component = modules.VotingDelegation;
      break;
    case "buy-nft":
      Component = modules.BuyNft;
      break;
    case "check-wallet-connection":
      Component = modules.CheckWalletConnection;
      break;
    default:
  }

  return <Component walletIcon={WalletIcon} onSetConnectingWallet={setIsConnectingWallet} {...props} />;
}

export default ConnectWallet;
