import { useCallback, useState } from 'react';
import { errorAlert } from '../../../../../utils';
import { useAppDispatch, useReduxStore } from '../../../../custom-hooks';
import Component from './Component';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { onToggleModal } from '../../../../../redux/features/commonSlice';

/**
 * AddComment component for adding comments to a proposal.
 *
 * This component allows users to add comments to a proposal. It handles form submission
 * and dispatches actions to update the Redux store based on the API response.
 *
 * @component
 * @returns {JSX.Element} - Rendered AddComment component.
 */
function AddComment(props) {
    // Retrieve data from the Redux store and get the dispatch function
    const { commonReducer } = useReduxStore();
    const dispatch = useAppDispatch();

    // State to manage the comment input
    const [comment, setComment] = useState('');

    /**
    * Handles form submission when the user attempts to submit a comment.
    *
    * Checks the user's voting power and either displays an error message or prepares
    * a query for the API call to add the comment. Handles the API response accordingly.
    *
    * @function
    * @returns {void}
    */
    const handleSubmit = useCallback(
        () => {
            // Check if the user's voting power is zero
            if (commonReducer.modal.proposal?.nftDetails?.goal?.votingPower === 0) {
                errorAlert("You Can't Comment Because Your Voting Power Is Zero Percent");
            } else {
                // Prepare the query for the API call
                const query = {
                    proposalId: commonReducer.modal.proposal?.proposalId,
                    type: commonReducer.modal.proposal?.type,
                    callback: (response) => {
                        if (response?.statusCode === 201) {
                            dispatch(onToggleModal({ modalType: constants.MODAL.ADD_COMMENT_SUCCESS }));
                            setComment('');
                        } else {
                            errorAlert(response?.data?.message);
                        }
                    }
                };
                if (comment) {
                    query.comment = comment;
                }
                postDataApi({ path: constants.API_PATHS.ADD_COMMENTS, data: query });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [comment]);

    // Render the Component with the necessary props
    return <Component {...props} comment={comment} setComment={setComment} onSubmit={handleSubmit} />;
}

// Export the Component as the default export
export default AddComment;
