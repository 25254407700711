import { constants } from "../../../../helpers";
import {
    setDecimalValue,
} from "../../../../utils";

function ContributionGoalFooter({ values }) {

    if (!values.url || !values.contributionGoal) {
        return null;
    }

    return <p className="text-black mt6">
        After the platform fee you will have {setDecimalValue({
            value: (values.contributionGoal || 0) - (values.contributionGoal || 0) * constants.COLLECTIVE.PLATFORM_FEE_PERCENTAGE / 100,
            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
        })} ETH to spend on the NFT.
    </p>;
}

export default ContributionGoalFooter;
