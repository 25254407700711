"use client";
import { useAccount, useWalletClient } from "wagmi";
import { collectionNFTCrowdfundAbi } from "../scripts/index.js";
import { errorAlert } from "../../../utils/alert.js";
import messages from "../../../helpers/messages.js";
import { ConnectWallet } from "../../components/index.js";
import { useAddCollectiveContribution } from "../../custom-hooks/index.js";
import { useCallback } from "react";

const buttonConfig = {
  className: "btn",
  title: "Connect Wallet",
};

function ContributeSimilarCollective(props) {
  const { data: walletClient } = useWalletClient();
  const { address } = useAccount();

  const { addCollectiveContribution } = useAddCollectiveContribution({
    data: props?.data,
    resetForm: props?.resetForm,
    onIsSubmitting: props?.onIsSubmitting,
    claimCollectiveId: props?.selectedCollectiveDetails?._id,
  });

  const callContribute = useCallback(async () => {
    try {
      const txHash = await walletClient.writeContract({
        address: props.collectiveCrowdfundAddress,
        abi: collectionNFTCrowdfundAbi,
        functionName: "transferUnusedContribution",
        args: [props.selectedCollectiveDetails?.contractAddress, address],

        // chainId: goerli.id,
        // gas: 6000000,
        onSettled(data, error) {
          console.log("Settled", { data, error });
        },
      });

      return txHash;
    } catch (error) {
      console.log("callExecute-error", error);
      props.onIsSubmitting(false);
      errorAlert(messages.COLLECTIVE.WALLET.TRANSACTION_DENIED);
    }
  }, [walletClient, address, props]);

  if (!address) {
    return (
      <ConnectWallet
        type="buy-nft"
        disabled={props.isDisabledSubmit}
        buttonConfig={buttonConfig}
      />
    );
  }

  return (
    <button
      className={
        props.isSubmitting ? "btn lg w-100 mb18 spinner" : "btn lg w-100 mb18"
      }
      disabled={props.isDisabledSubmit}
      onClick={() => {
        props.onIsSubmitting(true);
        callContribute().then((result) => {
          addCollectiveContribution(result);
        });
      }}
    >
      {/* <i className="wallet-white-icon" /> */}
      Contribute
    </button>
  );
}

export default ContributeSimilarCollective;
