import { useMemo } from "react";
import { constants } from "../../helpers";
import { calculateCompletionPercent, isPastTime } from "../../utils";
import useReduxStore from "./useReduxStore";

/**
 * Custom hook to handle various checks related to a collective.
 * @param {Object} params - Input parameters for the hook.
 * @param {Object} params.details - Details of the collective.
 * @param {Array} params.collectors - List of collectors associated with the collective.
 * @returns {Object} - An object with various boolean values for different checks.
 */
function useCollectiveChecks({ details, collectors = [] }) {
  const { authReducer } = useReduxStore();

  // Check if the collective type is a collection.
  const isCollectionType = useMemo(() => {
    return details?.collectiveType === constants.COLLECTIVE.TYPE.COLLECTION;
  }, [details]);

  // Check if the collective status is closed.
  const isClosed = useMemo(() => {
    return details.status === constants.COLLECTIVE.STATUS.CLOSED;
  }, [details.status]);

  const isExpired = useMemo(() => {
    return isPastTime({ timestamp: details.collectiveCloseTiming });
  }, [details.collectiveCloseTiming]);

  // Check if the NFT has been purchased (status is acquired).
  const isNftPurchased = useMemo(() => {
    return details.status === constants.COLLECTIVE.STATUS.ACQUIRED;
  }, [details.status]);

  // Check if the collective goal has been completed.
  const isGoalCompleted = useMemo(() => {
    return !!details?.isGoalReached;
  }, [details]);

  // Check if the current user is the leader of the collective.
  const isLeader = useMemo(
    () => details.userId === authReducer.userId,
    [details.userId, authReducer.userId]
  );

  // Check if the NFT has been purchased (status is acquired).
  const isNftSold = useMemo(() => {
    return details.status === constants.COLLECTIVE.STATUS.SOLD;
  }, [details.status]);

  // here we are handing on frontend using collectors
  // const isSpecificNftCoLeader = useMemo(() => {
  //   return isCollectionType
  //     ? false
  //     : !!collectors?.find((col) => {
  //         const username = col.userData?.userName || col?.userName || "";

  //         return username === authReducer.username;
  //       });
  // }, [authReducer, collectors, isCollectionType]);

  // Check if the current user is a co-leader for a specific NFT (backend-based).
  const isSpecificNftCoLeader = useMemo(() => {
    return isCollectionType ? false : details.isContributed;
  }, [details, isCollectionType]);

  const completionPercentage = useMemo(() => {
    return details?.goal?.goal?.amount &&
      details?.goal?.collectors[0]?.totalContribution
      ? calculateCompletionPercent({
          totalContribution: details.goal.collectors[0].totalContribution,
          amount: details.goal.goal.amount,
        })
      : 0;
  }, [details]);

  const isInOverfundStage = useMemo(() => {
    return completionPercentage >= 100;
  }, [completionPercentage]);

  // const isTBD = useMemo(() => {
  //   return (
  //     details.isGoalReached &&
  //     !isNftPurchased &&
  //     parseFloat(completionPercentage) > 100
  //   );
  // }, [details, isNftPurchased, completionPercentage]);

  const isTBD = useMemo(() => {
    return (
      !isNftPurchased && !isNftSold && !details.goal?.securedMyContribution
    );
  }, [details, isNftPurchased, isNftSold]);

  const isTxHashFailed = useMemo(() => {
    return details.txHashStatus === constants.COLLECTIVE.TX_Hash_STATUS.FAILED;
  }, [details]);

  return {
    isCollectionType,
    isClosed,
    isNftPurchased,
    isGoalCompleted,
    isLeader,
    isSpecificNftCoLeader,
    isNftSold,
    isExpired,
    isTBD,
    completionPercentage,
    isInOverfundStage,
    isTxHashFailed,
  };
}

export default useCollectiveChecks;
