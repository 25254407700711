import * as Yup from "yup";
import { constants, messages, regex } from "../../helpers";
import { store } from "../../redux/store";
import {
  calculateMinContributionGoal,
  setDecimalValue,
  titleCase,
  truncateToDecimalDigits,
} from "../../utils";
import { checkOpenSeaUrl, checkSpecificNftUrl } from "../../utils/regex";

const discordShowArray = [
  constants.COLLECTIVE.UTILITY_SELECTION.EXCLUSIVE_COMMUNITY_ACCESS,
  constants.COLLECTIVE.UTILITY_SELECTION.MEMBERSHIP,
];

export const signUp = Yup.object({
  username: Yup.string()
    .required(messages.IS_REQUIRED("Username"))
    .matches(regex.USERNAME, messages.USERNAME),
  email: Yup.string()
    .email("Invalid Email Address")
    .required(messages.IS_REQUIRED("Email"))
    .matches(regex.EMAIL, "Invalid Email Address"),
  password: Yup.string()
    .required(messages.IS_REQUIRED("Password"))
    .matches(regex.PASSWORD, "Invalid Password"),
  countryCode: Yup.string().optional(messages.IS_REQUIRED("Country Code")),
  phoneNumber: Yup.string()
    .matches(regex.PHONE_NUMBER, messages.PHONE_NUMBER)
    .optional(messages.IS_REQUIRED("Phone Number")),
  enableNotifications: Yup.boolean().optional(),
  acceptTerms: Yup.boolean().oneOf([true]),
});

export const login = Yup.object({
  email: Yup.string().required(
    messages.IS_REQUIRED("Username Or Email Address")
  ),
  password: Yup.string()
    .required(messages.IS_REQUIRED("Password"))
    .matches(regex.PASSWORD, "Invalid Password"),
});

export const forgotPassword = Yup.object({
  email: Yup.string()
    .email("Invalid Email Address")
    .required(messages.IS_REQUIRED("Email"))
    .matches(regex.EMAIL, "Invalid Email Address"),
});

export const resetPassword = Yup.object({
  newPassword: Yup.string()
    .required(messages.IS_REQUIRED("New Password"))
    .matches(regex.PASSWORD, "Invalid New Password"),
  confirmPassword: Yup.string()
    .required(messages.IS_REQUIRED("Confirm Password"))
    .oneOf([Yup.ref("newPassword"), null], messages.CONFIRM_PASSWORD),
});

export const profileSettings = Yup.object({
  username: Yup.string()
    .required(messages.IS_REQUIRED("Username"))
    .matches(regex.USERNAME, messages.USERNAME),
  phoneNumber: Yup.string()
    .matches(regex.PHONE_NUMBER, messages.PHONE_NUMBER)
    .optional(messages.IS_REQUIRED("Phone Number")),
});

export const updateEmail = Yup.object({
  email: Yup.string()
    .email("Invalid Email Address")
    .required(messages.IS_REQUIRED("Email Address"))
    .matches(regex.EMAIL, "Invalid Email Address"),
});

export const updatePassword = Yup.object({
  oldPassword: Yup.string()
    .required(messages.IS_REQUIRED("Old Password"))
    .matches(regex.PASSWORD, "Invalid Old Password"),
  newPassword: Yup.string()
    .required(messages.IS_REQUIRED("New Password"))
    .matches(regex.PASSWORD, "Invalid New Password"),
  confirmNewPassword: Yup.string()
    .required(messages.IS_REQUIRED("Confirm New Password"))
    .oneOf([Yup.ref("newPassword"), null], messages.CONFIRM_PASSWORD),
});

export const profile = Yup.object({
  bio: Yup.string()
    .optional(messages.IS_REQUIRED("Bio"))
    .max(256, "Bio should be maximum of 256 characters"),
  isEditable: Yup.boolean().optional(),
});

export const addCollective = Yup.object({
  collectiveType: Yup.string().required(
    messages.IS_REQUIRED("Collective Type")
  ),
  title: Yup.string().required(messages.IS_REQUIRED("Title")),
  subTitle: Yup.string().required(messages.IS_REQUIRED("Subtitle")),
  url: Yup.string()
    .required(messages.IS_REQUIRED("Url"))
    .test("custom-validation", function (value) {
      const { collectiveType } = this.parent; // Access other field value
      if (
        (constants.COLLECTIVE.TYPE.COLLECTION === collectiveType &&
          !checkOpenSeaUrl(value)) ||
        (constants.COLLECTIVE.TYPE.SPECIFIC_NFT === collectiveType &&
          !checkSpecificNftUrl(value))
      ) {
        return this.createError({
          message: "Invalid Opensea Url",
          path: "url",
        });
      }

      return true;
    }),
  contributionGoal: Yup.number()
    .required(messages.IS_REQUIRED("Contribution Goal"))
    .positive("Contribution Goal Must Be Greater Than 0")
    .test("contributionGoal", function (value) {
      const { details, collectiveType } = this.parent;

      const { totalPrice } = calculateMinContributionGoal({
        collectiveType,
        details,
      });

      // Generate dynamic error message based on field values
      if (value < totalPrice) {
        return this.createError({
          message: `Value Should Be Atleast ${totalPrice} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}.`,
          path: "contributionGoal",
        });
      }

      return true;
    }),
  contributionAmount: Yup.number()
    .required(messages.IS_REQUIRED("Contribution Amount"))
    .positive("Contribution Amount Must Be Greater Than 0")
    .test("contributionGoal", function (value) {
      const { contributionGoal } = this.parent;
      const minValue = constants.COLLECTIVE.MIN_CONTRIBUTION_AMOUNT({
        val: contributionGoal,
        goalDetails: store?.getState()?.auth?.goalDetails,
      });

      let maxValue = constants.COLLECTIVE.MAX_CONTRIBUTION_AMOUNT({
        val: contributionGoal,
        goalDetails: store?.getState()?.auth?.goalDetails,
      });

      if (minValue > maxValue) {
        maxValue = minValue;
      }

      if (!(value >= minValue && value <= maxValue)) {
        if (value >= minValue && minValue === maxValue) {
          return this.createError({
            message: `Value Should Be ${minValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`,
            path: "contributionAmount",
          });
        } else if (value <= minValue && minValue === maxValue) {
          return this.createError({
            message: `Value Should Be Atleast ${minValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`,
            path: "contributionAmount",
          });
        } else {
          return this.createError({
            message: `Value Should Be Between ${minValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH} to ${maxValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`,
            path: "contributionAmount",
          });
        }
      }

      return true;
    }),
  contributionPeriod: Yup.string().required(
    messages.IS_REQUIRED("Contribution Period")
  ),
  websiteUrl: Yup.string().url("Invalid Website Url").optional(),
  proposal: Yup.string().required(messages.IS_REQUIRED("Proposal")),
  acceptanceThreshold: Yup.number().required(
    messages.IS_REQUIRED("Acceptance Threshold")
  ),
  vetoPeriod: Yup.string().required(messages.IS_REQUIRED("Veto Period")),
  proposalExpiration: Yup.string().required(
    messages.IS_REQUIRED("Proposal Expiration")
  ),
  // utilitySelection: Yup.string().optional(),
  anticipatedApy: Yup.number().optional().min(1, "Invalid Anticipated APY"),
  utilityAvailable: Yup.array().of(
    Yup.string().required(messages.IS_REQUIRED("Utility Available"))
  ),
  discordId: Yup.string().when("utilityAvailable", {
    is: (values) => discordShowArray.some((util) => values.includes(util)),
    then: (schema) =>
      schema.required(messages.IS_REQUIRED("Game Discord Server Link")),
    otherwise: (schema) => schema,
  }),
  platform: Yup.string().optional(),
  sellingDate: Yup.string().optional(),
  sellingPrice: Yup.number().positive("Selling Price Must Be Greater than 0"),
  // .min(Yup.ref("contributionGoal"), messages.COLLECTIVE.SELLING_PRICE_MIN),
  enableNotifications: Yup.boolean().optional(),
});

export const referral = Yup.object({
  referralCode: Yup.string()
    .required(messages.IS_REQUIRED("Referral Code"))
    .matches(regex.REFERRAL_CODE, "Invalid Referral Code"),
  email: Yup.string().test("enableJoinWaitlist", function (value) {
    const { enableJoinWaitlist } = this.parent;

    if (enableJoinWaitlist) {
      if (!value) {
        return this.createError({
          message: messages.IS_REQUIRED("Email Address"),
          path: "email",
        });
      } else if (regex.EMAIL.test(value)) {
        return true;
      } else {
        return this.createError({
          message: "Invalid Email Address",
          path: "email",
        });
      }
    }

    return true;
  }),
  enableJoinWaitlist: Yup.boolean().required(),
});

export const collectivePreview = Yup.object({
  // added contribution goal to calculate contribute
  contributionGoal: Yup.number().optional(
    messages.IS_REQUIRED("Contribution Goal")
  ),
  contribute: Yup.number()
    .required(messages.IS_REQUIRED("Contribution"))
    .positive(messages.COLLECTIVE.CONTRIBUTION_ZERO_ERROR)
    .test("contributionGoal", function (value) {
      const { contributionGoal } = this.parent;
      const minContributionAmount =
        store?.getState()?.common?.modal?.collective?.contributionAmount;
      const goalDetails = store?.getState()?.auth?.goalDetails;

      const minValue = minContributionAmount
        ? setDecimalValue({
            value: minContributionAmount,
            goalDetails,
          })
        : constants.COLLECTIVE.MIN_CONTRIBUTION_AMOUNT({
            val: contributionGoal,
            goalDetails,
          });

      const maxValue = constants.COLLECTIVE.MAX_CONTRIBUTION_AMOUNT({
        val: contributionGoal,
        goalDetails,
      });

      // Generate dynamic error message based on field values
      if (!(value >= minValue && value <= maxValue)) {
        if (minValue === maxValue) {
          return this.createError({
            message: `Value Should Be ${minValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`,
            path: "contribute",
          });
        }
        return this.createError({
          message: `Value Should Be Between ${minValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH} to ${maxValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`,
          path: "contribute",
        });
      }

      return true;
    }),
});

export const userContributeCollective = Yup.object({
  totalContribution: Yup.number().optional(),
  contributionAmount: Yup.number().optional(),
  // added contribution goal to calculate contribute
  contributionGoal: Yup.number().optional(),
  contribute: Yup.number()
    .required(messages.IS_REQUIRED("Contribution"))
    .positive(messages.COLLECTIVE.CONTRIBUTION_ZERO_ERROR)
    .test("contributionGoal", function (value) {
      const {
        contributionGoal,
        contributionAmount,
        totalContribution,
        contribute,
      } = this.parent;

      const minValue = setDecimalValue({
        value: contributionAmount,
        formatted: false,
      });
      let maxValue = truncateToDecimalDigits({
        number:
          constants.COLLECTIVE.MAX_CONTRIBUTION_AMOUNT({
            val: contributionGoal,
            goalDetails: store?.getState()?.auth?.goalDetails,
          }) - totalContribution,
        limit: constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION,
      });

      if (maxValue < 0) {
        maxValue = maxValue * -1;
      }

      if (minValue > maxValue) {
        maxValue = minValue;
      }

      const percentage =
        (((totalContribution || 0) + (contribute || 0)) / contributionGoal) *
        100;

      if (percentage > 20) {
        return this.createError({
          message: titleCase({
            value: messages.COLLECTIVE.MAX_CONTRIBUTE_PERCENT({
              contributionGoal,
            }),
          }).replace("Eth", "ETH"),
          path: "contribute",
        });
      }

      // Generate dynamic error message based on field values
      if (!(value >= minValue && value <= maxValue)) {
        if (minValue === maxValue) {
          return this.createError({
            message: `Value Should Be ${minValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`,
            path: "contribute",
          });
        }
        return this.createError({
          message: `Value Should Be Between ${minValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH} to ${maxValue} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`,
          path: "contribute",
        });
      }

      return true;
    }),
  selectedUser: Yup.mixed().nullable().optional(),
});

export const addProposal = Yup.object({
  proposalType: Yup.string().optional(),
  sellingPrice: Yup.number().when("proposalType", {
    is: (value) => value === "selling",
    then: (schema) =>
      schema
        .required(messages.IS_REQUIRED("Sell Price"))
        .positive("Sell Price Must Be Greater Than 0"),
    otherwise: (schema) => schema,
  }),
  // sellingDate: Yup.string().when("proposalType", {
  //   is: (value) => value === "selling",
  //   then: (schema) =>
  //     schema.required(messages.IS_REQUIRED("List For Sale On Date")),
  //   otherwise: (schema) => schema,
  // }),
  // sellingPrice: Yup.string().required(
  //   messages.IS_REQUIRED("Sell Price")
  // ),
  // sellingDate: Yup.string().required(
  //   messages.IS_REQUIRED("List For Sale On Date")
  // ),
});

export const confirmProposal = Yup.object({
  notes: Yup.string().max(
    256,
    "Proposal Description Accept Only 256 Character"
  ),
});
