import React, { useState } from "react";
import { getBasicAuthDataApi } from "../../../../../../api/methods";
import { useAppNavigate, useHandleCalls } from "../../../../../../common/custom-hooks";
import { constants } from "../../../../../../helpers";
import CollectiveCard from "../../collective-card";

const NearlyComponent = ({ activeTab }) => {
  const navigate = useAppNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [page, setPage] = useState(1);
  const [result, setResult] = useState([]);
  const [details, setDetails] = useState([]);

  const loadMore = () => {
    setPage(page + 1);
  };

  const loadLess = () => {
    setDetails([]);
    setPage(1);
  };

  const noDataFound = () => {
    return (
      <div className="text-center mx-auto">
        <h6>No Nearly Full Collectives Found </h6>
        <p>List of all nearly full collectives will be shown here </p>
      </div>
    );
  };

  const fetchCollectiveData = () => {
      setIsFetching(true);
      const query = {
        pageNo: page,
        limit: 9,
        sortBy: activeTab,
        loaderType: constants.LOADER.TYPE.TABLE,
        callback: (response) => {
          if (response?.statusCode === 200) {
            if (response.data) {
              setIsFetching(false);
              setResult(response.data?.data);
              setDetails([...details, ...response.data.data]);
            }
          }
        },
      };

      getBasicAuthDataApi({
        path: constants.API_PATHS.HOME_COLLECTIVE_LIST,
        data: query,
      });
  };

  useHandleCalls(fetchCollectiveData, [page, activeTab], "fetchCollectiveData");

  return <CollectiveCard details={details} navigate={navigate} noDataFound={noDataFound } isFetching={isFetching} loadLess={loadLess} loadMore={loadMore} result={result} />;
};

export default NearlyComponent;
