import { useCallback } from 'react';
import { constants } from '../../../../../helpers';
import { onToggleModal } from '../../../../../redux/features/commonSlice';
import { resetPassword } from '../../../../../redux/services/auth';
import { capitalizeFirstLetter } from '../../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../custom-hooks';
import getFormikValidationSchema from '../../../../formik-validations';
import SetNewPasswordComponent from './Component';

const initialState = {
    newPassword: '',
    confirmPassword: '',
    isShowingNewPassword: false,
    isShowingConfirmPassword: false
};

function SetNewPassword(props) {
    const dispatch = useAppDispatch();
    const schema = getFormikValidationSchema('resetPassword');
    const commonReducer = useAppSelector(state => state.common);

    const handleSubmit = useCallback((data, { setSubmitting, setErrors }) => {
        if (!(commonReducer.modal?.forgotPassword?.userId)) return false;

        // disabled save changes before trigger api call
        setSubmitting(true);

        const dataToSave = {
            userId: commonReducer.modal.forgotPassword.userId,
            "newPassword": data.newPassword,
            "confirmPassword": data.confirmPassword,
            notify: false,
            callback: (response) => {
                if (response.statusCode === 200) {
                    dispatch(onToggleModal({ modalType: constants.MODAL.RESET_PASSWORD_SUCCESS }));
                } else if (response.data?.message && response.data?.type === "SIMILAR_PASSWORD") {
                    setErrors({ newPassword: capitalizeFirstLetter(response.data.message) });
                }

                // enabled save changes after api success
                setSubmitting(false);
            }
        };
        dispatch(resetPassword(dataToSave));
    }, [dispatch, commonReducer.modal]);

    return <SetNewPasswordComponent {...props} schema={schema} initialState={initialState} onSubmit={handleSubmit} />;
}

export default SetNewPassword;
