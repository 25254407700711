import { DateFormat } from "../common/third-party-instances";

/**
 * Formats a date using the specified format.
 * 
 * @param {Object} options - The options object.
 * @param {Date|string} options.date - The date to be formatted.
 * @param {string} options.format - The desired format for the date (optional).
 * @returns {string|undefined} - The formatted date or undefined if the date is not provided.
 */
export function dateFormatter({ date, format = "d MMM, YYYY" }) {
  if (!date) return;

  return DateFormat(date, format);
}

/**
 * Calculates the time difference between a target date and the current date.
 * 
 * @param {Date|number|string} targetDate - The target date for the calculation.
 * @returns {Object} - An object containing the time difference in days, hours, minutes, and seconds.
 */
export function calculateTimeDifference(targetDate) {
  if (!targetDate || isNaN(targetDate)) {
    // Return default values if targetDate is invalid or undefined
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const currentDate = new Date();
  const timeDifference = Math.abs(targetDate - currentDate) / 1000; // Get time difference in seconds

  const days = Math.floor(timeDifference / (24 * 60 * 60));
  const hours = Math.floor((timeDifference % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((timeDifference % (60 * 60)) / 60);
  const seconds = Math.floor(timeDifference % 60);

  const result = {
    days,
    hours,
    minutes,
    seconds,
  };

  return result;
}

/**
 * Formats a value and unit based on the quantity of the value.
 * 
 * @param {number} value - The value to be formatted.
 * @param {string} unit - The unit to be formatted.
 * @returns {string} - The formatted value and unit string.
 */
function formatValueUnit(value, unit) {
  if (value === 1) {
    return `${value} ${unit}`;
  }
  return `${value} ${unit}s`;
}

/**
 * Formats a time difference in days, hours, minutes, and seconds as a human-readable string.
 * 
 * @param {Object} timeData - An object containing time difference components (days, hours, minutes, seconds).
 * @returns {string} - The formatted time difference string.
 */
export function getFormattedTimeDifference(timeData) {
  if (timeData.days > 0) {
    return `${formatValueUnit(timeData.days, "day")} ago`;
  } else if (timeData.hours > 0) {
    return `${formatValueUnit(timeData.hours, "hour")} ago`;
  } else if (timeData.minutes > 0) {
    return `${formatValueUnit(timeData.minutes, "minute")} ago`;
  } else if (timeData.seconds > 0) {
    return `${formatValueUnit(timeData.seconds, "second")} ago`;
  } else {
    return "just now";
  }

  // const { days, hours, minutes, seconds } = time;
  // let formattedTimeDifference = "";

  // if (days > 0) {
  //   formattedTimeDifference += formatTimeValue(days, "day") + ", ";
  // }
  // if (hours > 0 || formattedTimeDifference !== "") {
  //   formattedTimeDifference += formatTimeValue(hours, "hr") + ", ";
  // }
  // if (minutes > 0 || formattedTimeDifference !== "") {
  //   formattedTimeDifference += formatTimeValue(minutes, "min") + ", ";
  // }
  // if (seconds > 0 || formattedTimeDifference !== "") {
  //   formattedTimeDifference += formatTimeValue(seconds, "sec") + ", ";
  // }

  // // Remove the trailing comma and space
  // formattedTimeDifference = formattedTimeDifference.slice(0, -2);

  // if (formattedTimeDifference === "") {
  //   formattedTimeDifference = "just now";
  // }

  // return formattedTimeDifference;
}
