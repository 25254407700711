import { Pagination } from "react-bootstrap";
import { useAppNavigate } from "../../custom-hooks";

function BreadCrumbs({ crumbs,setDefaultPage }) {
    const navigate = useAppNavigate();

    return <Pagination>
        {crumbs.map((crumb, index) => {
            return <Pagination.Item
                key={`${crumb.name}-${index}`}
                // style={{ cursor: crumb.to ? 'pointer' : '' }}
                onClick={() => {
                    if (crumb.to ==="CreateProposal") {
                        setDefaultPage(0)
                    }else{
                        navigate(crumb.to);
                    }
                }}
            >
                {crumb.name}
            </Pagination.Item>;
        })}
    </Pagination>;
}

export default BreadCrumbs;
