import { Field } from 'formik';
import { constants } from '../../../helpers';
import ValidationMessage from '../validation-message';
import { Asterisk } from './modules';

function Label({ label, required, labelConfig }) {
    return <>
        {label}
        {required && <Asterisk />}

        {labelConfig.enableUpdateButton &&
            <span className='blank-btn text-orange fw400 ml-auto fs14' onClick={labelConfig.buttonOnClick}>
                <u>{labelConfig.buttonName}</u>
            </span>
        }
    </>;
}

function InputField({ children, errors, touched, name, placeholder, required, type, label, labelClass, subLabel, subLabelClass, inputSubLabelClass, inputSubLabel, wrapperClass, inputWrapperClass, labelConfig, footer: Footer, tooltipConfig, platformFee, ...restProps }) {
    return (
        <div className={wrapperClass}>
            {label &&
                labelConfig.inPTag
                ? <p className={labelClass} htmlFor={name}>
                    <Label label={label} required={required} labelConfig={labelConfig} />
                    {tooltipConfig?.enableIcon &&
                        <div className='tooltip-div ml10'>
                            <i className='info-icon trig'></i>
                            <span className='text '>{tooltipConfig.content || ''}</span>
                        </div>
                    }

                    {platformFee &&
                        <p className='ml-auto text-purple fw600'>Platform Fee - {constants.COLLECTIVE.PLATFORM_FEE_PERCENTAGE}%</p>
                    }
                </p>
                : <label className={labelClass} htmlFor={name}>
                    <Label label={label} required={required} labelConfig={labelConfig} />
                </label>
            }
            {subLabel &&
                <label className={subLabelClass}>{subLabel}</label>
            }
            <div className={inputWrapperClass}>
                {inputSubLabel &&
                    <label className={inputSubLabelClass}>{inputSubLabel}</label>
                }
                <Field type={type} name={name} placeholder={placeholder} {...restProps} />
                {children || null}
            </div>
            {!!(errors?.[name] && touched?.[name]) && <ValidationMessage message={errors[name]} />}

            {Footer && <Footer />}
        </div>
    );
}

InputField.defaultProps = {
    disabled: false,
    label: '',
    title:'',
    labelClass: '',
    subLabelClass: 'text-text',
    placeholder: '',
    required: false,
    type: 'text',
    wrapperClass: 'form-in',
    inputWrapperClass: 'f-in',
    labelConfig: { enableUpdateButton: false, buttonName: '', inPTag: false, buttonOnClick: () => { } },
    tooltipConfig: { enableIcon: false, content: '' }
};

export default InputField;
