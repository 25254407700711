import { useCallback, useEffect, useMemo, useState } from 'react';
import { constants } from '../../../../../../../helpers';
import { setDecimalValue } from '../../../../../../../utils';
import { useFindContibutedPercentage, useReduxStore } from '../../../../../../custom-hooks';
import getFormikValidationSchema from '../../../../../../formik-validations';
import Component from './Component';

// Initial state for contribution details
const initialState = Object.freeze({
    contributionAmount: '',
    contributionGoal: '',
    contribute: '',
    selectedUser: null,
    totalContribution: 0,
});

/**
 * AddContribution component for handling contribution details.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @returns {JSX.Element} - Rendered AddContribution component.
 */
function AddContribution(props) {
    // Get the validation schema for formik
    const schema = getFormikValidationSchema('userContributeCollective');
    const { commonReducer, authReducer } = useReduxStore();
    const { myCollector } = useFindContibutedPercentage({
        collectors: commonReducer.modal.collective?.collectors,
        collective: commonReducer.modal.collective,
        collector_filter: constants.COLLECTIVE.COLLECTOR_FILTER.CONTRIBUTION,
        isFetchedCollectors: true,
    });
    const [detail, setDetails] = useState(initialState);
    const [isSubmitting, setIsSubmitting] = useState(false);

    /**
     * Function to handle submission state.
     *
     * @param {boolean} value - Submission state.
     */
    const handleIsSubmitting = useCallback((value) => {
        setIsSubmitting(value);
    }, []);

    // Prepare the leader option
    const leaderOption = useMemo(() => {
        return {
            userData: {
                userName: authReducer?.username || '',
                profilePicture: authReducer?.profilePicture || '',
                walletAddress: authReducer?.wallet?.address || '',
            },
            votingDelegationId: authReducer.userId || '',
        };
    }, [authReducer]);

    // Update contribution details based on collective information
    useEffect(() => {
        if (commonReducer.modal?.collective?.contributionGoal) {
            const contribute = setDecimalValue({ value: commonReducer.modal.collective.minimumDeposit });

            setDetails({
                ...detail,
                contribute: '',
                contributionGoal: setDecimalValue({ value: commonReducer.modal.collective.contributionGoal }),
                contributionAmount: contribute,
                totalContribution: setDecimalValue({ value: myCollector?.contribution || 0 }),
                selectedUser: leaderOption,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonReducer]);

    // Render the Component with the necessary props and state
    return <Component
        {...props}
        schema={schema}
        detail={detail}
        authReducer={authReducer}
        commonReducer={commonReducer}
        isSubmitting={isSubmitting}
        onIsSubmitting={handleIsSubmitting}
    />;
}

// Export the AddContribution component as the default export
export default AddContribution;
