function CustomTooltip({ content, isIcon }) {
    if (!content) return null;

    return <div className="tooltip-div ml6 va0">
        {isIcon &&
            <i className="info-line-icon trig"></i>
        }
        <div className="text">
            {content}
        </div>
    </div>;
}

export default CustomTooltip;
