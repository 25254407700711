import { createAsyncThunk } from "@reduxjs/toolkit";

import { postDataApi, putDataApi } from "../../api/methods";
// import { constants } from "../../helpers";

const constants = {
  API_PATHS: {
    USER_REGISTRATION: "user/signup",
    VERIFY_OTP: "user/verify-otp",
    LOGIN: "user/login",
    FORGOT_PASSWORD: "user/forgot-password",
    RESET_PASSWORD: "user/reset-password",
    FIND_EMAIL: "user/find-email",
    UPDATE_USER_SETTINGS: "user/profile",
    USER_SOCIAL_DISCONNECT: "user/social-disconnect",
    GOOGLE_AUTH: "user/google-auth",
  },
};

export const userRegistration = createAsyncThunk(
  constants.API_PATHS.USER_REGISTRATION,
  (payload) =>
    postDataApi({ path: constants.API_PATHS.USER_REGISTRATION, data: payload })
);

export const verifyOtp = createAsyncThunk(
  constants.API_PATHS.VERIFY_OTP,
  (payload) =>
    postDataApi({
      path: constants.API_PATHS.VERIFY_OTP,
      data: payload,
    })
);

export const userLogin = createAsyncThunk(
  constants.API_PATHS.LOGIN,
  (payload) => postDataApi({ path: constants.API_PATHS.LOGIN, data: payload })
);

export const disconnectSocialLogin = createAsyncThunk(
  constants.API_PATHS.USER_SOCIAL_DISCONNECT,
  (payload) =>
    postDataApi({
      path: constants.API_PATHS.USER_SOCIAL_DISCONNECT,
      data: payload,
    })
);

export const socialLogin = createAsyncThunk(
  constants.API_PATHS.USER_SOCIAL_AUTH_LOGIN,
  (payload) =>
    postDataApi({
      path: constants.API_PATHS.USER_SOCIAL_AUTH_LOGIN,
      data: payload,
    })
);


export const googleAuth = createAsyncThunk(
  constants.API_PATHS.GOOGLE_AUTH,
  (payload) =>
    postDataApi({
      path: constants.API_PATHS.GOOGLE_AUTH,
      data: payload,
    })
);

export const forgotPassword = createAsyncThunk(
  constants.API_PATHS.FORGOT_PASSWORD,
  (payload) =>
    postDataApi({ path: constants.API_PATHS.FORGOT_PASSWORD, data: payload })
);

export const resetPassword = createAsyncThunk(
  constants.API_PATHS.RESET_PASSWORD,
  (payload) =>
    postDataApi({ path: constants.API_PATHS.RESET_PASSWORD, data: payload })
);

export const updateUserSettings = createAsyncThunk(
  constants.API_PATHS.UPDATE_USER_SETTINGS,
  (payload) =>
    putDataApi({
      path: constants.API_PATHS.UPDATE_USER_SETTINGS,
      data: payload,
    })
);
