import { Modal } from 'react-bootstrap';
import { constants } from '../../../../../helpers';

function Component({ isOpen, isFetching, data, onClose, onOpenModal }) {
    return <Modal className='voter-info-modal' show={isOpen} onHide={onClose}>
        <Modal.Header className='pb10 justify-content-start align-items-center' closeButton>
            <button
                className='back-btn'
                onClick={() => {
                    onOpenModal(constants.MODAL.SIGN_UP);
                }}
            >
                <i className='arrow-left-icon'></i>
            </button>
            <p className='mb0 lg fw500'>Go Back</p>
        </Modal.Header>

        <Modal.Body>
            {isFetching && <div class="loader-in">
                <span></span>
            </div>}
            {!isFetching &&
                <h6 className=''>Privacy Policy</h6>
            }
            <div className="basic-text">
                <div dangerouslySetInnerHTML={{ __html: data || "" }} />
            </div>
        </Modal.Body>
    </Modal>;
}

export default Component;
