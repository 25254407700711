import { Modal } from 'react-bootstrap';

/**
 * Component for displaying a modal to add a comment.
 *
 * This component displays a modal to allow users to add a comment. It includes
 * a textarea for entering the comment and buttons to submit or cancel.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {boolean} props.isOpen - Indicates whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal close.
 * @param {string} props.comment - The current comment text.
 * @param {Function} props.setComment - Function to set the comment text.
 * @param {Function} props.onSubmit - Function to handle form submission.
 * @returns {JSX.Element} - Rendered Component.
 */
function Component({ isOpen, onClose, comment, setComment, onSubmit }) {
    return (
        <Modal className='add-comment' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                <h6 className='f20 mb0'>Add comment</h6>
            </Modal.Header>

            <Modal.Body>
                <hr className='mt10' />
                <div className='form-in'>
                    <label>Comment</label>
                    <div className='f-in'>
                        <textarea value={comment} rows="3" onChange={(e)=>setComment(e.target.value)} placeholder='Add comment'></textarea>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className='btns'>
                    <button className='btn-white lg' onClick={onClose}>Back</button>
                    <button className='btn lg' disabled={!comment} type='button' onClick={onSubmit}>Add Comment</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

// Export the Component as the default export
export default Component;
