import { createSlice } from "@reduxjs/toolkit";
import { constants } from "../../../helpers";
import { localStorageRemove, localStorageSet } from "../../../utils";
import {
  disconnectSocialLogin,
  googleAuth,
  socialLogin,
  updateUserSettings,
  userLogin,
} from "../../services/auth";

const walletInitialDetails = {
  id: "",
  isConnect: false,
  address: "",
};

const initialState = {
  isloggedin: false,
  profilePicture: "",
  userId: "",
  username: "",
  email: "",
  wallet: walletInitialDetails,
  isTwitterLogin: false,
  twitterProfileUrl: "",
  isDiscordLogin: false,
  isGoogleLogin:false,
  isPasswordSetDone:false,
  discordProfileUrl: "",
  openCollectives: [],
  socialLoginInfo: {},
  goalDetails: {
    minGoalInUSD: 1597.46,
    minGoalInEth: 0.015,
    minContributionInEth: 0.015 / 5,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    /** Clearing redux state */
    clearReduxState() {
      localStorageRemove("accessToken");
      // Note that this should be left intentionally empty.
      // Clearing redux state and localForage happens in rootReducer.ts.
      return initialState;
    },

    setRegistrationDetails(state, action) {
      localStorageSet("accessToken", action.payload?.accessToken || "");

      state.isloggedin = true;
      state.userId = action.payload?.userId || "";
      state.profilePicture = action.payload?.profilePicture || "";
      state.username = action.payload?.username || "";
      state.email = action.payload?.email || "";
    },

    setUpdatedEmail(state, action) {
      state.email = action.payload?.email || "";
    },

    setWalletDetails(state, action) {
      state.wallet = {
        ...state.wallet,
        ...(action.payload || {}),
      };
    },
    setSocialLoginInfo(state, action) {
      state.socialLoginInfo = {
        ...(state.socialLoginInfo || {}),
        ...action.payload.data,
      };
    },

    /**
     *  Saving Open Collectives
     */
    setOpenCollectives(state, action) {
      state.openCollectives = action.payload || [];
    },
    clearOpenCollectives(state) {
      state.openCollectives = [];
    },
    /**
     *  End of Saving Open Collectives
     */

    /**
     *  Saving Open Collectives
     */
    setGoalDetails(state, action) {
      state.goalDetails = {
        ...(action.payload || {}),
      };
    },
    /**
     *  End of Saving Open Collectives
     */
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, action) => {
      if (action.payload?.data?.accessToken) {
        localStorageSet("accessToken", action.payload?.data?.accessToken || "");

        state.isloggedin = true;
        state.userId = action.payload?.data?.userId || "";
        state.profilePicture = action.payload?.data?.profilePicture || "";
        state.username = action.payload?.data?.userName || "";
        state.email = action.payload?.data?.email || "";
        state.isTwitterLogin = action.payload?.data.isTwitterLogin || "";
        state.twitterProfileUrl = action.payload?.data.twitterProfileUrl || "";
        state.isDiscordLogin = action.payload?.data.isDiscordLogin || "";
        state.discordProfileUrl = action.payload?.data.discordProfileUrl || "";
        const wallet = {
          isConnect: !!action.payload?.data?.isWalletConnect,
          address: action.payload?.data?.walletAddress || "",
        };
        state.wallet = wallet;
      }
      state.isGoogleLogin = action.payload?.data?.isGoogleLogin || false;
      state.isPasswordSetDone =
          action.payload?.data?.isPasswordSetDone || false;
    });
    builder.addCase(socialLogin.fulfilled, (state, action) => {
      state.isTwitterLogin = action.payload?.data?.isTwitterLogin || false;
      state.twitterProfileUrl = action.payload?.data?.twitterProfileUrl || "";
      state.isDiscordLogin = action.payload?.data?.isDiscordLogin || false;
      state.discordProfileUrl = action.payload?.data?.discordProfileUrl || "";
    });
    builder.addCase(googleAuth.fulfilled, (state, action) => {
      if (action.payload?.data?.accessToken) {
        localStorageSet("accessToken", action.payload?.data?.accessToken || "");

        state.isloggedin = true;
        state.userId = action.payload?.data?.userId || "";
        state.profilePicture = action.payload?.data?.profilePicture || "";
        state.username = action.payload?.data?.userName || "";
        state.email = action.payload?.data?.email || "";
        state.isTwitterLogin = action.payload?.data.isTwitterLogin || "";
        state.isGoogleLogin = action.payload?.data.isGoogleLogin || false;
        state.isPasswordSetDone =
          action.payload?.data?.isPasswordSetDone || false;
        state.twitterProfileUrl = action.payload?.data.twitterProfileUrl || "";
        state.isDiscordLogin = action.payload?.data.isDiscordLogin || false;
        state.discordProfileUrl = action.payload?.data.discordProfileUrl || "";
        const wallet = {
          isConnect: !!action.payload?.data?.isWalletConnect,
          address: action.payload?.data?.walletAddress || "",
        };
        state.wallet = wallet;
      }
    });
    builder.addCase(disconnectSocialLogin.fulfilled, (state, action) => {
      if (
        action.meta.arg.socialLoginType ===
        constants.PROFILE.SOCIAL_MEDIA.TYPE.TWITTER
      ) {
        state.isTwitterLogin = false;
        state.twitterProfileUrl = "";
      } else {
        state.isDiscordLogin = false;
        state.discordProfileUrl = "";
      }
    });
    builder.addCase(updateUserSettings.fulfilled, (state, action) => {
      if (action.payload?.data?.userName) {
        state.username = action.payload.data.userName;
      }
    });
  },
});

export const {
  clearReduxState,
  setRegistrationDetails,
  setUpdatedEmail,
  setWalletDetails,
  setSocialLoginInfo,

  setOpenCollectives,
  clearOpenCollectives,

  setGoalDetails,
} = authSlice.actions;

export default authSlice.reducer;
