/**
 * Checks if a user's profile is verified based on login methods.
 *
 * @param {Object} data - The user data object.
 * @returns {boolean} - Whether the profile is verified or not.
 */
export function isProfileVerified(data) {
  return data.isTwitterLogin || data.isDiscordLogin;
  // || data.isTelegramLogin;
}
