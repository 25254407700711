import { useState } from "react";
import Component from "./Component";

function Collectives() {
  const [activeTab, setActiveTab] = useState("created");

  return <Component activeTab={activeTab} setActiveTab={setActiveTab} />;
}

export default Collectives;
