import {
  RainbowKitProvider,
  connectorsForWallets,
  darkTheme,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import {
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
} from "@rainbow-me/rainbowkit/wallets";
import "bootstrap/scss/bootstrap.scss";
import { useCallback, useState } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import * as wagmiChains from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import config from "./Config";
import { useAppSelector } from "./common/custom-hooks";
import { store } from "./redux/store";
import AppRoutes from "./router";
import "./style.scss";
import { getWalletIcon } from "./utils";

const projectId = config.WALLET_PROJECT_ID;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [wagmiChains[config["NETWORK"]]],
  [publicProvider()]
);

const connectors = connectorsForWallets([
  {
    groupName: "Select wallet to connect",
    wallets: [
      metaMaskWallet({ projectId, chains }),
      rainbowWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName: "web3connect" }),
      injectedWallet({ chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

const CustomAvatar = ({ size }) => {
  const { wallet } = useAppSelector((state) => state.auth);
  const WalletIcon = getWalletIcon(wallet.id);

  return WalletIcon ? (
    <img
      src={WalletIcon}
      width={size}
      height={size}
      style={{ borderRadius: 999 }}
      alt={wallet.id || "Wallet"}
    />
  ) : (
    <div
      style={{
        backgroundColor: "red",
        borderRadius: 999,
        height: size,
        width: size,
      }}
    >
      :^)
    </div>
  );
};

function App() {
  // eslint-disable-next-line no-unused-vars
  const [reRenderCount, forceReRender] = useState(0);

  const handleReRenderCount = useCallback(() => {
    forceReRender((pCount) => (pCount || 0) + 1);
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          avatar={CustomAvatar}
          modalSize="compact"
          chains={chains}
          appInfo={{
            appName: "web3connect",
          }}
          theme={{
            lightMode: lightTheme(),
            darkMode: darkTheme(),
          }}
        >
          <AppRoutes onForceReRender={handleReRenderCount} />
        </RainbowKitProvider>
      </WagmiConfig>
    </Provider>
  );
}

export default App;
