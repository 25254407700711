import PropTypes from "prop-types";
import { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideClick(ref, { isOpen, onElementClick }) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (
                ref.current &&
                !ref.current.contains(event.target) &&
                !(event?.target?.outerHTML === '<span></span>') && // Additional conditions to exclude specific elements
                !(event?.target?.outerHTML === '<button class=""><span></span><span></span><span></span></button>')
            ) {
                if (isOpen && !event.target.getAttribute('data-side-bar-menu-btn')) {
                    onElementClick(false);
                }
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on cleanup
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, ref, onElementClick]);
}

/**
 * Component that alerts if you click outside of it
 */
export const ViewOutsideClick = (props) => {
    const wrapperRef = useRef(null);

    useOutsideClick(wrapperRef, props);

    return <div ref={wrapperRef} {...props}>{props.children}</div>;
};

ViewOutsideClick.propTypes = {
    children: PropTypes.element.isRequired,
    onElementClick: PropTypes.func.isRequired,
};

export default ViewOutsideClick;
