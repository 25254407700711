import { constants } from "../../../helpers";

export function getModalActionType(modalType, prevModalType) {
  switch (modalType) {
    case constants.MODAL.SIGN_UP:
    case constants.MODAL.SIGN_UP_VERIFY_EMAIL:
    case constants.MODAL.SIGN_UP_OTP_VERIFICATION_SUCCESS:
      return constants.API_TYPE.REGISTER;

    case constants.MODAL.LOGIN:
      return constants.API_TYPE.LOGIN;

    case constants.MODAL.FORGOT_PASSWORD:
    case constants.MODAL.FORGOT_PASSWORD_VERIFY_EMAIL:
    case constants.MODAL.SET_NEW_PASSWORD:
      return constants.API_TYPE.FORGOT_PASSWORD;

    case constants.MODAL.UPDATE_EMAIL:
    case constants.MODAL.VERIFY_UPDATED_EMAIL:
      return constants.API_TYPE.EMAIL;

    default:
      return "";
  }
}
