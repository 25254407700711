import { useCallback } from "react";
import { putDataApi } from "../../api/methods";
import { constants, messages } from "../../helpers";
import {
  setRefreshCollectiveDetails,
  setRefreshVotingDelegationList,
} from "../../redux/features/commonSlice";
import { errorAlert } from "../../utils";
import { useAppDispatch } from "./redux";
import useCustomModals from "./useCustomModals";
import useReduxStore from "./useReduxStore";

/**
 * Custom hook for updating a voting delegation.
 * @param {Object} options - Hook options.
 * @param {string} options.votingDelegationId - ID of the voting delegation being updated.
 * @param {function} options.resetForm - Function to reset the form after update.
 * @param {function} options.onIsSubmitting - Callback to handle submission status.
 * @returns {Object} - An object with the addUpdateVotingDelegation function.
 */
function useUpdateVotingDelegation({
  votingDelegationId,
  resetForm,
  onIsSubmitting,
}) {
  const { onOpenModal } = useCustomModals();
  const { commonReducer } = useReduxStore();
  const dispatch = useAppDispatch();

  // Callback function to update the voting delegation.
  const addUpdateVotingDelegation = useCallback(
    (transactionHash) => {
      try {
        if (
          !transactionHash ||
          !votingDelegationId ||
          !commonReducer.modal?.collective?.collectiveId
        ) {
          return false;
        }

        // Disable save changes before triggering the API call.
        onIsSubmitting(true);

        // Prepare the request data for the API call.
        const request = {
          collectiveId: commonReducer.modal.collective.collectiveId,
          votingDelegationId,
          transactionHash,
          notify: true,
          callback: (response) => {
            if (response?.statusCode === 200) {
              // Reset the form, open modal, and refresh collective details.
              resetForm();
              onOpenModal();
              dispatch(setRefreshCollectiveDetails(true));
              setTimeout(() => {
                dispatch(setRefreshCollectiveDetails(false));
              }, 1000);

              dispatch(setRefreshVotingDelegationList(true));
              setTimeout(() => {
                dispatch(setRefreshVotingDelegationList(false));
              }, 1000);
            }

            // Enable save changes after API success.
            onIsSubmitting(false);
          },
        };

        // Make the API call to update the voting delegation.
        putDataApi({
          path: constants.API_PATHS.CHANGE_VOTE_DELEGATION,
          data: request,
        });
      } catch (error) {
        // Handle API call failure.
        errorAlert(messages.COLLECTIVE.WALLET.VOTING_DELEGATION_FAILED);
      }
    },
    [
      resetForm,
      onIsSubmitting,
      commonReducer,
      votingDelegationId,
      dispatch,
      onOpenModal,
    ]
  );

  return { addUpdateVotingDelegation };
}

export default useUpdateVotingDelegation;
