import { Modal } from 'react-bootstrap';
import { ImageNotFound } from '../../../../../assets/images/images';
import { calculateTimeDifference, getFormattedTimeDifference, truncateToDecimalDigits } from '../../../../../utils';
import { constants } from '../../../../../helpers';

function Component({ isOpen, onClose, votersDetails }) {

    return (
        <Modal className='voter-info' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                <h6 className='mb16'>Received delegates voters from</h6>
            </Modal.Header>

            <Modal.Body>
                {votersDetails?.votingDelegationIds.length > 0 &&
                    votersDetails?.votingDelegationIds.filter((data) => data.userId !== votersDetails?.user_detail?._id).map((delegatesUser) => {
                        const votedTime = calculateTimeDifference(new Date(delegatesUser.created))
                        return (
                            <div key={delegatesUser?.userId} className='vote-comment1'>
                                <figure><img src={delegatesUser?.user_detail?.profilePicture || ImageNotFound} alt="icon" /></figure>
                                <div className='text'>
                                    <p className='lg'>{delegatesUser?.user_detail?.userName || "N/A"}</p>
                                </div>

                                <div className='ml-auto d-flex align-items-baseline flex-shrink-0'>
                                    <p className='lg mr12'>{delegatesUser?.votingPercentage ? truncateToDecimalDigits({
                                        number: delegatesUser.votingPercentage,
                                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                                    }) : '0'}% of votes</p>
                                    <p className=' text-blue fw600'>{getFormattedTimeDifference(votedTime)}</p>
                                    {/* {getFormattedTimeDifference(votetime)} */}
                                </div>
                            </div>
                        )
                    })}

            </Modal.Body>
        </Modal>
    );
}

export default Component;
