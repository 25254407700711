import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputField from '../input-field';

function PhoneNumber({ disabled, label, labelClass, required, errors, touched, name, type, countryCode, placeholder, onCodeChange, onCodeBlur, onSetDialCode, ...restProps }) {
    return <>
        <div className='form-in phone'>
            {label &&
                <label className={labelClass} htmlFor={label}>
                    {label}
                    {required && <span className='req'>*</span>}
                </label>
            }

            <div className='f-in'>
                    <PhoneInput
                        country={countryCode ? countryCode.toLowerCase() : ""}
                        disabled={disabled}
                        onBlur={onCodeBlur}
                        onChange={(e, data) => {
                            if (data?.countryCode) {
                                onCodeChange(data.countryCode);
                            }
                            if (onSetDialCode && data?.dialCode) {
                                onSetDialCode(data.dialCode);
                            }
                        }}
                    />
                    <InputField
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                        errors={errors}
                        touched={touched}
                        {...restProps}
                    />
            </div>
        </div>
    </>;
}

PhoneNumber.defaultProps = {
    disabled: false,
    label: '',
    labelClass: '',
    countryCode: '',
    required: false,
    onCodeChange: () => { },
    onCodeBlur: () => { },
    onSetDialCode: () => { }
};

export default PhoneNumber;
