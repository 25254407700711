import Select from 'react-select';

function ReactSelect({ disabled, options, value, label, labelClass, placeholder, isDropdownIndicator, components, isSearchable, onSelect, onBlur, menuPlacement, getOptionLabel, getOptionValue }) {

    const selectProps = {
        options,
        value,
        placeholder,
        menuPlacement,
        isSearchable,
        onChange: onSelect,
        getOptionLabel,
        getOptionValue,
        onBlur,
        isDisabled: disabled
    };

    if (!isDropdownIndicator) {
        selectProps.components = { DropdownIndicator: () => null, IndicatorSeparator: () => null };
    }

    if (components) {
        selectProps.components = { ...selectProps.components, ...components };
    }

    return (
        <div className="form-in">
            {label && <label className={labelClass} htmlFor={label}>{label}</label>}
            <div className='f-in'>
                <Select
                    {...selectProps}
                />
            </div>
        </div>
    );
}

ReactSelect.defaultProps = {
    disabled: false,
    label: '',
    labelClass: '',
    isSearchable: true,
    getOptionLabel: (option) => option.label,
    getOptionValue: (option) => option.value,
    onBlur: () => { }
};

export default ReactSelect;
