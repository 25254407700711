import { Form, Modal } from 'react-bootstrap';
import * as Yup from "yup";
import FormikWrapper from '../../../formik-wrapper';
import { Logo } from '../../../../../assets/images/images';
import InputField from '../../../input-field';
import { useAppDispatch, useAppNavigate, useAppSelector, useCustomModals, useFingerprint } from '../../../../custom-hooks';
import { onToggleModal, setReferralDetails } from '../../../../../redux/features/commonSlice';
import { constants, messages, regex } from '../../../../../helpers';
import { capitalizeFirstLetter, errorAlert, successAlert } from '../../../../../utils';
import { googleAuth } from '../../../../../redux/services/auth';
import { postDataApi } from '../../../../../api/methods';

// Initial state for the form
const initialState = {
    referralCode: '',
    email: '',
    enableJoinWaitlist: false
};

// Validation schema for Yup
const referral = Yup.object({
    referralCode: Yup.string()
        .required(messages.IS_REQUIRED("Referral Code"))
        .matches(regex.REFERRAL_CODE, "Invalid Referral Code"),
});

/**
 * ReferralComponent component for handling referral actions and UI.
 *
 * This component handles referral actions, displays a modal, and manages form validation.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {boolean} props.isOpen - Indicates whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal close.
 * @returns {JSX.Element} - Rendered ReferralComponent.
 */
function ReferralComponent({ isOpen, onClose }) {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const deviceId = useFingerprint();
    const { onOpenModal } = useCustomModals();

    const commonReducer = useAppSelector(state => state.common);

    return (
        // FormikWrapper for form handling
        <FormikWrapper
            enableReinitialize
            initialValues={initialState}
            validationSchema={referral}
            onSubmit={(values, { setErrors, setFieldValue, setTouched, resetForm, errors, setSubmitting }) => {
                const request = {
                    email: commonReducer.modal.referralGoogle.email || "",
                    referralCode: values.referralCode,
                    socialId: commonReducer.modal.referralGoogle.sub,
                    deviceId: deviceId,
                    deviceToken: "string",
                    notify: false,
                    callback: (response) => {
                        if ((response?.statusCode === 201 && response?.type !== 'JOIN_WAITING_LIST') || response?.statusCode === 200) {
                            dispatch(onToggleModal());
                            navigate(constants.BROWSER_ROUTES.DISCOVER);
                            if (response.message) {
                                successAlert(response.message);
                            }
                        } else if (response?.statusCode === 201 && response?.type === 'JOIN_WAITING_LIST') {
                            setFieldValue('enableJoinWaitlist', true);
                            setTouched({ referralCode: true });
                            setTimeout(() => {
                                setErrors({ referralCode: capitalizeFirstLetter(response.message) });
                            }, 200);
                            setTimeout(() => {
                                setErrors({ referralCode: capitalizeFirstLetter(response.message) });
                            }, 200)
                        } else if (response?.data?.message) {
                            dispatch(onToggleModal());
                            errorAlert(response?.data?.message);
                        }
                    },
                };
                // let timer = setTimeout(() => {
                //     if (values.referralCode) {
                // onCheckReferralApi({ referralCode: values.referralCode }, { values, setFieldValue });
                //     }
                // }, 200);
                dispatch(googleAuth(request));
                if (values.enableJoinWaitlist) {
                    const request = {
                        "referralCode": values.referralCode,
                        callback: (response) => {
                            if (response) {
                                const { statusCode, message, data, type } = response;

                                if (statusCode === 201 && type === 'ADD_WAITING_LIST' && message) {
                                    successAlert(message);

                                    resetForm();
                                } else if (data.statusCode === 400 && data.type === 'EMAIL_ALREADY_ADDED' && data.message) {
                                    setErrors({ email: capitalizeFirstLetter(data.message) });
                                } else if (data?.type === 'EMAIL_ALREADY_EXIST' && data.message) {
                                    setErrors({ email: capitalizeFirstLetter(data.message) });
                                    setTimeout(() => {
                                        dispatch(setReferralDetails({ email: values.email }));
                                        onOpenModal(constants.MODAL.LOGIN);
                                        setErrors({ ...errors, email: undefined });
                                    }, 1000);
                                }
                            }

                            // enabled save changes after api success
                            setSubmitting(false);
                        }
                    };
                    if (values.email) {
                        request.email = values.email;
                    }
                    postDataApi({ path: constants.API_PATHS.JOIN_REFERRAL_WAITING_LIST, data: request });
                }
            }
            }
        >
            {({ values, errors, touched, isSubmitting, handleSubmit, setFieldValue, setErrors, setTouched }) => {

                return <Form>
                    <Modal className='add-comment' show={isOpen} onHide={onClose} backdrop="static">

                        <Modal.Header closeButton>
                            <figure><img src={Logo} alt="Logo" /></figure>
                        </Modal.Header>
                        <Modal.Body>
                            <h5 className='m6'>Referral Code</h5>
                            <InputField
                                className="info-error"
                                name='referralCode'
                                label='Referral Code'
                                placeholder="Enter Referral Code"
                                inputWrapperClass=''
                                required
                                errors={errors}
                                touched={touched}
                            />
                            {values.enableJoinWaitlist &&
                                <InputField
                                    type="email"
                                    name='email'
                                    label='Email for Waitlist'
                                    placeholder="Enter Email for Waitlist"
                                    inputWrapperClass=''
                                    required={values.enableJoinWaitlist}
                                    errors={errors}
                                    touched={touched}
                                // onChange={(event) => {
                                //     setFieldValue('email', event.target.value);
                                //     setTouched({ email: true });
                                //     onCheckReferral({ values: { ...values, email: event.target.value }, setFieldValue, setErrors, setTouched });
                                // }}
                                // onBlur={() => {
                                //     onCheckReferral({ values, setFieldValue, setErrors, setTouched });
                                // }}
                                />
                            }

                        </Modal.Body>
                        <Modal.Footer>

                            <div className='form-btn text-center pt24'>
                                <button
                                    className='btn lg'
                                    // disabled={values.enableJoinWaitlist ? false : isDisabledSubmit}
                                    onClick={handleSubmit}
                                    type='submit'
                                >
                                    {values.enableJoinWaitlist ? "Join Waitlist" : "Continue"}
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </Form>
            }}
        </FormikWrapper>
    );
}

// Export the ReferralComponent as the default export
export default ReferralComponent;
