import { useMemo } from 'react';
import { formatDistance } from "../../../../../common/third-party-instances";
import { useReduxStore } from '../../../../custom-hooks';
import Component from './Component';

/**
 * CollectorInfo component to display collector details.
 * @param {object} props - The properties passed to the component.
 * @returns {JSX.Element} - The rendered component.
 */
function CollectorInfo(props) {
    // Accessing commonReducer from custom hooks
    const { commonReducer } = useReduxStore();

    // Computing collectorDetails using useMemo
    const collectorDetails = useMemo(() => {
        const details = commonReducer.modal?.collectorDetails || {};
        const totalContribution = details.collectiveDetails.goal?.collectors?.[0]?.totalContribution || 0;
        const collectorContribution = details.collector.contribution || 0;
        const votingDelegationList = details.collector?.votingDelegationIds || [];
        const votingDelegationCount = votingDelegationList.length;
        const collectorDelegation = votingDelegationList.find(item => item.user_detail?.userName === details.username);
        const isSelfDelegated = collectorDelegation && votingDelegationCount === 1;

        return {
            ...details,
            firstContributionTime: details.collector?.createdAt ? `${formatDistance(new Date(), new Date(details.collector.createdAt).getTime())} ago`.replace('about', '') : '',
            contribution: collectorContribution,
            contributionPercentage: (collectorContribution / totalContribution) * 100,
            votingDelegationList,
            votingDelegationCount,
            collectorDelegation,
            isSelfDelegated
        };
    }, [commonReducer]);

    // Rendering the Component with collectorDetails as a prop
    return <Component {...props} collectorDetails={collectorDetails} />;
}

// Export the CollectorInfo component as the default export
export default CollectorInfo;
