import { useEffect } from 'react';
import { constants } from '../../../../../helpers';
import { onToggleModal } from '../../../../../redux/features/commonSlice';
import { useAppDispatch, useAppNavigate } from '../../../../custom-hooks';
import Component from './Component';

function SignUpOtpVerificationSuccess(props) {
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setTimeout(() => {
            dispatch(onToggleModal());
            navigate(constants.BROWSER_ROUTES.DISCOVER);
        }, 3 * 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
}

export default SignUpOtpVerificationSuccess;
