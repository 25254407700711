import { useCallback } from 'react';
import {
    setRefreshAllCollectorList,
    setRefreshCollectiveDetails,
    setRefreshVotingDelegationList
} from '../../../../../../../redux/features/commonSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../custom-hooks';
import Component from './Component';

/**
 * ContributionSuccess Component handles success scenario after a contribution.
 * It triggers refresh of collective details and related data.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {Object} props.commonReducer - Common data from Redux.
 * @returns {JSX.Element} - Rendered Component.
 */
function ContributionSuccess(props) {
    const dispatch = useAppDispatch();
    const commonReducer = useAppSelector(state => state.common);

    /**
     * Callback function to refresh collective details and related data.
     * It dispatches actions to refresh collective details, voting delegation list, and all collector list.
     * Refresh is triggered after a delay, and the flags are set to false after another delay.
     */
    const handleRefreshDetails = useCallback(() => {
        dispatch(setRefreshCollectiveDetails(true));
        dispatch(setRefreshVotingDelegationList(true));
        dispatch(setRefreshAllCollectorList(true));

        setTimeout(() => {
            dispatch(setRefreshCollectiveDetails(false));
            dispatch(setRefreshVotingDelegationList(false));
            dispatch(setRefreshAllCollectorList(false));
        }, 1000);

        // Trigger a refresh of collective details after a delay
        setTimeout(() => {
            dispatch(setRefreshCollectiveDetails(true));
            dispatch(setRefreshVotingDelegationList(true));
            dispatch(setRefreshAllCollectorList(true));

            setTimeout(() => {
                dispatch(setRefreshCollectiveDetails(false));
                dispatch(setRefreshVotingDelegationList(false));
                dispatch(setRefreshAllCollectorList(false));
            }, 1000);

            // Trigger a refresh of collective details after another delay
            setTimeout(() => {
                dispatch(setRefreshCollectiveDetails(true));
                dispatch(setRefreshVotingDelegationList(true));
                dispatch(setRefreshAllCollectorList(true));

                setTimeout(() => {
                    dispatch(setRefreshCollectiveDetails(false));
                    dispatch(setRefreshVotingDelegationList(false));
                    dispatch(setRefreshAllCollectorList(false));
                }, 1000);
            }, 10000); // Another delay of 10 seconds before triggering refresh
        }, 10000); // Delay of 10 seconds before triggering refresh
    }, [dispatch]);

    return (
        <Component
            {...props}
            commonReducer={commonReducer}
            onRefreshDetails={handleRefreshDetails}
        />
    );
}

// Export the ContributionSuccess component as the default export
export default ContributionSuccess;
