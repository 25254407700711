// Import all validation schema objects from the "schemas" module
import * as schema from "./schemas";

// Define a function that takes a "type" parameter and returns a validation schema
function getFormikValidationSchema(type) {
  // If a "type" is provided, return the corresponding schema from the "schema" object
  return type ? schema[type] : null;
}

// Export the function for use in other parts of the application
export default getFormikValidationSchema;
