import React from 'react';
import Modal from 'react-bootstrap/Modal';

const Component = ({ isOpen, onClose, onVetoExpire }) => {
    return (
        <Modal className="alert-modal" show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb12' closeButton onClick={onClose}>
                <Modal.Title className='mb0 h5'>Expire Veto Period?</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    Are you sure you want to expire Veto Period?
                </p>
            </Modal.Body>

            <Modal.Footer>
                <div>
                    <button type='button' className='btn-white mr12' onClick={onClose}>Cancel</button>
                    <button type='button' className='btn' onClick={onVetoExpire} >Confirm</button>
                </div>
            </Modal.Footer>

        </Modal>
    )
}
export default Component;


