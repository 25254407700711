import { ConnectButton } from "@rainbow-me/rainbowkit";

function Header({ walletIcon, onSetConnectingWallet }) {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        if (chain?.unsupported) {
          openChainModal();
        }

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <div>
                    <button
                      className="top-btn "
                      onClick={(event) => {
                        openConnectModal(event);
                        onSetConnectingWallet(true);
                      }}
                    >
                      Connect Wallet
                    </button>
                  </div>
                );
              }
              if (chain.unsupported) {
                return (
                  <div>
                    <button
                      type="button"
                      className="top-btn wallet-btn"
                      onClick={openChainModal}
                    >
                      Wrong network
                    </button>
                  </div>
                );
              }
              return (
                <div>
                  <button
                    type="button"
                    className="rainbow-btn"
                    onClick={openAccountModal}
                  >
                    <img
                      src={walletIcon}
                      className="wallet-address-icon"
                      alt={chain.name ?? "Chain icon"}
                    />
                    <span className="wallet-address">
                      {account.displayName}
                    </span>
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}

export default Header;
