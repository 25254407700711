import React, { useCallback, useState } from 'react';
import config from '../../../../../../../Config';
import { constants } from '../../../../../../../helpers';
import { useAppNavigate, useReduxStore } from '../../../../../../custom-hooks';
import Component from './Component';

function CollectiveLaunchSuccess(props) {
    const navigate = useAppNavigate();
    const { commonReducer } = useReduxStore();

    const [isCopy, setIsCopy] = useState(false);

    const handleGoHome = useCallback(() => {
        navigate(constants.BROWSER_ROUTES.DISCOVER);
        props.onOpenModal();
    }, [navigate, props]);

    const handleViewCollective = useCallback(() => {
        navigate(`${constants.BROWSER_ROUTES.VIEW_COLLECTIVE}?collectiveId=${commonReducer.modal?.collective?.collectiveId || ''}`);
        props.onOpenModal();
    }, [navigate, props, commonReducer]);

    const shareLink = `${config.WEB_URL}${constants.BROWSER_ROUTES.VIEW_COLLECTIVE}?collectiveId=${commonReducer.modal?.collective?.collectiveId || ''}`;

    const handleCopy = useCallback(() => {
        navigator.clipboard.writeText(shareLink)
            .then(() => {
                setIsCopy(true);
                setTimeout(() => {
                    setIsCopy(false);
                }, 2000);
            });
    }, [shareLink]);

    return <Component
        {...props}
        commonReducer={commonReducer}
        shareLink={shareLink}
        isCopy={isCopy}
        onGoHome={handleGoHome}
        onViewCollective={handleViewCollective}
        onCopy={handleCopy}
    />;
}

export default CollectiveLaunchSuccess;
