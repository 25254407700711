import { useCallback } from "react";
import { constants } from "../../helpers";
import { onToggleModal } from "../../redux/features/commonSlice";
import { useAppDispatch } from "./redux";

function useLoginFlow() {
  const dispatch = useAppDispatch();

  const handleOpenModal = useCallback(
    function () {
      dispatch(onToggleModal({ modalType: constants.MODAL.SIGN_UP }));
    },
    [dispatch]
  );

  return { onOpenLoginFlowModal: handleOpenModal };
}

export default useLoginFlow;
