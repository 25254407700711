import { Modal } from 'react-bootstrap';
import { TwitterShareButton } from 'react-share';
import config from '../../../../../../../Config';
import { GreenCheck, confett } from '../../../../../../../assets/images/images';
import { constants } from '../../../../../../../helpers';

/**
 * Component function displays a success message after a contribution,
 * allowing the user to share their contribution on Twitter.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {boolean} props.isOpen - Indicates if the modal is open or not.
 * @param {Object} props.commonReducer - Common data from Redux.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {Function} props.onOpenModal - Function to open another modal.
 * @param {Function} props.onRefreshDetails - Function to refresh details.
 * @returns {JSX.Element} - Rendered Component.
 */
function Component({ isOpen, commonReducer, onClose, onOpenModal, onRefreshDetails }) {
    // const imageUrl = commonReducer?.modal?.collective?.image;
    const linkUrl = `${config.WEB_URL}${constants.BROWSER_ROUTES.VIEW_COLLECTIVE}?collectiveId=${commonReducer.modal?.collective?.collectiveId || ''}`;
    const textToShare = `I just purchased a fraction of an NFT on MyMetaFi for ${commonReducer.modal?.collective?.contribute} ETH! Co-own this NFT with me by clicking the link:`;
    const combinedUrl = `${textToShare} ${linkUrl}`;

    return (
        <Modal
            className='success-contri'
            show={isOpen}
            onHide={() => {
                onRefreshDetails();
                onClose();
            }}
            backdrop="static"
        >
            <video className="confetti" autoPlay muted playsInline>
                <source src={confett} type="video/mp4" />
            </video>
            <Modal.Header closeButton>
                <figure><img src={GreenCheck} alt='Green Check' /></figure>
            </Modal.Header>

            <Modal.Body>
                <h5 className='m8'>{commonReducer.modal?.collective?.contribute || 0} {constants.COLLECTIVE.PRICE.SYMBOL.ETH} Contributed</h5>
                <p className='m0 text-black'>Your request to contribute in collective is in progress. Please hold tight while we ensure a smooth transaction.</p>
                <p className='m0 text-black'>You will be notified of the Collective’s progress.</p>
            </Modal.Body>

            <Modal.Footer>
                <div className='btns'>
                    <button
                        className='btn-white lg skip'
                        onClick={() => {
                            onOpenModal();
                            onRefreshDetails();
                        }}
                    >
                        Close
                    </button>
                    {/* <button
                    className='btn lg'
                    onClick={underDevAlert}
                >
                    <i className='twitter-white-icon'></i> Share on Twitter110
                </button> */}
                    <TwitterShareButton
                        url={combinedUrl}
                        className="btn lg shareTwitterBtn"
                    >
                        <i className='twitter-white-icon'></i> Share on Twitter
                    </TwitterShareButton>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

// Export the Component as the default export
export default Component;
