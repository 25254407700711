/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { PorfileBanner } from '../../../../../assets/images/images';
import FormikWrapper from '../../../formik-wrapper';

/**
 * Functional component for displaying a modal for collective preview.
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - Determines whether the modal is open.
 * @param {Object} props.initialState - Initial state for the form in the modal.
 * @param {object} props.schema - Validation schema for the form.
 * @param {Function} props.onClose - Function to handle modal close event.
 * @param {Function} props.onOpenModal - Function to handle modal open event.
 * @param {Function} props.onSubmit - Function to handle form submission.
 * @returns {JSX.Element} JSX element representing the modal.
 */
function Component({ isOpen, initialState, schema, onClose, onOpenModal, onSubmit }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
        {({ values, errors, isSubmitting, touched, handleSubmit }) => {
            return <Form>
                <Modal className='collective-preview' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        <figure className='added-img'><img src={PorfileBanner} alt='Profile Banner' /></figure>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='text-center'>
                            <h4 className='m6 fw600'>Success!</h4>
                            <p className='lg m32'>Your collective is now live</p>
                        </div>

                        <div className=''>
                            <p className='text-center lg m12'>Share</p>

                            <div className='form-in left-icon right-icon'>
                                <input type='text' placeholder='Enter link' />
                                <span className='icon-left'><i className='link-icon'></i></span>
                                <span className='icon-right'><button className='btn sm'>Copy</button></span>
                            </div>
                        </div>

                        <div className='added-links'>
                            <a className='#'><i className='twitter-grey-icon'></i></a>
                            <a className='#'><i className='line-grey-icon'></i></a>
                            <a className='#'><i className='discord-grey-icon'></i></a>
                            <a className='#'><i className='telegram-grey-icon'></i></a>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btns'>
                            <button className='btn-white lg'>Back to home</button>
                            <button className='btn lg'>View Collective</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default Component;
