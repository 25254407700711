import { lazy } from "react";

import { constants } from "../helpers";

// Instead of regular import statements, we will use the above approach for lazy loading
const HomePage = lazy(() => import("../pages/home"));
const ProfilePage = lazy(() => import("../pages/profile"));
const ProfileSettingsPage = lazy(() => import("../pages/profile-settings"));
const SocialMediaPage = lazy(() => import("../pages/social-media"));
const WalletsPage = lazy(() => import("../pages/wallets"));
const TermsAndConditionsPage = lazy(() =>
  import("../pages/terms-and-conditions")
);
const BookmarksPage = lazy(() => import("../pages/bookmarks"));
const ViewCollective = lazy(() => import("../pages/view-collective"));
const AddCollectivePage = lazy(() => import("../pages/add-collective"));
const OpenCollectiveDetailPage = lazy(() =>
  import("../pages/open-collective-details")
);
const SomeOneProfilePage = lazy(() => import("../pages/someone-profile"));
const NotFoundPage = lazy(() => import("../pages/not-found"));
const RankingPage = lazy(() => import("../pages/rankings"));
const AddNewProposalPage = lazy(() => import("../pages/add-new-proposal"));
const ProposalConfirmationPage = lazy(() =>
  import("../pages/add-new-proposal/modules/proposal-confirmation")
);
const DashboardPage = lazy(() => import("../pages/dashboard"));
const CommunityPage = lazy(() => import("../pages/community"));
const SearchPage = lazy(() => import("../pages/search"));
// const ReferralPage = lazy(() => import("../pages/referral"));
const AboutUsPage = lazy(() => import("../pages/aboutUs"));
const PrivacyPolicyPage = lazy(() => import("../pages/privacy-policy"));
const ChooseCollectivePage = lazy(() =>
  import("../pages/add-new-proposal/modules/choose-collective")
);
const BuyNftPage = lazy(() =>
  import("../pages/view-collective/modules/buy-nft")
);
const MyCollectivePage = lazy(() =>
  import("../pages/dashboard/modules/my-collectives/all-my-collectives")
);
const EventsPage = lazy(() => import("../pages/events"));

const POST_LOGIN_ROUTES = [
  {
    name: "profile",
    path: constants.BROWSER_ROUTES.PROFILE,
    Component: ProfilePage,
  },
  {
    name: "profile-settings",
    path: constants.BROWSER_ROUTES.PROFILE_SETTINGS,
    Component: ProfileSettingsPage,
  },
  {
    name: "social-media",
    path: constants.BROWSER_ROUTES.SOCIAL_MEDIA,
    Component: SocialMediaPage,
  },
  {
    name: "wallets",
    path: constants.BROWSER_ROUTES.WALLETS,
    Component: WalletsPage,
  },
  {
    name: "bookmarks",
    path: constants.BROWSER_ROUTES.BOOKMARKS,
    Component: BookmarksPage,
  },
  {
    name: "someone-else's-profile",
    path: constants.BROWSER_ROUTES.SOMEONE_PROFILE,
    Component: SomeOneProfilePage,
  },
  {
    name: "add-collective",
    path: constants.BROWSER_ROUTES.ADD_COLLECTIVE,
    Component: AddCollectivePage,
  },
  {
    name: "open-collective-details",
    path: constants.BROWSER_ROUTES.OPEN_COLLECTIVE_DETAILS,
    Component: OpenCollectiveDetailPage,
  },
  {
    name: "add-new-proposal",
    path: constants.BROWSER_ROUTES.ADD_NEW_PROPOSAL,
    Component: AddNewProposalPage,
  },
  {
    name: "proposal-confirmation",
    path: constants.BROWSER_ROUTES.PROPOSAL_CONFIRMATION,
    Component: ProposalConfirmationPage,
  },
  {
    name: "aboutUs",
    path: constants.BROWSER_ROUTES.ABOUTUS,
    Component: AboutUsPage,
  },
  {
    name: "choose-collective",
    path: constants.BROWSER_ROUTES.CHOOSE_COLLECTIVE,
    Component: ChooseCollectivePage,
  },
  {
    name: "buy-nft",
    path: constants.BROWSER_ROUTES.BUY_NFT,
    Component: BuyNftPage,
  },
  {
    name: "my-collectives",
    path: constants.BROWSER_ROUTES.MY_COLLECTIVES,
    Component: MyCollectivePage,
  },
];

const PUBLIC_ROUTES = [
  {
    name: "not-found",
    path: "*",
    Component: NotFoundPage,
  },
  {
    name: "home",
    path: constants.BROWSER_ROUTES.DISCOVER,
    Component: HomePage,
  },
  {
    name: "view-collective",
    path: constants.BROWSER_ROUTES.VIEW_COLLECTIVE,
    Component: ViewCollective,
  },
  {
    name: "dashboard",
    path: constants.BROWSER_ROUTES.DASHBOARD,
    Component: DashboardPage,
  },
  {
    name: "community",
    path: constants.BROWSER_ROUTES.COMMUNITY,
    Component: CommunityPage,
  },
  {
    name: "search",
    path: constants.BROWSER_ROUTES.SEARCH,
    Component: SearchPage,
  },
  {
    name: "events",
    path: constants.BROWSER_ROUTES.EVENTS,
    Component: EventsPage,
  },
  {
    name: "rankings",
    path: constants.BROWSER_ROUTES.RANKINGS,
    Component: RankingPage,
  },
  {
    name: "terms-of-use",
    path: constants.BROWSER_ROUTES.TERMS_AND_CONDITIONS,
    Component: TermsAndConditionsPage,
  },
  {
    name: "privacy-policy",
    path: constants.BROWSER_ROUTES.PRIVACY_POLICY,
    Component: PrivacyPolicyPage,
  },
];

const PRE_LOGIN_ROUTES = [
  // {
  //   name: "referral",
  //   path: constants.BROWSER_ROUTES.REFERRAL,
  //   Component: ReferralPage,
  // },
];

function getRoutes(isloggedin) {
  if (isloggedin) {
    return [...POST_LOGIN_ROUTES, ...PUBLIC_ROUTES];
  }
  return [...PUBLIC_ROUTES, ...PRE_LOGIN_ROUTES];
}

export default getRoutes;
