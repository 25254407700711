import Fingerprint2 from "fingerprintjs2";
import { useEffect, useState } from "react";

/**
 * Custom hook to generate a unique fingerprint for the user.
 * @returns {string} - A unique fingerprint identifier.
 */
function useFingerprint() {
  const [uniqueId, setUniqueId] = useState("");

  useEffect(() => {
    // Use Fingerprint2 library to gather components and generate a fingerprint
    Fingerprint2.get((components) => {
      // Extract component values and concatenate them
      const values = components.map((component) => component.value);

      // Generate a murmur hash from concatenated values
      const murmur = Fingerprint2.x64hash128(values.join(""), 31);

      // Set the unique fingerprint identifier
      setUniqueId(murmur);
    });
  }, []); // Empty dependency array ensures the effect runs only once

  return uniqueId;
}

export default useFingerprint;
