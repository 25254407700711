import { useCallback, useEffect, useRef, useState } from 'react';
import { constants } from '../../../../../helpers';
import NotificationComponent from './Component';
import { useAppDispatch, useHandleCalls } from '../../../../custom-hooks';
import { errorAlert, localStorageGet } from '../../../../../utils';
import { getNotificationList, readAllNotification, readNotification } from '../../../../../redux/services/notifications';

function Notifications() {
  const dispatch = useAppDispatch();
  const listInnerRef = useRef();
  const [notificationList, setNotificationList] = useState(constants.PAGINATION_DATA);
  const [currentPage, setCurrentPage] = useState(1);

  const handleFetchNotification = useCallback(
    (page, isCount) => {
      if (localStorageGet('accessToken')) {
        const query = {
          pageNo: page || currentPage,
          limit: constants.PER_PAGE_NOTIFICATION_RECORDS,
          IsCount: isCount || false,
          // loaderType: constants.LOADER.TYPE.TABLE,
          callback: (response) => {
            if (response?.data?.data) {
              setNotificationList({
                list: page === 1 ? response.data.data : [...notificationList.list, ...response.data.data],
                total: response.data.totalPage,
                pageNo: response.data.pageNo,
                unreadCount: response.data.count,
              });
            }
          }
        };
        dispatch(getNotificationList(query));
      }

    }, [dispatch, currentPage, notificationList.list]);

  useHandleCalls(handleFetchNotification, [], 'notification_list');

  useEffect(() => {
    let callApiInTwoMin = setInterval(() => {
      setCurrentPage(1)
      handleFetchNotification(1);
    }, 180000)
    return () => clearInterval(callApiInTwoMin)
  }, [handleFetchNotification])


  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        if (notificationList.total === currentPage) {
          return;
        }
        let pageNoCount = currentPage + 1
        setCurrentPage(currentPage + 1);
        handleFetchNotification(pageNoCount)
      }
    }
  };

  const handleReadAllMsg = useCallback(() => {
    const dataToSave = {
      callback: (response) => {
        if (response.statusCode === 200) {
          if (response.message) {
            setCurrentPage(1)
            handleFetchNotification(1);
          }
        } else {
          errorAlert(response.data.message);
        }
      }
    };
    dispatch(readAllNotification(dataToSave));
  }, [dispatch, handleFetchNotification]);

  const handleReadNotification = useCallback((notificationId, scheduleNotification) => {
    const dataToSave = {
      notificationId: notificationId,
      callback: (response) => {
        if (response.statusCode === 200) {
          setCurrentPage(1)
          handleFetchNotification(1);
          if (response.data.notificationUrl) {
            if (scheduleNotification) {
              window.open(response?.data?.notificationUrl, '_blank');
            } else {
              window.open(response?.data?.notificationUrl, '_self');
            }
          }
        } else {
          errorAlert(response.data.message);
        }
      }
    };
    dispatch(readNotification(dataToSave));
  }, [dispatch, handleFetchNotification]);

  return <NotificationComponent
    notificationList={notificationList}
    onReadAllMsg={handleReadAllMsg}
    onReadNotification={handleReadNotification}
    onScroll={onScroll}
    listInnerRef={listInnerRef}
    onFetchNotification={handleFetchNotification}
  />;
}

export default Notifications;
