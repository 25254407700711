import { useAppDispatch, useReduxStore } from '../../../../custom-hooks';
import Component from './Component';
import { onToggleModal, setCollectiveDetails } from '../../../../../redux/features/commonSlice';
import { constants } from '../../../../../helpers';

/**
 * ClaimModal component for handling the claim modal functionality.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @returns {JSX.Element} - Rendered ClaimModal component.
 */
function ClaimModal(props) {
    // Retrieve commonReducer and dispatch function from custom hooks
    const { commonReducer } = useReduxStore();
    const dispatch = useAppDispatch();

    /**
     * Handles contributing to another collective.
     *
     * Sets collective details and opens the contribute modal for another collective.
     */
    const handleContributeAnother = () => {
        dispatch(setCollectiveDetails(commonReducer.modal.collective));
        dispatch(onToggleModal({ modalType: constants.MODAL.CONTRIBUTE_ANOTHER_COLLECTIVE }));
    };

    // Render the Component with the necessary props and event handlers
    return <Component {...props} commonReducer={commonReducer} onContributeAnother={handleContributeAnother} />;
}

// Export the ClaimModal component as the default export
export default ClaimModal;
