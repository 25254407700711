import axios from "axios";
import { utils } from "ethers";
import config from "../../../Config";
import { errorAlert } from "../../../utils";
import { messages } from "../../../helpers";

export async function getBasicOrderParameters({
  collectiveCrowdfundAddress,
  contractAddress,
  tokenId,
}) {
  try {
    return new Promise(async (resolve, reject) => {
      // export async function getSeaportListing() {
      // Split the URL by "/"
      // const urlParts = nftUrl?.split("/") || "";

      // Get the last two values from the array
      // const contractAddress = urlParts[urlParts.length - 2];
      // const tokenId = urlParts[urlParts.length - 1];

      const response = await axios.get(
        `${config.OPENSEA_API_URL}/v2/orders/${config["NETWORK_ETHEREUM"]}/seaport/listings?asset_contract_address=${contractAddress}&token_ids=${tokenId}`,
        {
          headers: {
            "X-API-KEY": config.X_API_KEY,
          },
        }
      );
      const order_hash = response?.data?.orders[0]?.order_hash || "";

      if (order_hash) {
        console.log("getBasicOrderParameters-order_hash", order_hash);
        // const delay = ms => new Promise(res => setTimeout(res, ms));
        // await delay(2000);
        // const options = {
        //   method: "POST",
        //   url: "https://testnets-api.opensea.io/v2/listings/fulfillment_data",
        //   // headers: {
        //   //   "content-type": "application/json",
        //   //   "User-Agent": "PostmanRuntime/7.29.3",
        //   //   Connection: "keep-alive",
        //   // },
        //   data: {
        //     listing: {
        //       hash: order_hash,
        //       chain: "goerli",
        //       protocol_address: "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
        //     },
        //     fulfiller: { address: contractAddress },
        //   },
        // };

        // const BasicOrderParameters = await axios
        //   .request(options)
        //   // ethers.utils.formatUnits(call)
        //   .then(function (response) {
        //     return response.data.fulfillment_data.transaction.input_data.parameters;
        //   })
        //   .catch(function (error) {
        //     console.error(error);
        //     // ethers.utils.parseEther("0.00003");
        //   });

        setTimeout(async () => {
          try {
            const BasicOrderParameters = await axios.post(
              `${config.OPENSEA_API_URL}/v2/listings/fulfillment_data`,
              {
                listing: {
                  hash: order_hash,
                  chain: config["NETWORK_ETHEREUM"],
                  protocol_address:
                    "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC",
                },
                fulfiller: { address: collectiveCrowdfundAddress },
              },
              {
                headers: {
                  "X-API-KEY": config.X_API_KEY,
                },
              }
            );

            const price = response?.data?.orders[0]?.current_price || "";
            console.log("getBasicOrderParameters-price", price);

            const fixedGovernanceOpts = {
              voteDuration: utils.parseEther("604800"),
              vetoDuration: utils.parseEther("86400"),
              passThresholdBps: utils.parseEther("8000"),
              sellingPlatform: "Opensea",
              sellingDuration: utils.parseEther("86400"),
              sellingPrice: utils.parseEther("950000000000"),
            };

            console.log(
              "getBasicOrderParameters-response",
              BasicOrderParameters
            );

            resolve({
              BasicOrderParameters:
                BasicOrderParameters?.data?.fulfillment_data?.transaction
                  ?.input_data?.order?.parameters ||
                BasicOrderParameters?.data?.fulfillment_data?.transaction
                  ?.input_data?.parameters,
              price,
              fixedGovernanceOpts,
            });
          } catch (error) {
            console.log("getBasicOrderParameters-setInterval-error", error);
            errorAlert(messages.COLLECTIVE.NFT_NOT_AVAILABLE);
            reject({});
          }
        }, 1000);
      } else {
        errorAlert(messages.COLLECTIVE.NFT_NOT_AVAILABLE);
        reject({});
      }
    });
  } catch (error) {
    console.log("getBasicOrderParameters-error", error);
    errorAlert(messages.COLLECTIVE.NFT_NOT_AVAILABLE);
    return {};
  }
}
