import { Modal } from 'react-bootstrap';
import { truncateToDecimalDigits } from '../../../../../utils';
import { constants } from '../../../../../helpers';

/**
 * Component to display collector information in a modal.
 * @param {boolean} isOpen - Flag indicating if the modal is open.
 * @param {object} collectorDetails - Details of the collector to display.
 * @param {function} onClose - Callback to handle modal close.
 */
function Component({ isOpen, collectorDetails, onClose }) {
    return <Modal className='voter-info-modal' show={isOpen} onHide={onClose}>
        <Modal.Header className='pb10' closeButton>
            <h6>Collector info</h6>
        </Modal.Header>

        <Modal.Body>
            {/* Rendering collector information */}
            <div className='voter-info'>
                <figure><img src={collectorDetails.profilePicture} alt='Profile' /></figure>
                <div className='text'>
                    <p className='lg txt'>{collectorDetails.username}</p>
                    <p className='fs12 text-text'>{collectorDetails.firstContributionTime}</p>
                </div>

                <div className='ml-auto'>
                    <p class="text-purple"><i className='etherium-icon'></i> {truncateToDecimalDigits({
                        number: collectorDetails.contribution,
                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                    })}
                    </p>
                    <p className='fs12 text-text'>{truncateToDecimalDigits({
                        number: collectorDetails.contributionPercentage,
                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                    })}%
                    </p>
                </div>
            </div>

            <div className='voter-info'>
                <div className='text'>
                    <p className='lg txt'>Ownership</p>
                    {collectorDetails.delegatedUsername
                        ? <p className='fs12 text-text'>Delegated to <b className='text-black'>{collectorDetails.delegatedUsername}</b></p>
                        : <p className='fs12 text-text'>Self-delegated</p>
                    }
                </div>

                <div className='ml-auto'>
                    <p class="lg fw500">{truncateToDecimalDigits({
                        number: collectorDetails.contributionPercentage,
                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                    })}%
                    </p>
                </div>
            </div>

            <div className='voter-info'>
                <div className='text'>
                    <p className='lg txt'>Current Voting Power</p>
                    {!!collectorDetails.isSelfDelegated && <p className='fs12 text-text'>No one has delegated to this member yet </p>}
                    {collectorDetails.isSelfDelegated && collectorDetails.votingDelegationCount > 1 && <p className='fs12 text-text'>Received delegate votes from <b className='text-black'>
                        {collectorDetails.votingDelegationCount} {collectorDetails.votingDelegationCount > 1 ? 'members' : 'member'}</b>
                    </p>}
                </div>

                <div className='ml-auto'>
                    <p class="lg fw500">{truncateToDecimalDigits({
                        number: collectorDetails.collector.votingPower,
                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                    })}%</p>
                </div>
            </div>
        </Modal.Body>
    </Modal>;
}

// Export the Component as the default export
export default Component;
