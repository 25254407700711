import config from "../Config";

// Regular expressions for various validation purposes
const regex = {
  // Validate usernames with 8 to 16 characters, including letters, digits, and some special characters
  USERNAME: /^[a-zA-Z0-9@$&#+$!%^&*_+=-~`.]{8,16}$/,

  // Validate passwords with 8 to 16 characters, requiring at least one lowercase letter, one uppercase letter, one digit, and one special character
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()-_+=])[A-Za-z\d@$!%*?&#^()-_+=]{8,16}$/,

  // Validate email addresses using a common pattern
  EMAIL: /^[a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

  // Validate phone numbers with 5 to 15 digits
  PHONE_NUMBER: /^\d{5,15}$/,

  // Validate OpenSea collection URLs
  OPENSEA_COLLECTION_URL: /^https:\/\/opensea\.io\/collection\/[\w-]+$/,

  // Validate testnets OpenSea collection URLs
  TESTNETS_OPENSEA_COLLECTION_URL: config["isProdEnv"]
    ? /^https:\/\/opensea\.io\/collection\/[\w-]+$/
    : /^https:\/\/testnets.opensea\.io\/collection\/[\w-]+$/,

  // Validate OpenSea specific NFT URLs
  OPENSEA_SPECIFIC_NFT_URL: /^(https?:\/\/)?(www\.)?opensea\.io\/assets\/.*$/,

  // Validate testnets OpenSea specific NFT URLs
  TESTNETS_OPENSEA_SPECIFIC_NFT_URL: config["isProdEnv"]
    ? /^(https?:\/\/)?(www\.)?opensea\.io\/assets\/.*$/
    : /^(https?:\/\/)?(www\.)?testnets.opensea\.io\/assets\/.*$/,

  // Validate general URLs (http, https, ftp)
  URL: /^(ftp|http|https):\/\/[^ "]+$/,

  // Validate referral codes with 8 to 16 characters, requiring at least one lowercase letter, one uppercase letter, and one digit
  REFERRAL_CODE: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$&%]{8,16}$/,
};

// Export the regex object for use in other modules
export default regex;
