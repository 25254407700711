import { truncateToDecimalDigits } from "../utils";
import constants from "./constants";

// Messages object containing various error and validation messages
const messages = {
  // Generate a "field is required" message with the provided field name
  IS_REQUIRED: (name) => `${name} Is Required`,

  // Invalid username message
  USERNAME:
    "Invalid Username. Must Contain Between 8 And 16 Characters. Alphanumeric With Special Character Allowed. No Space Allowed.",

  // Invalid phone number message
  PHONE_NUMBER: "Invalid Phone Number. Must Be Between 5 And 15 Digits.",

  // New password and confirm new password do not match message
  CONFIRM_PASSWORD: "New Password And Confirm New Password Does Not Match.",

  // Messages related to the collective
  COLLECTIVE: {
    // Message for exceeding the maximum contribution percentage
    MAX_CONTRIBUTE_PERCENT: ({ contributionGoal }) =>
      `You can not contribute more than ${
        constants.COLLECTIVE.MAX_CONTRIBUTION_PERCENTAGE * 100
      }% (${truncateToDecimalDigits({
        number:
          contributionGoal * constants.COLLECTIVE.MAX_CONTRIBUTION_PERCENTAGE,
        limit: constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION,
      })} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}) of contribution goal`,

    // Message when the collective goal has been reached
    COLLECTIVE_GOAL_REACHED: "Collective Goal Has Been Reached.",

    // Messages related to wallet actions
    WALLET: {
      TRANSACTION_DENIED: "Transaction has been denied",
      CREATE_COLLECTIVE_FAILED: "Create collective has been failed",
      COLLECTIVE_CONTRIBUTION_FAILED: "Collective contribution has been failed",
      VOTING_DELEGATION_FAILED: "Voting delegation has been failed",
      BUY_NFT_FAILED: "Buy NFT has been failed",
    },

    // Message for invalid contribution amount (zero)
    CONTRIBUTION_ZERO_ERROR: "Contribution Should Be Greater Than 0ETH",

    // Message for invalid collection
    INVALID_COLLECTION: "Invalid Collection",

    // Message for supported collection types
    SUPPORTED_COLLECTION: `Only ${constants.COLLECTIVE.SUPPORTED_TYPE} Collections Are Allowed`,

    // Message when the goal has not been reached yet
    GOAL_NOT_REACHED: "Goal Not Reached Yet",

    // Message for missing floor item when buying NFT
    FLOOR_ITEM_MISSING: "Please Add Floor Item to Buy NFT",

    // Message for selling price not meeting minimum requirement
    SELLING_PRICE_MIN:
      "Selling Price Must Be Greater Than Or Equal To Contribution Goal",

    // Message when the requested NFT is not available in OpenSea
    NFT_NOT_AVAILABLE: "NFT Is Not Available Now In The OpenSea",

    // Message for inability to retrieve data from OpenSea
    UNABLE_TO_RETRIEVE_FROM_OPENSEA: "Unable To Retrieve Data From OpenSea",

    COLLECTIVE_URL_COPIED: "Collective URL Copied",

    NFT_VALUE_EXCEEDS_GOAL:
      "Selected NFT Price is Greater than Contributed Goal.",
  },
  DISCORD_USERNAME_COPIED: "Discord Username Copied",
};

// Export the messages object for use in other modules
export default messages;
