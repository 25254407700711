import { constants } from "../helpers";
import { store } from "../redux/store";
import { setDecimalValue, truncateToDecimalDigits } from "./number";

/**
 * Formats a duration in seconds into a human-readable format with days and hours.
 *
 * @param {number} a - The duration in seconds.
 * @param {Object} options - The options object for customizing the output format.
 * @param {string} options.dayText - Text for a single day.
 * @param {string} options.hourText - Text for a single hour.
 * @param {string} options.daysText - Text for multiple days.
 * @param {string} options.hoursText - Text for multiple hours.
 * @param {boolean} options.isGap - Whether to include a gap between units.
 * @returns {string} - The formatted duration.
 */
export function formatVetoPeriod(
  a,
  {
    day: dayText = "Day",
    hour: hourText = "Hour",
    days: daysText = "Days",
    hours: hoursText = "Hours",
    isGap = true,
  } = {
    day: "Day",
    hour: "Hour",
    days: "Days",
    hours: "Hours",
    isGap: true,
  }
) {
  let secs = a * 3600;
  const days = Math.floor(secs / (3600 * 24));
  secs -= days * 3600 * 24;
  const hours = Math.floor(secs / 3600);
  secs -= hours * 3600;

  let dayUnit = days === 1 ? dayText : daysText;
  let hourUnit = hours === 1 ? hourText : hoursText;

  let gapText = "";
  if (isGap) {
    gapText = " ";
  }

  let fullDayWithHours = `${days.toString()}${gapText}${dayUnit} ${
    hours !== 0 ? hours + gapText + hourUnit : ""
  }`;
  return fullDayWithHours;
}

/**
 * Checks if an HTML string is empty (contains no visible content).
 *
 * @param {string} htmlString - The HTML string to check.
 * @returns {boolean} - True if the HTML string is empty, false otherwise.
 */
export function checkEmptyHtml(htmlString) {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(htmlString, "text/html");
  return !parsedHtml.body.textContent.trim();
}

/**
 * Calculates and formats the remaining time between a given timestamp and a period.
 *
 * @param {Object} options - The options object.
 * @param {number} options.created - The initial timestamp.
 * @param {number} options.period - The period in days to be added to the initial timestamp.
 * @param {Object} options.format - The format options for displaying the remaining time.
 * @param {string} options.format.dayText - Text for a single day.
 * @param {string} options.format.hourText - Text for a single hour.
 * @param {string} options.format.minuteText - Text for a single minute.
 * @param {string} options.format.secondText - Text for a single second.
 * @param {string} options.format.daysText - Text for multiple days.
 * @param {string} options.format.hoursText - Text for multiple hours.
 * @param {string} options.format.minutesText - Text for multiple minutes.
 * @param {string} options.format.secondsText - Text for multiple seconds.
 * @param {boolean} options.format.isGap - Whether to include a gap between value and unit.
 * @param {boolean} options.isCompleted - Indicates if the calculation is completed.
 * @returns {string} - The formatted remaining time.
 */
export function calculateRemainingTime({
  created,
  endTimeStamp,
  period,
  format: {
    day: dayText = "Day",
    hour: hourText = "Hour",
    minute: minuteText = "Minute",
    second: secondText = "Second",
    days: daysText = "Days",
    hours: hoursText = "Hours",
    minutes: minutesText = "Minutes",
    seconds: secondsText = "Seconds",
    isGap = true,
  } = {
    day: "Day",
    hour: "Hour",
    days: "Days",
    hours: "Hours",
    minutes: "Mins",
    seconds: "Secs",
    isGap: true,
  },
  isCompleted,
}) {
  if (isCompleted) {
    return "00:00:00";
  }

  if (!created) {
    return "-";
  }

  // Replace these with your timestamps
  const timestamp1 = created; // Example timestamp
  // Convert timestamps to Date objects
  const date1 = new Date(timestamp1);

  // Calculate the end date by adding period days
  const endDate = new Date(date1);
  endDate.setDate(date1.getDate() + parseInt(period));

  // Get the end date timestamp in milliseconds
  const timestamp2 = endDate.getTime();
  const date2 = new Date(endTimeStamp || timestamp2);

  // Get the current date timestamp in milliseconds
  const timestamp3 = new Date().getTime();
  const date3 = new Date(timestamp3);

  // Calculate the time difference in milliseconds
  const timeDifference = date2 - date3;

  // Calculate remaining days, hours, minutes, and seconds
  const totalSeconds = Math.floor(timeDifference / 1000);
  const remainingDays = Math.floor(totalSeconds / 86400);
  let remainingSeconds = totalSeconds % 86400;
  const remainingHours = Math.floor(remainingSeconds / 3600);
  remainingSeconds %= 3600;
  const remainingMinutes = Math.floor(remainingSeconds / 60);
  remainingSeconds %= 60;

  let gapText = "";
  if (isGap) {
    gapText = " ";
  }

  let unit = "";
  let value = 0;

  if (remainingDays > 0) {
    unit = remainingDays === 1 ? dayText : daysText;
    value = remainingDays;
  } else if (remainingHours > 0) {
    unit = remainingHours === 1 ? hourText : hoursText;
    value = remainingHours;
  } else if (remainingMinutes > 0) {
    unit = remainingMinutes === 1 ? minuteText : minutesText;
    value = remainingMinutes;
  } else if (remainingSeconds > 0) {
    unit = remainingSeconds === 1 ? secondText : secondsText;
    value = remainingSeconds;
  }

  const string = `${value}${gapText}${unit}`;

  if (!parseInt(string)) {
    return "00:00:00";
  }

  return string.trim();
}

/**
 * Extracts the collection slug from a URL.
 *
 * @param {Object} options - The options object.
 * @param {string} options.url - The URL containing the collection slug.
 * @returns {string} - The extracted collection slug.
 */
export function getCollectionSlug({ url }) {
  const slug = url?.split("/")?.pop() || "";
  return slug;
}

/**
 * Gets the appropriate text for votes based on the number of votes.
 *
 * @param {number|string} votes - The number of votes.
 * @returns {string} - The appropriate text for votes.
 */
export function getVotesText(votes) {
  return parseInt(votes) > 1 ? "Vote(s)" : "Vote";
}

/**
 * Extracts contract address and token ID details from an NFT URL.
 *
 * @param {Object} options - The options object.
 * @param {string} options.url - The NFT URL containing contract address and token ID.
 * @returns {Object} - An object containing contract address and token ID details.
 */
export function getNftUrlDetails({ url }) {
  const array = url?.split("/") || [];
  return {
    contractAddress: array[array.length - 2] || "",
    tokenId: array.pop() || "",
  };
}

/**
 * Sets and organizes collective details based on input data and goal.
 *
 * @param {Object} options - The options object containing data and goal.
 * @param {Object} options.data - The data object containing collective details.
 * @param {Object} options.details - Additional details to merge (optional).
 * @param {Object} options.goal - The goal object containing goal-related details.
 * @returns {Object} - Organized collective details.
 */
export function setCollectiveDetails({ data, details, goal }) {
  return {
    ...(details || {}),
    collectiveId: data._id,
    name: data.name || "",
    // image:
    //   data.collectiveType === constants.COLLECTIVE.TYPE
    //     ? data.featured_image_url || ""
    //     : data.image || "",
    image: data.image || data.featured_image_url || "",
    collectiveType: data.collectiveType,
    contractAddress: data.contractAddress || "",
    proposal: data.proposal?.[0] || "",
    utilities:
      data.utilityAvailable?.map((utility) => ({ name: utility })) || [],
    userId: data.userId,
    url: data.url,
    title: data.title,
    subTitle: data.subTitle,
    contributionGoal: parseFloat(data.contributionGoal.amount || 0),
    minimumDeposit: parseFloat(data.contributionAmount.amount || 0),
    contribute: parseFloat(data.contributionAmount.amount || 0),
    contributionDuration: parseInt(data.contributionPeriod || 0),
    created: data.created || 0,
    acceptanceThreshold: parseInt(data.governance?.acceptanceThreshold || 0),
    vetoPeriod: parseInt(data.governance?.vetoPeriod || 0),
    proposalExpiration: parseInt(data.governance?.proposalExpiration || 0),
    // utilitySelection: data.utilitySelection,
    platform: data.platform,
    sellPrice: parseFloat(data.sellingPrice?.amount || 0),
    websiteUrl: data.websiteUrl,
    isGoalReached: !!data.isGoalReached,
    status: data.status || "",
    discordId: data.discordId,
    goal: {
      collectors: goal.collectors,
      created: goal.created,
      goal: goal.goal,
      period: goal.period ? parseFloat(goal.period) : 0,
      myContribution: goal.myContribution || 0,
      contributionPercent: goal.contributionPercent || 0,
      votingPower: goal.votingPower || 0,
      myClaim: goal.myClaim || 0,
      securedMyContribution: goal?.securedContribution || 0,
      securedContributionPercent: goal?.securedContributionPercent || 0,
      securedVotingPercentage: goal?.securedVotingPercentage || 0,
    },
    collectiveCloseTiming: data.collective_close_timing || 0,
    sellingDate: data.sellingDate ? new Date(data.sellingDate) : "",
    txHashStatus: data.txHashStatus || "",
  };
}

/**
 * Adds a loader class to a button's className if isLoading is true.
 *
 * @param {Object} options - The options object.
 * @param {string} options.className - The original class name of the button.
 * @param {boolean} options.isLoading - Indicates whether the button is in a loading state.
 * @returns {string} - The modified class name with the loader class if isLoading is true.
 */
export function addButtonLoader({ className, isLoading }) {
  return `${className} ${isLoading ? "spinner" : ""}`;
}

/**
 * Calculates the remaining time for a proposal based on the created timestamp and period.
 *
 * @param {Object} options - The options object.
 * @param {string|number} options.created - The created timestamp of the proposal.
 * @param {number} options.period - The period in days for the proposal.
 * @returns {string|number} - The formatted remaining time for the proposal.
 */
export function calculateRemainingTimeProposal({ created, period }) {
  const currentTime = new Date();
  const createdTime = new Date(created);
  const endDate = new Date(createdTime);
  endDate.setDate(createdTime.getDate() + parseInt(period));

  if (currentTime >= endDate) {
    return 0;
  }

  const timeDifference = endDate - currentTime;
  const seconds = Math.floor((timeDifference / 1000) % 60);
  const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
  const hours = Math.floor((timeDifference / 1000 / 3600) % 24);
  const days = Math.floor(timeDifference / 1000 / 86400);
  var remainingTime = 0;
  if (days > 0) {
    remainingTime = days + " Days " + hours + " Hour";
    return remainingTime;
  } else if (hours > 0) {
    remainingTime = hours + " Hour " + minutes + " Min";
    return remainingTime;
  } else if (minutes > 0) {
    remainingTime = minutes + " Min " + seconds + " Sec";
    return remainingTime;
  } else if (seconds > 1) {
    remainingTime = seconds + " Sec";
    return remainingTime;
  }
}

export function checkStatus(created, period, isGoalReached) {
  // Replace these with your timestamps
  const timestamp1 = created; // Example timestamp
  // Convert timestamps to Date objects
  const date1 = new Date(timestamp1);

  // Calculate the end date by adding period days
  const endDate = new Date(date1);
  endDate.setDate(date1.getDate() + parseInt(period));

  // Get the end date timestamp in milliseconds
  const timestamp2 = endDate.getTime();
  const date2 = new Date(timestamp2);

  // Get the current date timestamp in milliseconds
  const timestamp3 = new Date().getTime();
  const date3 = new Date(timestamp3);

  if (date3 < date2 && isGoalReached) {
    return "Completed";
  }
  return "Active";
}

/**
 * Gets the appropriate text for likes based on the number of likes.
 *
 * @param {number|string} likes - The number of likes.
 * @returns {string} - The appropriate text for likes.
 */
export function getLikesText(likes) {
  return parseInt(likes) > 1 ? "Like(s)" : "Like";
}

export function calculateMinContributionGoal({ collectiveType, details }) {
  let price;
  if (collectiveType === constants.COLLECTIVE.TYPE.COLLECTION) {
    price = details.floorPrice;
  } else {
    price = details.currentPrice;
  }

  const minValue1 = constants.COLLECTIVE.MIN_CONTRIBUTION_GOAL(
    store?.getState()?.auth?.goalDetails
  );

  const minValue2 = setDecimalValue({
    value: price + (price * constants.COLLECTIVE.PLATFORM_FEE_PERCENTAGE) / 100,
  });

  let minValue2Trunc = truncateToDecimalDigits({
    number: minValue2,
    limit: constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION_GOAL,
  });

  if (minValue2 > minValue2Trunc) {
    minValue2Trunc = setDecimalValue({
      value: minValue2Trunc + 0.001,
      limit: constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION_GOAL,
    });
  }

  if (minValue2Trunc && minValue2Trunc >= minValue1) {
    return { totalPrice: minValue2Trunc, price };
  }

  return { totalPrice: minValue1, price };
}

function preciseDivision({ a, b, precision }) {
  const power = Math.pow(10, precision);
  const aInt = a * power;
  const bInt = b * power;
  return parseFloat((aInt / bInt).toFixed(precision));
}

export function calculateCompletionPercent({ totalContribution, amount }) {
  let percent;
  if (totalContribution && amount) {
    percent = truncateToDecimalDigits({
      number:
        preciseDivision({
          a: totalContribution,
          b: amount,
          precision: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE,
        }) * 100,
      limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE,
    });
  }
  return percent;
}

export function getMyContributionValue({
  isNftPurchased,
  myContribution,
  securedMyContribution,
}) {
  // if (isNftPurchased || !securedMyContribution) {
  //   return myContribution;
  // }

  // return securedMyContribution;

  return myContribution;
}

export function getContributionPercent({
  isNftPurchased,
  isNftSold,
  contributionPercent,
  securedContributionPercent,
}) {
  if (isNftPurchased || isNftSold || !securedContributionPercent) {
    return contributionPercent;
  }

  return securedContributionPercent;
}

export function getVotingPercentage({
  isNftPurchased,
  isNftSold,
  votingPower,
  securedVotingPercentage,
}) {
  if (isNftPurchased || isNftSold) {
    return votingPower;
  }

  return securedVotingPercentage;
}

// export function filterDuplicateFloorItems(arr) {
//   const seen = new Set();
//   return arr.filter((item) => {
//     const key = item.contractAddress + "-" + item.tokenId;
//     if (!seen.has(key)) {
//       seen.add(key);
//       return true;
//     }
//     return false;
//   });
// }
