import { useCallback } from "react";
import { postDataApi } from "../../api/methods";
import { constants } from "../../helpers";

/**
 * Custom hook to handle voting for a collective.
 * @param {Object} params - Input parameters for the hook.
 * @param {Object} params.details - Details of the collective.
 * @param {Object} params.item - Information about the item being voted on.
 * @param {Function} params.callback - Callback function to handle the response after voting.
 * @returns {Object} - An object with a function to perform collective voting.
 */
function useCollectiveVoting({ details, item, callback = () => {} }) {
  const onCollectiveVoting = useCallback(
    (vote, nft) => {
      // Perform the API call to add collective voting.
      postDataApi({
        path: constants.API_PATHS.ADD_COLLECTIVE_VOTING,
        data: {
          collectiveId: details.collectiveId,
          contractAddress: nft.contractAddress,
          tokenId: nft.tokenId,
          votes: vote,
          imageUrl: nft.imageUrl,
          name: nft.name,
          amount: nft.amount,
          url: details.url,
          notify: true,
          callback: (response) => {
            // Invoke the provided callback function with the API response.
            callback(response);
          },
        },
      });
    },
    [details, callback]
  );

  return { onCollectiveVoting };
}

export default useCollectiveVoting;
