/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { onSetCanResendOtp, setForgotPasswordDetails } from '../../../../../redux/features/commonSlice';
import { capitalizeFirstLetter } from '../../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../custom-hooks';
import getFormikValidationSchema from '../../../../formik-validations';
import ForgotPasswordComponent from './Component';

function ForgotPassword(props) {
    const dispatch = useAppDispatch();
    const schema = getFormikValidationSchema('forgotPassword');
    const commonReducer = useAppSelector(state => state.common);
    const [email, setEmail] = useState('');

    const handleContinue = useCallback((data, { setSubmitting, setErrors }) => {
        // disabled save changes before trigger api call
        setSubmitting(true);

        dispatch(onSetCanResendOtp({ canResendOtp: false }));

        const request = {
            email: data.email,
            callback: (response) => {
                if (response.statusCode === 200) {
                    props.onOpenModal(constants.MODAL.FORGOT_PASSWORD_VERIFY_EMAIL);
                    dispatch(setForgotPasswordDetails({ email: data.email }));

                    setTimeout(() => {
                        dispatch(onSetCanResendOtp({ canResendOtp: true }));
                    }, constants.RESEND_OTP_TIME_LIMIT.FORGOT_PASSWORD);

                } else if (response.data.type === 'EMAIL_NOT_REGISTERED_YET') {
                    setErrors({ email: "Email Address Is Not Registered" });
                } else if (response?.data?.message) {
                    setErrors({ email: capitalizeFirstLetter(response.data.message) });
                }

                // enabled save changes after api success
                setSubmitting(false);
            }
        };
        postDataApi({ path: constants.API_PATHS.FORGOT_PASSWORD, data: request });
    }, [dispatch, props]);

    useEffect(() => {
        if (commonReducer.modal?.forgotPassword?.email) {
            setEmail(commonReducer.modal.forgotPassword.email);
        }
    }, [commonReducer.modal]);

    return <ForgotPasswordComponent
        {...props}
        schema={schema}
        dispatch={dispatch}
        initialState={{ email }}
        onContinue={handleContinue}
    />;
}

export default ForgotPassword;
