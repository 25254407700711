import axios from "axios";
import { useCallback, useEffect } from "react";
import { getDataApi } from "../../api/methods";
import { constants } from "../../helpers";
import { setGoalDetails } from "../../redux/features/authSlice";
import { setDecimalValue } from "../../utils";
import { useAppDispatch } from "./redux";

function useGetEthPrice() {
  const dispatch = useAppDispatch();

  const handleSetGoalDetails = useCallback(
    (currentPrice) => {
      dispatch(
        setGoalDetails({
          minGoalInUSD: currentPrice,
          minGoalInEth: setDecimalValue({
            value:
              constants.COLLECTIVE.LIMIT_IN_DOLLAR.CONTRIBUTION_GOAL /
              currentPrice,
            limit: constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION_GOAL,
          }),
          minContributionInEth: setDecimalValue({
            value:
              constants.COLLECTIVE.LIMIT_IN_DOLLAR.MIN_CONTRIBUTION /
              currentPrice,
            limit: constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION,
          }),
        })
      );
    },
    [dispatch]
  );

  const fetchingEthereumDetails = useCallback(() => {
    axios
      .get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum"
      )
      .then((response) => {
        const currentPrice = response.data?.[0]?.current_price;

        if (currentPrice) {
          handleSetGoalDetails(currentPrice);
        } else {
          const request = {
            callback: (response) => {
              if (response.statusCode === 200 && response.data.current_price) {
                handleSetGoalDetails(parseFloat(response.data.current_price));
              }
            },
          };
          getDataApi({
            path: constants.API_PATHS.COLLECTIVE_MARKET_PRICE,
            data: request,
          });
        }
      });
  }, [handleSetGoalDetails]);

  useEffect(() => {
    fetchingEthereumDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useGetEthPrice;
