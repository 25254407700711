/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { utils } from 'ethers';
import { Field, Form } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { constants } from '../../../../../../../helpers';
import { CollectionBox } from '../../../../../../../pages/add-collective/modules';
import { checkValidDecimals, formatVetoPeriod, truncateToDecimalDigits } from '../../../../../../../utils';
import CreateCollective from '../../../../../../wallet-transactions/create-collective';
import FormikWrapper from '../../../../../formik-wrapper';
import ValidationMessage from '../../../../../validation-message';
import MinContributionCheckbox from '../../../../../min-contribution-checkbox';

function Component({ isOpen, detail, schema, collectiveDetails, authReducer, onClose, onOpenModal, onSubmit }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={detail}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
        {({ values, errors, isSubmitting, touched, resetForm, handleSubmit, setSubmitting, setFieldValue }) => {
            const isDisabledSubmit = !values.contribute || isSubmitting
                || Object.keys(errors).length;

            const submitBtnProps = {
                _collectiveTitleName: collectiveDetails.title,
                _nftContractAddress: collectiveDetails.details?.contractAddress || '',
                _nftTokenId: collectiveDetails.details?.tokenId || '', // need to add in case of single nft
                _fundraiseGoal: collectiveDetails.contributionGoal ? utils.parseEther(String(collectiveDetails.contributionGoal)).toString() : collectiveDetails.contributionGoal,
                _crowdFundDuration: collectiveDetails.contributionPeriod * 86400, // convert to secs
                _lowerLimitInvestment: collectiveDetails.contributionGoal
                    ? utils.parseEther(String(constants.COLLECTIVE.MIN_CONTRIBUTION_AMOUNT({
                        val: collectiveDetails.contributionGoal,
                        goalDetails: authReducer?.goalDetails
                    }))).toString()
                    : collectiveDetails.contributionGoal,// need to add parse ethers
                _upperLimitInvestment: collectiveDetails.contributionGoal
                    ? utils.parseEther(String(constants.COLLECTIVE.MAX_CONTRIBUTION_AMOUNT({
                        val: collectiveDetails.contributionGoal,
                        goalDetails: authReducer?.goalDetails
                    }))).toString()
                    : collectiveDetails.contributionGoal,// need to add parse ethers
                _initialContributor: authReducer.wallet?.address || '', // wallet address
                _initialContribution: values.contribute ? utils.parseEther(String(values.contribute)).toString() : values.contribute, // need to add parse ethers
                _voteDuration: parseInt(collectiveDetails.proposalExpiration) * 86400, // convert to secs
                _vetoDuration: collectiveDetails.vetoPeriod * 3600,// convert to secs
                _passThresholdBps: collectiveDetails.acceptanceThreshold * 100, // multiple by 100
                // _sellingPlatform: collectiveDetails.platform,
                // _sellingDuration: collectiveDetails.sellingDate,
                // _sellingPrice: collectiveDetails.sellingPrice,
                isCollectiveType: collectiveDetails.collectiveType === constants.COLLECTIVE.TYPE.COLLECTION,
                isDisabledSubmit,
                data: { ...collectiveDetails, ...values },
                userId: authReducer.userId,
                resetForm,
                categories: collectiveDetails.categories,
                isSubmitting,
                // setCategories: onSelectCategory,
                setCategories: () => { },
                onIsSubmitting: setSubmitting,
                onSubmit: handleSubmit,
            };

            const minContributionAmount = constants.COLLECTIVE.MIN_CONTRIBUTION_AMOUNT({
                val: values.contributionGoal,
                isBigNumber: true,
                goalDetails: authReducer?.goalDetails
            });
            const maxContributionAmount = constants.COLLECTIVE.MAX_CONTRIBUTION_AMOUNT({
                val: values.contributionGoal,
                goalDetails: authReducer?.goalDetails
            });


            const fundraiseGoal = truncateToDecimalDigits({
                number: collectiveDetails.contributionGoal,
                limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
            });

            return <Form>
                <Modal className='collective-preview' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        {/* <div className='collect-card'>
                            <figure><img src={collectiveDetails.details?.image || CollectivePlaceholder} alt='PorfileBanner' /></figure>

                            <div className='text'>
                                <figure><img src={collectiveDetails.details?.profile_image || ProfilePlaceholder} alt='Profile' /></figure>

                                <h6 className='f20'>{collectiveDetails.details?.name || '-'}</h6>

                                <div className='d-flex'>
                                    <div>
                                        <p className='lg text-text m4'>Collection Size</p>
                                        <h6>
                                            {abbreviateNumber(collectiveDetails.details?.collectionSize) || '-'}
                                        </h6>
                                    </div>
                                    <div>
                                        <p className='lg text-text m4'>Floor Price</p>
                                        <h6>{collectiveDetails.details?.floorPrice ? setDecimalValue({ value: collectiveDetails.details.floorPrice, formatted: true }) : '-'} {collectiveDetails.details.floorPrice && constants.COLLECTIVE.PRICE.SYMBOL.ETH}</h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <CollectionBox values={collectiveDetails} />
                    </Modal.Header>

                    <Modal.Body>
                        <div className='row row-cols-lg-2'>
                            <div className=''>
                                <p className='m4'>Collective Title</p>
                                <p className='lg text-text'>{collectiveDetails.title || '-'}</p>
                            </div>

                            <div className=''>
                                <p className='m4'>Project Website</p>
                                <p className='lg text-text'>{collectiveDetails.websiteUrl || '-'}</p>
                            </div>

                            <div className=''>
                                <p className='m4'>Fundraise Goal</p>
                                <p className='lg fw600'><i className='etherium-icon lg'></i>
                                    {fundraiseGoal} {constants.COLLECTIVE.PRICE.SYMBOL.ETH}
                                </p>
                            </div>

                            <div className=''>
                                <p className='m4'>Fund Duration</p>
                                <p className='lg text-text'>{collectiveDetails.contributionPeriod || '-'} Days</p>
                            </div>

                            <div className=''>
                                <p className='m4'>NFT Category</p>
                                <p className='lg text-text'>{collectiveDetails.categories.filter(cat => !!cat.isChecked).map(cat => cat.name).join(', ')}</p>
                            </div>

                            <div className=''>
                                <p className='m4'>Acceptance Threshold</p>
                                <p className='lg text-text'>{collectiveDetails.acceptanceThreshold}%</p>
                            </div>

                            <div className=''>
                                <p className='m4'>Proposal Expiration</p>
                                <p className='lg text-text'>
                                    {formatVetoPeriod(parseFloat(collectiveDetails.proposalExpiration || 0) * 24)}
                                </p>
                            </div>

                            <div className=''>
                                <p className='m4'>Veto Period</p>
                                <p className='lg text-text'>
                                    {formatVetoPeriod(collectiveDetails.vetoPeriod)}
                                </p>
                            </div>
                        </div>

                        <div className=''>
                            <h6>Contribute</h6>
                            <p className='m20 text-text'>
                                Add your contribution to continue your collection creation
                            </p>

                            <div className='eth-input'>
                                <h5>{constants.COLLECTIVE.PRICE.SYMBOL.ETH}</h5>
                                <Field
                                    type='number'
                                    name='contribute'
                                    placeholder={`Minimum ${minContributionAmount} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`}
                                    min={minContributionAmount}
                                    max={maxContributionAmount}
                                    onChange={(event) => {
                                        if (checkValidDecimals({ event }) && !isNaN(event.target.value)) {
                                            setFieldValue('contribute', event.target.value.trim());
                                        }
                                    }}
                                />
                            </div>
                            {!!(errors?.['contribute'] && touched?.['contribute']) && <ValidationMessage message={errors['contribute']} />}

                            {/* <div className='checkbox'><input type="checkbox" /><label>Use Gas Credits <span className='fw400 text-text ml6'>(Available Credits: 1)</span></label></div> */}
                        </div>

                        <div className='checkbox'>
                            <input
                                type="checkbox"
                                checked
                            />
                            <MinContributionCheckbox value={collectiveDetails.contributionAmount} />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <CreateCollective {...submitBtnProps} />
                    </Modal.Footer>

                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default Component;
