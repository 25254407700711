import { useCallback } from 'react';
import Component from './Component';
import { useAppDispatch, useReduxStore } from '../../../../custom-hooks';
import { errorAlert, successAlert } from '../../../../../utils';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { onToggleModal, setRefreshVotingandCommentDetails } from '../../../../../redux/features/commonSlice';

function VetoExpireAlert(props) {
    const { commonReducer } = useReduxStore();
    const dispatch =useAppDispatch();


    // Handle Veto Period Expire
    const handleVetoExpire = useCallback(
        () => {
            const query = {
                proposalId: commonReducer.modal.proposal?.proposalId,
                type: commonReducer.modal.proposal?.type,
                callback: (response) => {
                    if (response?.statusCode === 201) {
                        successAlert(response?.message);
                        dispatch(onToggleModal());
                        dispatch(setRefreshVotingandCommentDetails(true));
                        setTimeout(() => {
                            dispatch(setRefreshVotingandCommentDetails(false));
                        }, 1000);
                    } else {
                        errorAlert(response?.data?.message);
                    }
                }
            };
            postDataApi({ path: constants.API_PATHS.VETO_EXPIRE, data: query });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [commonReducer,dispatch]
    );

    return <Component {...props} onVetoExpire={handleVetoExpire} />;
}

export default VetoExpireAlert;
