import { useCallback, useState } from 'react';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { setUpdatedEmail } from '../../../../../redux/features/authSlice';
import { onSetCanResendOtp, onToggleModal } from '../../../../../redux/features/commonSlice';
import { verifyOtp } from '../../../../../redux/services/auth';
import { successAlert } from '../../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../custom-hooks';
import Component from './Component';

function VerifyUpdatedEmail(props) {
    const dispatch = useAppDispatch();
    const { auth: authReducer, common: commonReducer } = useAppSelector(state => state);

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');

    const handleOtpChange = useCallback((value) => {
        // Only allow numbers
        const regex = /^[0-9\b]+$/;
        if (regex.test(value) || value === '') {
            setOtp(value);
            if (otpError !== '') {
                setOtpError('');
            }
        }
    }, [otpError]);

    const handleVerifyOtp = useCallback(() => {
        if (!(authReducer?.email)) return false;

        const data = {
            type: constants.API_TYPE.PROFILE_UPDATION,
            email: authReducer.email,
            otp,
            callback: (response) => {
                if (response.statusCode === 200) {
                    setOtpError("");
                    dispatch(onToggleModal());
                    dispatch(setUpdatedEmail({ email: commonReducer.modal?.updateEmail?.email || '' }));
                    props.onForceReRender();

                    if (response.message) {
                        successAlert(response.message);
                    }
                } else if (response.data.type === "INVALID_OTP") {
                    setOtpError(response.data.message);
                } else if (response.data.type === "OTP_EXPIRED") {
                    setOtpError(response.data.message);
                    dispatch(onSetCanResendOtp({ canResendOtp: true }));
                } else {
                    setOtpError(response.message);
                }
            }
        };
        dispatch(verifyOtp(data));
    }, [otp, dispatch, authReducer, props, commonReducer]);

    const handleResendOtp = useCallback(() => {
        if (!(commonReducer.modal?.updateEmail?.email) || !(commonReducer.modal?.canResendOtp)) return false;

        dispatch(onSetCanResendOtp({ canResendOtp: false }));

        const data = {
            type: constants.API_TYPE.EMAIL,
            email: commonReducer.modal.updateEmail.email,
            notify: true,
            callback: () => {
                setTimeout(() => {
                    dispatch(onSetCanResendOtp({ canResendOtp: true }));
                },
                    constants.RESEND_OTP_TIME_LIMIT.UPDATE_EMAIL
                );
            }
        };
        postDataApi({ path: constants.API_PATHS.USER_EMAIL_SEND_OTP, data });
    }, [commonReducer.modal, dispatch]);

    return <Component
        {...props}
        otp={otp}
        canResendOtp={!!(commonReducer.modal?.canResendOtp)}
        email={commonReducer.modal?.updateEmail?.email || ''}
        otpError={otpError}
        actionType={commonReducer.actionType}
        onSetOtp={handleOtpChange}
        onVerifyOtp={handleVerifyOtp}
        onResendOtp={handleResendOtp}
    />;
}

export default VerifyUpdatedEmail;
