import { useCallback, useEffect, useState } from 'react';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { onSetCanResendOtp, setUpdateEmailDetails } from '../../../../../redux/features/commonSlice';
import { useAppDispatch, useAppSelector } from '../../../../custom-hooks';
import getFormikValidationSchema from '../../../../formik-validations';
import UpdateEmailComponent from './Component';

function UpdateEmail(props) {
    const dispatch = useAppDispatch();
    const schema = getFormikValidationSchema('updateEmail');
    const { auth: authReducer, common: commonReducer } = useAppSelector(state => state);
    const [email, setEmail] = useState('');

    const handleSubmit = useCallback((data, { setSubmitting, setErrors }) => {
        if (authReducer.email === data.email) {
            setErrors({ email: 'Similar Email Is Not Allowed.' });
            return false;
        }

        // disabled save changes before trigger api call
        setSubmitting(true);

        dispatch(onSetCanResendOtp({ canResendOtp: false }));

        const request = {
            type: constants.API_TYPE.EMAIL,
            email: data.email,
            callback: (response) => {
                if (response.statusCode === 200) {
                    dispatch(setUpdateEmailDetails({ email: data.email }));
                    props.onOpenModal(constants.MODAL.VERIFY_UPDATED_EMAIL);
                    props.onForceReRender();

                    setTimeout(() => {
                        dispatch(onSetCanResendOtp({ canResendOtp: true }));
                    }, constants.RESEND_OTP_TIME_LIMIT.UPDATE_EMAIL);
                } else {
                    setErrors({ email: 'Email Address Already Registered' });
                }

                // enabled save changes after api success
                setSubmitting(false);
            }
        };
        postDataApi({ path: constants.API_PATHS.USER_EMAIL_SEND_OTP, data: request });
    }, [dispatch, props, authReducer]);

    const handleOnClose = useCallback(() => {
        props.onClose();
        props.onForceReRender();
    }, [props]);

    useEffect(() => {
        if (commonReducer.modal.updateEmail.email) {
            setEmail(commonReducer.modal.updateEmail.email);
        }
    }, [commonReducer.modal.updateEmail.email]);

    return <UpdateEmailComponent
        {...props}
        schema={schema}
        dispatch={dispatch}
        initialState={{ email }}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
    />;
}

export default UpdateEmail;
