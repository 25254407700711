import axiosInstance, { customBasicAuthAxios } from ".";
import { constants } from "../helpers";
import { setIsLoader, setIsTableLoader } from "../redux/features/commonSlice";
import { store } from "../redux/store";
import { errorAlert, successAlert } from "../utils";

/**
 * @function handleOnSetLoader: Handle loaders
 * @param {Boolean} isLoading Loader On/Off
 * @param {Object} payload API request
 */
function handleOnSetLoader(isLoading, payload = {}) {
  const { isLoading: loading = true, loaderType } = payload;
  if (loading) {
    if (loaderType === constants.LOADER.TYPE.TABLE) {
      store.dispatch(setIsTableLoader(isLoading));
    } else {
      store.dispatch(setIsLoader(isLoading));
    }
  }
}

/**
 * @function handleApiThen: Handle API success response
 * @param {Object} response API response
 * @param {Object} payload API request
 */
function handleApiThen(response, payload) {
  handleOnSetLoader(false, payload);
  if (payload.callback) {
    payload.callback(response.data);
  }
  if (payload.notify && response.data?.message) {
    successAlert(response.data.message);
  }
}

/**
 * @function handleApiCatch: Handle API failure response
 * @param {Object} error API error response
 * @param {Object} data API request data
 */
function handleApiCatch(error, data) {
  handleOnSetLoader(false, data);
  if (data.notify && [404, 400].includes(error.response?.data?.statusCode)) {
    errorAlert(error.response.data.message);
  } else if (data.notify && error.message) {
    errorAlert(error.message);
  }
  if (data.callback) {
    data.callback(error.response);
  }
}

/**
 * @function getApiRequest: Set API request
 * @param {Object} data API request data
 */
function getApiRequest(data) {
  const req = JSON.parse(JSON.stringify(data));
  delete req.notify;
  delete req.callback;
  delete req.loaderType;
  delete req.isLoading;

  return req;
}

/**
 * @function getApiUrl: Set API request
 * @param {Object} data API request configuration
 */
function getApiUrl(data) {
  return `${data.baseUrl}/${data.path}`;
}

/**
 * @function To call get API's
 * @param param0 get data
 * @returns api response on success or error on fail
 */
export const getDataApi = ({
  baseUrl = "",
  path = "no-path-provided",
  data = {},
}) => {
  try {
    handleOnSetLoader(true, data);
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(getApiUrl({ path, baseUrl }), {
          params: getApiRequest(data),
        })
        .then((response) => {
          handleApiThen(response, data);
          resolve(response.data);
        })
        .catch((error) => {
          handleApiCatch(error, data);
          resolve(error);
        });
    });
  } catch (error) {
    handleApiCatch(error, data);
    return error.message;
  }
};

/**
 * @function To call post API's
 * @param param0 post data
 * @returns api response on success or error on fail
 */
export const postDataApi = ({
  baseUrl = "",
  path = "no-path-provided",
  data = {},
}) => {
  try {
    handleOnSetLoader(true, data);
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(getApiUrl({ path, baseUrl }), getApiRequest(data))
        .then((response) => {
          handleApiThen(response, data);
          resolve(response.data);
        })
        .catch((error) => {
          handleApiCatch(error, data);
          resolve(error);
        });
    });
  } catch (error) {
    handleApiCatch(error, data);
    return error.message;
  }
};

/**
 * @function To call put API's
 * @param param0 put data
 * @returns api response on success or error on fail
 */
export const putDataApi = ({
  baseUrl = "",
  path = "no-path-provided",
  data = {},
}) => {
  try {
    handleOnSetLoader(true, data);
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(getApiUrl({ path, baseUrl }), getApiRequest(data))
        .then((response) => {
          handleApiThen(response, data);
          resolve(response.data);
        })
        .catch((error) => {
          handleApiCatch(error, data);
          resolve(error);
        });
    });
  } catch (error) {
    handleApiCatch(error, data);
    return error.message;
  }
};

/**
 * @function To call delete API's
 * @param param0 delete data
 * @returns api response on success or error on fail
 */
export const deleteDataApi = ({
  baseUrl = "",
  path = "no-path-provided",
  data = {},
}) => {
  try {
    handleOnSetLoader(true, data);
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(getApiUrl({ path, baseUrl }), getApiRequest(data))
        .then((response) => {
          handleApiThen(response, data);
          resolve(response.data);
        })
        .catch((error) => {
          handleApiCatch(error, data);
          resolve(error);
        });
    });
  } catch (error) {
    handleApiCatch(error, data);
    return error.message;
  }
};

/**
 * @function To call get basic auth API's
 * @param param0 get data
 * @returns api response on success or error on fail
 */
export const getBasicAuthDataApi = ({
  baseUrl = "",
  path = "no-path-provided",
  data = {},
}) => {
  try {
    handleOnSetLoader(true, data);
    return new Promise((resolve, reject) => {
      customBasicAuthAxios
        .get(getApiUrl({ path, baseUrl }), {
          params: getApiRequest(data),
        })
        .then((response) => {
          handleApiThen(response, data);
          resolve(response.data);
        })
        .catch((error) => {
          handleApiCatch(error, data);
          resolve(error);
        });
    });
  } catch (error) {
    handleApiCatch(error, data);
    return error.message;
  }
};



export const postBasicAuthDataApi = ({
  baseUrl = "",
  path = "no-path-provided",
  data = {},
}) => {
  try {
    handleOnSetLoader(true, data);
    return new Promise((resolve, reject) => {
      customBasicAuthAxios
        .post(getApiUrl({ path, baseUrl }), getApiRequest(data))
        .then((response) => {
          handleApiThen(response, data);
          resolve(response.data);
        })
        .catch((error) => {
          handleApiCatch(error, data);
          resolve(error);
        });
    });
  } catch (error) {
    handleApiCatch(error, data);
    return error.message;
  }
};
