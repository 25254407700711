/* 
* remove class 'text-red' on run time when the user is filling the password field
*/

function PasswordValidationView({ name, values, errors, touched }) {

    const isValidStep1 = values[name].length >= 8 && values[name].length <= 16;
    const isValidStep2 = /(?=.*\d)(?=.*[!@#$%^&*])/.test(values[name]);
    const isValidStep3 = /(?=.*[a-z])(?=.*[A-Z])/.test(values[name]);

    const isValidAll = isValidStep1 && isValidStep2 && isValidStep3;

    if (errors[name] && touched[name] && !isValidAll) {
        return <div className='password-info'>
            <p>Password Must Contain</p>
            <ul>
                <li className={`${isValidStep1 ? "" : 'text-red'}`}>Minimum 8 and maximum 16 characters</li>
                <li className={`${isValidStep2 ? '' : 'text-red'}`}>Atleast one special and one numeric character</li>
                <li className={`${isValidStep3 ? '' : 'text-red'}`}>Atleast one uppercase and one lowercase character</li>
            </ul>
        </div>;
    }

    return null;
}

export default PasswordValidationView;
