import { InputField } from '../../../../common/components';
import { useWebsitePreview } from '../../../../common/custom-hooks';
import { regex } from '../../../../helpers';

function WebsitePreview({ values, errors, touched }) {
    const { metadata } = useWebsitePreview(values.websiteUrl);

    const isValidUrl = regex.URL.test(values.websiteUrl);

    return <>
        <InputField
            name='websiteUrl'
            wrapperClass='collect-in'
            labelClass='lg m6'
            inputWrapperClass='form-in'
            label="Project Website (For Example : https://www.pixelmon.ai)"
            labelConfig={{ inPTag: true }}
            placeholder='Enter Project Website'
            errors={errors}
            touched={touched}
        />

        {values.websiteUrl && isValidUrl &&
            <div className='collect-in'>
                <p className='lg m6'>Website Preview</p>

                <div className='web-preview'>
                    <figure><img src={metadata.image} alt={metadata.title} /></figure>
                    <h6>{metadata.title}</h6>
                    <p>{metadata.description}</p>

                    <button
                        className={`btn-white icon ${values.websiteUrl ? "" : "disabled"}`}
                        disabled={!values.websiteUrl}
                        onClick={() => {
                            values.websiteUrl && window.open(values.websiteUrl, '_blank');
                        }}
                    >
                        <i className='outer-orange-icon mr4'>
                        </i>
                        <span>WEBSITE</span>
                    </button>
                </div>
            </div>
        }
    </>;
}

export default WebsitePreview;
