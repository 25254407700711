import BuyNft from '../../../common/wallet-transactions/buy-nft';
import { constants } from '../../../helpers';

function BuyNowWalletButton({ isCollectionType, submitProps, buttonConfig, ...rest }) {
    return <BuyNft {...rest} {...submitProps} buttonConfig={buttonConfig} isCollectionType={isCollectionType} />;
}

function Component(props) {

    const { buttonConfig, details, isLeader, isGoalCompleted, isCollectionType, isCollectiveDetailPage, submitProps, isSpecificNftCoLeader,
        // buyNftList,
        onProceedToBuyNft } = props;

    if (isCollectiveDetailPage) {

        if ((isLeader || isSpecificNftCoLeader) &&
            isGoalCompleted &&
            details.status !== constants.COLLECTIVE.STATUS.ACQUIRED
        ) {

            // if (isCollectionType && (buyNftList?.length > 1 || !buyNftList?.length)) {
            if (isCollectionType) {
                return <div>
                    <button
                        className={buttonConfig.className}
                        disabled={submitProps.isDisabledSubmit}
                        onClick={onProceedToBuyNft}
                    >
                        {buttonConfig.title}
                    </button>
                </div>;
            }

            return <BuyNowWalletButton {...props} />;
        }

        return null;

    }

    return <BuyNowWalletButton {...props} />;
}

export default Component;
