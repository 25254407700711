import { Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { Logo } from '../../../../../assets/images/images';
import { constants } from '../../../../../helpers';
import FormikWrapper from '../../../formik-wrapper';
import ValidationMessage from '../../../validation-message';

function Component({ isOpen, email, onClose, onSubmit, onOpenModal }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={{ email }}
        onSubmit={onSubmit}
    >
        {({ errors, handleSubmit }) => {
            return <Form>
                <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        <figure><img src={Logo} alt="Logo" /></figure>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className='m6'>Send Verification Email</h5>
                        <p className='m20 text-text'>We will send a verification email to the below associated Email address for your account</p>
                        <div className='email-verify'>
                            <i className='mail-icon'></i> <p>{email}</p>
                        </div>
                        {errors.email && <ValidationMessage message={errors.email} />}
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btns'>
                            <button className='btn-white lg back-modal' onClick={() => onOpenModal(constants.MODAL.FORGOT_PASSWORD)}>
                                Back
                            </button>
                            <button className='btn lg' onClick={handleSubmit}>
                                Send
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default Component;
