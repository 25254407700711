import { Modal } from 'react-bootstrap';
import { GreenCheck } from '../../../../../assets/images/images';

/**
 * Component for displaying success message after adding a vote.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {boolean} props.isOpen - Indicates whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal close.
 * @returns {JSX.Element} - Rendered Component.
 */
function Component({ isOpen, onClose }) {
    return (
        <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header >
                {/* Modal header content */}
                <figure><img src={GreenCheck} alt="Logo" /></figure>
            </Modal.Header>

            <Modal.Body className='text-center'>
                {/* Modal body content */}
                <h5 className='m6'>Vote Added</h5>
                <p className='m10 text-text'>Vote Added Successfully</p>
            </Modal.Body>
        </Modal>
    );
}

// Export the Component as the default export
export default Component;
