/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { Logo } from '../../../../../assets/images/images';
import { constants } from '../../../../../helpers';
// import { useAppDispatch, useAppNavigate } from '../../../../custom-hooks';
import getFormikValidationSchema from '../../../../formik-validations';
// import FormikWrapper from '../../../formik-wrapper';
import InputField from '../../../input-field';
// Google Login
import { handleGoogleLogin } from '../sign-up/googleLogin';
import {
    LoginSocialGoogle
} from 'reactjs-social-login'
//import { useCallback } from 'react';
import axios from 'axios';
import { useAppDispatch, useAppNavigate, useRefreshPage } from '../../../../custom-hooks';
import config from '../../../../../Config';

function LoginComponent({ isOpen, commonReducer, detail, onClose, onSubmit, onOpenModal, deviceId }) {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { onRefreshViewCollectivePage } = useRefreshPage();

    // const onLoginStart = useCallback(() => {
    //     alert('login start')
    // }, [])

    const loginSuccessHandle = async (provider, data) => {
        if (data) {
            const response = await axios.get(
                `${config.GOOGLE_LOGIN_USER_INFO}?access_token=${data.access_token}`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            // console.log(response.data, "RRRRR")
            let res = response.data
            handleGoogleLogin(dispatch,onRefreshViewCollectivePage, deviceId, commonReducer, navigate, res)
        }
    }

    return (
        <>
            <Formik
                enableReinitialize
                initialValues={detail}
                validationSchema={getFormikValidationSchema('login')}
                onSubmit={onSubmit}
            >
                {({ values, errors, isSubmitting, touched, setFieldValue, setSubmitting, setErrors, handleSubmit }) => {
                    const isDisabledSubmit = !(
                        values.email &&
                        values.password
                    ) || isSubmitting;

                    return <Form>
                        <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
                            <Modal.Header closeButton>
                                <figure><img src={Logo} alt="Logo" /></figure>
                            </Modal.Header>

                            <Modal.Body>
                                <h5 className='m6'>Login</h5>
                                <p className='m20 text-black'>Enter your details to continue.</p>

                                <InputField
                                    name='email'
                                    label='Username or Email'
                                    required
                                    placeholder="Enter Username or Email Address"
                                    errors={errors}
                                    touched={touched}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !isDisabledSubmit) {
                                            e.preventDefault(); // Prevent form submission
                                            onSubmit(values, { setSubmitting, setErrors }); // Manually trigger form submission
                                        }
                                    }}
                                />

                                <InputField
                                    type={values.isShowingPassword ? 'text' : 'password'}
                                    name='password'
                                    label='Password'
                                    required
                                    placeholder="Enter password"
                                    wrapperClass='form-in right-icon'
                                    errors={errors}
                                    touched={touched}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !isDisabledSubmit) {
                                            e.preventDefault(); // Prevent form submission
                                            onSubmit(values, { setSubmitting, setErrors }); // Manually trigger form submission
                                        }
                                    }}
                                >
                                    <span
                                        className='icon-right'
                                        onClick={() => setFieldValue('isShowingPassword', !values.isShowingPassword)}
                                    >
                                        <i className={values.isShowingPassword ? 'eye-closed-icon' : "eye-icon"}></i>
                                    </span>
                                </InputField>

                                <div className='text-end'>
                                    <a href='#' className='text-black fs14' onClick={() => onOpenModal(constants.MODAL.FORGOT_PASSWORD)}>Forgot Password</a>
                                </div>
                            </Modal.Body>

                            <Modal.Footer>
                                <div className='w-100'>
                                    <Button className='btn lg w-100' disabled={isDisabledSubmit} onClick={handleSubmit}>
                                        Sign In
                                    </Button>
                                    <br />

                                    <LoginSocialGoogle
                                        // client_id={'217348099499-cet4nrisgkjtn8paqt4d0gvac6uftcs5.apps.googleusercontent.com'}
                                        client_id={config.GOOGLE_CLIENT_ID}
                                        // onLoginStart={onLoginStart}
                                        onResolve={({ provider, data }) => {
                                            loginSuccessHandle(provider, data)
                                        }}
                                        onReject={(err) => {
                                            console.log(err)
                                        }}
                                        cookie_policy='single_host_origin'
                                        scope='openid profile email'
                                    >
                                        <button
                                            className='btn-white lg w-100 mt12'
                                        // disabled={isDisabledSubmit}
                                        >
                                            <i className='google-icon mr10'></i>
                                            Sign in with Google
                                        </button>
                                    </LoginSocialGoogle>

                                </div>

                                {commonReducer?.modal?.referral?.code &&
                                    <p className='mt16 text-text'>
                                        Don’t have an account ?
                                        <a href='#'
                                            className='link'
                                            onClick={() => onOpenModal(constants.MODAL.SIGN_UP)}
                                        >
                                            Sign Up
                                        </a>
                                    </p>
                                }
                            </Modal.Footer>
                        </Modal>
                    </Form>;
                }}
            </Formik>;

        </>
    )
}

export default LoginComponent;
