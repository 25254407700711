/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useState } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { setWalletDetails } from '../../../../../redux/features/authSlice';
import { errorAlert } from '../../../../../utils';
import { useAppDispatch, useAppNavigate, useAppSelector } from '../../../../custom-hooks';
import Component from './Component';
import config from '../../../../../Config';

function Wallet(props) {
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();
    const authReducer = useAppSelector(state => state.auth);
    const [selectedWallet, setSelectedWallet] = useState('');
    const [isFetched, setIsFetched] = useState(false);
    const [web3Modal, setWeb3Modal] = useState(false);

    const handleConnectWeb3 = async () => {
        if (!!(authReducer.wallet?.isConnect)) {
            errorAlert('Wallet Already Connected');
            return false;
        }

        const providerOptions = {
            // Add provider options based on the wallets you want to support
        };

        const web3Modal = new Web3Modal({
            network: config["NETWORK"], // Specify the Ethereum network you want to connect to
            cacheProvider: true, // Cache the provider to improve performance
            providerOptions, // Pass the provider options
        });
        setWeb3Modal(web3Modal);

        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);

        // Use the web3 instance to interact with the Ethereum network
        // For example, you can get the user's address with: const accounts = await web3.eth.getAccounts();

        // Remember to handle provider disconnection and cleanup
        // web3Modal.on('disconnect', () => {
        //     web3Modal.clearCachedProvider();
        //     // Add any additional cleanup steps you need
        // });

        const accounts = await web3.eth.getAccounts(); // Get the connected accounts

        // setAddress(accounts[0]); // Set the user's address

        // const balance = await web3.eth.getBalance(accounts[0]); // Get the account balance
        // setBalance(web3.utils.fromWei(balance, 'ether')); // Convert balance to Ether and set it

        if (accounts[0]) {
            postDataApi({
                path: constants.API_PATHS.CONNECT_WALLET,
                data: {
                    "walletAddress": accounts[0],
                    notify: true,
                    callback: (response) => {
                        if (response) {
                            dispatch(setWalletDetails({
                                isConnect: true,
                                address: accounts[0],
                            }));
                            props.onClose();
                            navigate(constants.BROWSER_ROUTES.WALLETS);
                        }
                    }
                }
            });
        }
    };

    const handleDisconnectWeb3 = useCallback(() => {
        // Remember to handle provider disconnection and cleanup
        if (web3Modal) {
            web3Modal.on('disconnect', () => {
                web3Modal.clearCachedProvider();
                // Add any additional cleanup steps you need
            });
        }
    }, [web3Modal]);

    const handleSelectedWallet = useCallback((name = '') => {
        setSelectedWallet(name);
    }, []);

    const handleIsFetched = useCallback((value) => {
        setIsFetched(!!value);
    }, []);

    const handleOnClose = useCallback(() => {
        props.onClose();
        navigate(constants.BROWSER_ROUTES.DISCOVER);
        handleDisconnectWeb3();
    }, [props, navigate, handleDisconnectWeb3]);

    return <Component
        {...props}
        selectedWallet={selectedWallet}
        isFetched={isFetched}
        onSetSelectedWallet={handleSelectedWallet}
        onSetFetched={handleIsFetched}
        onConnectWeb3={handleConnectWeb3}
        onDisconnectWeb3={handleDisconnectWeb3}
        onClose={handleOnClose}
    />;
}

export default Wallet;
