import Tooltip from 'react-bootstrap/Tooltip';

export const renderTooltip = (props) => {
    // let count = ""

    // //Sometimes, props.popper.state is undefined. 
    // //It runs this function enough times that state gets a value 
    // if (props.popper.state) {
    //     count = props.popper.state.options.count
    // }

    return(
    <Tooltip id="button-tooltip" {...props}>
      The number of rewards available
    </Tooltip>
  )};