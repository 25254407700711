// Import necessary functions from the "firebase/app" and "firebase/auth" libraries
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";

// Import the configuration object from the "Config" file
import config from "../Config";

// Define the Firebase configuration object using values from the imported config object
const firebaseConfig1 = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASURMENT_ID,
};

// const firebaseConfig2 = {
//   apiKey: "AIzaSyCyBNf4HK1PTdUPBhk7hqNIzLI3fF5bKuw",
//   authDomain: "mymetafi-1a56b.firebaseapp.com",
//   projectId: "mymetafi-1a56b",
//   storageBucket: "mymetafi-1a56b.appspot.com",
//   messagingSenderId: "450649673635",
//   appId: "1:450649673635:web:479b7bc50e5fac61a31416",
// };

// Initialize the Firebase app using the configuration object

export const app1 = initializeApp(firebaseConfig1, "project1");
// export const app2 = initializeApp(firebaseConfig2, "project2");

// Get the authentication instance using the initialized Firebase app
export const twitterAuthentication = getAuth(app1);
export const analytics = getAnalytics(app1);

export const analyticsEvent = (title) => {
  logEvent(analytics, "page_view", {
    page_title: title,
  });
};
// export const googleAuthentication = getAuth(app2);

// console.log(googleAuthentication, "googleAuthentication");
