import { useCallback } from "react";
import { onToggleModal } from "../../redux/features/commonSlice";
import { useAppDispatch } from "./redux";

/**
 * Custom hook to manage opening and closing modals.
 * @returns {Object} - An object with a function to open a modal.
 */
function useCustomModals() {
  const dispatch = useAppDispatch();

  /**
   * Opens a specified modal type.
   * @param {string} modalType - The type of modal to open.
   */
  const onOpenModal = useCallback(
    (modalType) => {
      // Dispatch an action to toggle the modal using the provided modalType
      dispatch(onToggleModal({ modalType }));
    },
    // Dependency array includes dispatch since it's used in the callback
    [dispatch]
  );

  return { onOpenModal };
}

export default useCustomModals;
