import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { constants } from '../../../../../helpers';

function ProfileSettingComponent({ onSelect }) {
    return <div className='setting'>
        {/* <button className='profile-btn'><i className='setting-icon'></i></button> */}
        <Dropdown className='profile-dropdown' onSelect={onSelect}>

            <Dropdown.Toggle id="dropdown-basic" className='profile-btn'>
                <i className='setting-icon' />
            </Dropdown.Toggle>

            <Dropdown.Menu align="end">
                {Object.values(constants.PROFILE.SETTING).map(setting => {
                    return <Dropdown.Item
                        key={setting.key}
                        as={Link}
                        to={setting.path}
                        eventKey={setting.key}
                    >
                        <span className='icon'>
                            <i className={setting.iconClass} />
                        </span>
                        {setting.title}
                    </Dropdown.Item>;
                })}
            </Dropdown.Menu>

        </Dropdown>
    </div>;
}

export default ProfileSettingComponent;
