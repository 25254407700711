import { useCustomModals } from "../../../../common/custom-hooks";
import { constants } from "../../../../helpers";

function GetStarted() {
    const { onOpenModal } = useCustomModals();

    return <section className='cta-banner'>
        <div className='container'>
            <div className='h1'>Ready to Collect <br /> & Earn with NFTs?</div>

            <button
                className='btn lg w-200'
                onClick={() => {
                    onOpenModal(constants.MODAL.SIGN_UP);
                }}
            >
                Get Started
            </button>
        </div>
    </section>;
}

export default GetStarted;
