import axios from "axios";
import config from "../Config";
import { constants } from "../helpers";
import { clearReduxState } from "../redux/features/authSlice";
import { clearCommonReducerState } from "../redux/features/commonSlice";
import { store } from "../redux/store";
import { errorAlert, localStorageGet } from "../utils";

// get header keys
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const offset = new Date().getTimezoneOffset();

const apiBaseUrl = `${config.API_BASE_URL}/main/mymetafi/api/v1/`;

function getBasicAuth() {
  return "Basic " + btoa(`${config.USERNAME}:${config.PASSWORD}`);
}

// set axios instance
const customAxios = axios.create({
  baseURL: apiBaseUrl,
  timeout: 100000,
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    timezone,
    offset,
    platform: config.PLATFORM,
    language: config.LANGUAGE,
    api_key: config.API_KEY,
  },
});

// set basic auth axios instance
export const customBasicAuthAxios = axios.create({
  baseURL: apiBaseUrl,
  timeout: 100000,
  headers: {
    accept: "application/json",
    "Content-type": "application/json",
    Authorization: getBasicAuth(),
    timezone,
    offset,
    platform: config.PLATFORM,
    language: config.LANGUAGE,
    api_key: config.API_KEY,
  },
});

/**
 * @function requestHandler: Set access token and username/password for api calls
 * @param {Object} request API request
 */
const requestHandler = (request) => {
  const accessToken = localStorageGet("accessToken");
  const payload = request?.data || {};

  const isBasicAuth =
    payload?.type === constants.API_TYPE.PROFILE_UPDATION ||
    window.location.pathname === constants.BROWSER_ROUTES.REFERRAL ||
    !accessToken;

  if (isBasicAuth) {
    request.headers["Authorization"] = getBasicAuth();
  } else {
    request.headers["Authorization"] = `Bearer ${accessToken || ""}`;
  }

  return request;
};

/**
 * @function logout: Clearing the stored redux state and logged out from current session
 * @param {String} message Coming text message from backend
 */
function logout(message) {
  store.dispatch(clearReduxState());
  setTimeout(() => {
    store.dispatch(clearCommonReducerState());
  }, 2000);
  if (message) {
    errorAlert(message);
  }
}

/**
 * @function responseHandler: Checking API response and logged out when session will expire
 * @param {Object} response API response
 */
const responseHandler = (response) => {
  if (response.data?.statusCode === 401) {
    logout(response.data.message);
  }

  return response;
};

/**
 * @function errorHandler: Checking API error response and logged out when session will expire
 * @param {Object} error API response
 */
const errorHandler = (error) => {
  if (error.response?.data?.statusCode === 401) {
    logout(error.response.data.message);
  }

  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customBasicAuthAxios.interceptors.request.use(
  (request) => request,
  (error) => errorHandler(error)
);

customBasicAuthAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
