function NameTooltip({ name }) {
    return <p className="fw600 tooltip-div">
        <span className="trig">{name || 'NA'}</span>
        <div className="text xs">
            <p>{name || 'NA'}</p>
        </div>
    </p>;
}

export default NameTooltip;
