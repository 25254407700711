import { CollectivePlaceholder, ProfileDp } from '../../../../assets/images/images';
import { constants } from '../../../../helpers';
import { abbreviateNumber, setDecimalValue } from '../../../../utils';
import "../../_collective.scss";

function Component({ values: { url, details, collectiveType }, authReducer }) {
    return <div className='collect-right'>
        {collectiveType === constants.COLLECTIVE.TYPE.COLLECTION &&
            <div className='collect-card'>
                <figure><img src={details.image || CollectivePlaceholder} alt='PorfileBanner' /></figure>

                {url &&
                    <div className='text'>
                        <figure><img src={details.profile_image || ProfileDp} alt='Profile' /></figure>

                        <h6 className='f20'>{details.name || '-'}</h6>

                        <div className='d-flex'>
                            <div>
                                <p className='lg text-text m4'>Collection Size</p>
                                <h6>{abbreviateNumber(details.collectionSize) || '-'}</h6>
                            </div>
                            <div>
                                <p className='lg text-text m4'>Floor Price</p>
                                <h6>{details.floorPrice ? setDecimalValue({ value: details.floorPrice, formatted: true }) : '-'} {details.floorPrice && constants.COLLECTIVE.PRICE.SYMBOL.ETH}</h6>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
        {collectiveType === constants.COLLECTIVE.TYPE.SPECIFIC_NFT &&
            <div className='collect-card new'>
                <figure><img src={details.profile_image || CollectivePlaceholder} alt='PorfileBanner' /></figure>

                {url &&
                    <div className='text'>
                        <div className='d-flex'>
                            <p className='lg fw600'>{details.name || '-'}</p>
                            {/* <p className='fs12 fw400'>{details.name || '-'}</p> */}
                        </div>

                        <div className='d-flex'>
                            <div>
                                <p className='lg text-text m4'>Current Price</p>
                                <h6>
                                    {details.currentPrice &&
                                        <i className='etherium-icon lg'></i>
                                    }
                                    {details.currentPrice || "-"}
                                </h6>
                            </div>
                            <div>
                                <p className='lg text-text m4'>Owned By</p>
                                <h6>{details.ownedBy || "-"}</h6>
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>;
}

export default Component;
