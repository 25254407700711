import { useCallback, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { getBasicAuthDataApi } from "../../../../api/methods";
import activityLogo from '../../../../assets/images/activityLogo.png';
import { useAppNavigate, useHandleCalls } from "../../../../common/custom-hooks";
import { formatDistance } from "../../../../common/third-party-instances";
import { constants } from "../../../../helpers";
import { checkStatus, setDecimalValue, truncateToDecimalDigits } from "../../../../utils";

function CollectiveRankings() {
  const navigate = useAppNavigate();
  const [details, setDetails] = useState();

  const fetchTopCollectives = useCallback(() => {
      const query = {
        type: "TOP_COLLECTIVES",
        loaderType: constants.LOADER.TYPE.TABLE,
        callback: (response) => {
          if (response?.statusCode === 200) {
            if (response.data?.length) {
              setDetails(response?.data);
            }
          }

        },
      };

      getBasicAuthDataApi({
        path: constants.API_PATHS.HOME_TOP_COLLECTORS_COLLECTIVES,
        data: query,
      });
  }, []);

  useHandleCalls(fetchTopCollectives, [], "fetchTopCollectiveForHome");

  return (
    <section className="collective-ranks">
      <div className="container">
        <div className="heading">
          <h2>Top Collectives</h2>

          <div className="ml-div web-btn">
            <button className="link" onClick={() => navigate('/rankings?tab=collective')}>View All</button>
          </div>
        </div>


        {details?.length ?
          <div className="collective-cards">
            {details.slice(0, 10).map((collective, index) => {
              const completionPercentage = collective?.contributionGoal?.amount && collective.contribution
                ? setDecimalValue({
                  value:
                    (collective.contribution / collective?.contributionGoal?.amount) *
                    100
                })
                : 0;

              const isGoalCompleted = completionPercentage >= 100;
              return (
                <div className="cursor-pointer" key={collective.id} onClick={() => navigate(
                  `${constants.BROWSER_ROUTES.VIEW_COLLECTIVE}?collectiveId=${collective.collectiveId}`
                )}>
                  <div className="collective-card">
                    <div className="top">
                      <p className="rank-no">#{index + 1}</p>
                      <figure>
                        <img src={collective.image ? collective.image : activityLogo} alt="Game1" />
                      </figure>

                      <div className="text">
                        <p>{collective.title ? collective.title : 'NA'}</p>
                        <span>
                          {collective.leaderName ? collective.leaderName : 'NA'}{" "}
                          {/* <i className="green-tick-icon"></i> */}
                        </span>

                        <div className="progress-div">
                          {/* <p>{isGoalCompleted ? setDecimalValue({ value: completionPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE }) : setDecimalValue({ value: completionPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE })}% Contributed</p> */}
                          <p>{setDecimalValue({ value: completionPercentage, limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE })}% Contributed</p>
                          <ProgressBar
                            className={isGoalCompleted ? "green" : ''}
                            now={completionPercentage}
                          />
                        </div>
                      </div>

                      <div className="price">
                        <h6>
                          <i className="etherium-icon"></i> {truncateToDecimalDigits({
                            number: collective?.contributionGoal?.amount,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                          })}
                        </h6>
                        <p>{checkStatus(collective.created, collective.contributionPeriod, collective.isGoalReached)}</p>
                        <span>{formatDistance(new Date(), collective.lastContributionDate || collective.created, {
                          includeSeconds: true
                        })} ago</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          : <div className="text-center mx-auto">
            <h6>No Top collectives Found </h6>
            <p>List of all top 10 collectives will be shown here </p>
          </div>}


        <div className="phn-btn">
          <button className="btn-white" onClick={() => navigate('/rankings?tab=collective')}>View All</button>
        </div>
      </div>
    </section>
  );
}

export default CollectiveRankings;
