/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from 'react-bootstrap';
import { Web3modalEthereum } from '../../..';
// import { constants } from '../../../../../helpers';
// import { underDevAlert } from '../../../../../utils';

function Component({ isOpen, selectedWallet, onSetSelectedWallet, onSetFetched, onConnectWeb3, onDisconnectWeb3, onClose }) {
    return <Modal className='wallet-modal' show={isOpen} onHide={onClose} backdrop="static">
        <Modal.Header closeButton>
            {/* {selectedWallet &&
                <button
                    onClick={() => {
                        onDisconnectWeb3();
                        onSetSelectedWallet();
                    }}
                >
                    back
                </button>
            } */}
            <h5 className='m0'>Select wallet to connect</h5>
        </Modal.Header>

        <Modal.Body>
            {/* {!selectedWallet && */}
            {/* <div className='modal-wallet'>
                {constants.PROFILE.WALLET.LIST.map(wallet => {
                    return <div
                        key={wallet.name}
                        className='connect-card'
                        onClick={(event) => {
                            if (wallet.type === "under-develepment") {
                                underDevAlert();
                            } else {
                                onConnectWeb3(event);
                                onSetSelectedWallet(wallet.name);
                            }
                        }}
                    >
                        <figure><i className={wallet.icon}></i></figure>
                        <div className='text'>
                            <p>{wallet.name}</p>
                        </div>
                        <a href='javascript:;' className='str'></a>
                    </div>;
                })}
            </div> */}
            <Web3modalEthereum />
            {/* } */}
        </Modal.Body>
    </Modal>;
}

export default Component;
