import { useEffect, useState } from 'react';
import { constants } from '../../../../../../../helpers';
import { useReduxStore } from '../../../../../../custom-hooks';
import getFormikValidationSchema from '../../../../../../formik-validations';
import Component from './Component';

const initialState = {
    contributionGoal: '',
    contribute: '',
};

function CollectivePreview(props) {
    const schema = getFormikValidationSchema('collectivePreview');
    const { authReducer, commonReducer } = useReduxStore();
    const [detail, setDetails] = useState(JSON.parse(JSON.stringify(initialState)));

    useEffect(() => {
        if (commonReducer?.modal?.collective?.contributionGoal) {
            setDetails({
                ...detail,
                contributionGoal: commonReducer.modal.collective.contributionGoal,
                contribute: commonReducer.modal.collective.contributionAmount || constants.COLLECTIVE.MIN_CONTRIBUTION_AMOUNT({
                    val: commonReducer.modal.collective.contributionGoal,
                    isBigNumber: true,
                    goalDetails: authReducer?.goalDetails
                }),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [commonReducer]);

    return <Component
        {...props}
        detail={detail}
        schema={schema}
        authReducer={authReducer}
        collectiveDetails={commonReducer.modal.collective}
    />;
}

export default CollectivePreview;
