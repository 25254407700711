import { Modal } from 'react-bootstrap';
import { ImageNotFound } from '../../../../../assets/images/images';
import { PROPOSAL_TYPE } from '../../../../../helpers/constants';
import { calculateTimeDifference, getFormattedTimeDifference } from '../../../../../utils';

/**
 * Component for displaying voting information and options.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {boolean} props.isOpen - Indicates whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal close.
 * @param {Function} props.setVote - Function to set the selected vote.
 * @param {string} props.vote - The selected vote.
 * @param {Function} props.onSubmit - Function to handle vote submission.
 * @param {Object} props.commonReducer - The common reducer data.
 * @returns {JSX.Element} - Rendered Component.
 */
function Component({ isOpen, onClose, setVote, vote, onSubmit, commonReducer }) {
    // Calculate the time difference for display
    const createdTime = calculateTimeDifference(new Date(commonReducer?.created));

    return (
        <Modal className='voter-info g-card-modal' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                {/* Modal header content */}
            </Modal.Header>

            <Modal.Body>
                {/* Modal body content */}
                <div className='g-card mb0'>
                    {/* Card content */}
                    <div className='header'>
                        {/* Card header */}
                        <figure>
                            <img src={commonReducer?.nftDetails?.buyNft_Data[0]?.image || ImageNotFound} alt="Logo" />
                        </figure>

                        <div className='text'>
                            <h6 className='f20'>{commonReducer?.type === "selling_proposal" ? PROPOSAL_TYPE.selling_proposal : PROPOSAL_TYPE.governing_proposal}</h6>
                            <p className='text-text'>{commonReducer?.nftDetails?.buyNft_Data[0]?.name || commonReducer?.nftDetails?.title}</p>
                        </div>

                        <div className='ml-div'>
                            <p className='text-grey'> {getFormattedTimeDifference(createdTime)}</p>
                        </div>
                    </div>

                    <div className='select-option'>
                        {/* Voting options */}
                        <div className='title'>
                            <p className='lg'>{commonReducer?.nftDetails?.subTitle}</p>
                        </div>

                        <div>
                            <div className={vote === "YES" ? 'vote-card active' : 'vote-card'}>
                                <input type="radio" className='' onChange={() => setVote("YES")} name='vote' />
                                <label>Yes</label>
                            </div>

                            <div className={vote === "NO" ? 'vote-card active' : 'vote-card'}>
                                <input type="radio" onChange={() => setVote("NO")} name='vote' />
                                <label>No</label>
                            </div>

                            <div className={vote === "ABSTAIN" ? 'vote-card active' : 'vote-card'}>
                                <input type="radio" onChange={() => setVote("ABSTAIN")} name='vote' />
                                <label>Abstain</label>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                {/* Modal footer content */}
                <div className='btns'>
                    <button className='btn-white lg skip' onClick={onClose}>Back</button>
                    <button className='btn lg' type='button' onClick={onSubmit}>Vote</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

// Export the Component component as the default export
export default Component;
