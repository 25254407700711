import { Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { InputField, PasswordValidationView } from '../../..';
import FormikWrapper from '../../../formik-wrapper';

function Component({ isOpen, initialState, schema, onClose, onSubmit }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => {
            const isDisabledSubmit = !(values.oldPassword && values.newPassword && values.confirmNewPassword) || isSubmitting;

            return <Form>
                <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton />

                    <Modal.Body>
                        <h5 className='m6'>Update Password</h5>
                        <p className='m20 text-text'>Add your password details below</p>

                        <InputField
                            type={values.isShowingOldPassword ? 'text' : 'password'}
                            name='oldPassword'
                            label='Old Password'
                            required
                            placeholder="Enter old password"
                            wrapperClass='form-in right-icon'
                            errors={errors}
                            touched={touched}
                        >
                            <span
                                className='icon-right'
                                onClick={() => setFieldValue('isShowingOldPassword', !values.isShowingOldPassword)}
                            >
                                <i className={values.isShowingOldPassword ? 'eye-closed-icon' : "eye-icon"}></i>
                            </span>
                        </InputField>

                        <InputField
                            type={values.isShowingNewPassword ? 'text' : 'password'}
                            name='newPassword'
                            label='New Password'
                            required
                            placeholder="Enter new password"
                            wrapperClass='form-in right-icon'
                            errors={errors}
                            touched={touched}
                        >
                            <span
                                className='icon-right'
                                onClick={() => setFieldValue('isShowingNewPassword', !values.isShowingNewPassword)}
                            >
                                <i className={values.isShowingNewPassword ? 'eye-closed-icon' : "eye-icon"}></i>

                                <PasswordValidationView name="newPassword" values={values} errors={errors} touched={touched} />
                            </span>
                        </InputField>

                        <InputField
                            type={values.isShowingConfirmNewPassword ? 'text' : 'password'}
                            name='confirmNewPassword'
                            label='Confirm New Password'
                            required
                            placeholder="Enter confirm new password"
                            wrapperClass='form-in right-icon'
                            errors={errors}
                            touched={touched}
                        >
                            <span
                                className='icon-right'
                                onClick={() => setFieldValue('isShowingConfirmNewPassword', !values.isShowingConfirmNewPassword)}
                            >
                                <i className={values.isShowingConfirmNewPassword ? 'eye-closed-icon' : "eye-icon"}></i>
                            </span>
                        </InputField>

                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btns'>
                            <button className='btn lg' disabled={isDisabledSubmit} onClick={handleSubmit}>
                                Update Password
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default Component;
