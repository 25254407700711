/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { useAppDispatch } from '../../../../custom-hooks';
import { onToggleModal } from '../../../../../redux/features/commonSlice';

function Component({ isOpen, onClose, carouselItems, index, onNext, onSkip }) {
    const dispatch = useAppDispatch()

    return <Modal className='proposal-modal' show={isOpen} onHide={onClose} backdrop="static">

        <Modal.Body>
            <Carousel activeIndex={index}>
                {carouselItems.map((item) => (
                    <Carousel.Item key={item.id}>
                        <figure><img src={item.image} alt={item.alt} /></figure>
                        <div className='text'>
                            <h3>{item.heading}</h3>
                            <p>{item.subHeading}</p>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </Modal.Body>

        <Modal.Footer>
            <div className='btns'>
                {index === 3 ?
                    <div className='text-center '>
                        <button type='button' className='btn w-200' onClick={() => { dispatch(onToggleModal()) }}>Continue</button>
                    </div>
                    :
                    <>
                        <button type='button' className='btn-white skip' onClick={onSkip}>Skip</button>
                        <button type='button' className='btn' onClick={onNext}>Next</button>
                    </>
                }

            </div>
        </Modal.Footer>
    </Modal>
}

export default Component;
