import { Modal } from 'react-bootstrap';
import { constants } from '../../../../../helpers';
import { truncateToDecimalDigits } from '../../../../../utils';

function Component({ isOpen, contributionDetails, onClose, onSubmit }) {
    return (
        <Modal className='add-comment' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                <h6 className='f20 mb0'>Contribution Details</h6>
            </Modal.Header>

            <Modal.Body>
                {/* 2.5% platform fee changes */}
                <div className="choose-total">
                    <div className="d-flex">
                        <p className="fw500 lg">Total Contribution</p>
                        <p className="fw500 lg ml-auto">{truncateToDecimalDigits({
                            number: contributionDetails.totalContribution,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                        })} {constants.COLLECTIVE.PRICE.SYMBOL.ETH}</p>
                    </div>
                    <br />
                    <div className="d-flex">
                        <p className="fw500 lg">Selected NFT</p>
                        <p className="fw500 lg ml-auto">{truncateToDecimalDigits({
                            number: contributionDetails.nftPrice,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                        })} {constants.COLLECTIVE.PRICE.SYMBOL.ETH}</p>
                    </div>
                    <div className="d-flex">
                        <p className="fw500 lg text-black">MyMetaFi Fee</p>
                        <p className="fw500 lg ml-auto text-black">{truncateToDecimalDigits({
                            number: contributionDetails.plaformFee,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                        })} {constants.COLLECTIVE.PRICE.SYMBOL.ETH}</p>
                    </div>
                    <div className="d-flex total">
                        <p className="fw500 lg text-black">Total Amount</p>
                        <p className="fw500 lg ml-auto text-black">{truncateToDecimalDigits({
                            number: contributionDetails.totalAmount,
                            limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                        })} {constants.COLLECTIVE.PRICE.SYMBOL.ETH}</p>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className='btns'>
                    <button className='btn-white lg' onClick={onClose}>Back</button>
                    <button className='btn lg' type='button' onClick={onSubmit}>Confirm</button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default Component;
