import { useMemo } from "react";
import useReduxStore from "./useReduxStore";

function useGetVotingDelegationDetails() {
  const { authReducer, commonReducer } = useReduxStore();

  const delegationDetails = useMemo(() => {
    const list = commonReducer.votingDelegationList || [];
    const myDelagation =
      list.find(
        (item) =>
          item &&
          item.delegatedUsername !== authReducer.username &&
          item.userData?.userName === authReducer.username
      ) || null;
    return { list, myDelagation };
  }, [authReducer, commonReducer]);

  return { delegationDetails };
}

export default useGetVotingDelegationDetails;
