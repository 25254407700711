import { useCallback } from "react";
import { useAppDispatch } from "./redux";
import {
  setRefreshCollectiveDetails,
  setRefreshVotingDelegationList,
  setRefreshAllCollectorList,
  setRefreshVotingandCommentDetails
} from "../../redux/features/commonSlice";

function useRefreshPage() {
  const dispatch = useAppDispatch();

  const handleRefreshViewCollectivePage = useCallback(() => {
    dispatch(setRefreshCollectiveDetails(true));
    dispatch(setRefreshVotingDelegationList(true));
    dispatch(setRefreshAllCollectorList(true));
    dispatch(setRefreshVotingandCommentDetails(true));

    setTimeout(() => {
      dispatch(setRefreshCollectiveDetails(false));
      dispatch(setRefreshVotingDelegationList(false));
      dispatch(setRefreshAllCollectorList(false));
      dispatch(setRefreshVotingandCommentDetails(false));
    }, 1000);
  }, [dispatch]);

  return { onRefreshViewCollectivePage: handleRefreshViewCollectivePage };
}

export default useRefreshPage;
