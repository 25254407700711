/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LogoWhite } from "../../../assets/images/images";
import { constants } from "../../../helpers";
import { NavigationBar } from "../../../pages/home/modules";
import Dropdown from 'react-bootstrap/Dropdown';
import { ConnectWallet, HomeLink } from '..';
import { underDevAlert } from "../../../utils";

function Component({ isHomePage, authReducer, isOpen, dispatch, navigate, elementClass, onForceReRender, onToggleMenu, onToggleModal, details, onClickNew }) {

    return <header className={`main-header ${(isHomePage) ? "dark" : ''} ${isOpen ? 'nav-active' : ''} ${elementClass}`}>
        <div className='container'>
            <div
                className='logo-div'
            >
                <HomeLink>
                    <img src={LogoWhite} alt="Logo" />
                </HomeLink>
            </div>

            <NavigationBar isOpen={isOpen} onToggleMenu={onToggleMenu} onForceReRender={onForceReRender} />

            <div className='header-btn'>
                {authReducer.isloggedin
                    ? <ConnectWallet type='header' />
                    : <div>
                        <button
                            className='top-btn '
                            onClick={() => {
                                onToggleModal();
                                onForceReRender();
                            }}
                        >
                            Connect Wallet
                        </button>
                    </div>
                }

                <Dropdown className="add-new-drop" onClick={() => { onClickNew() }}>
                    <Dropdown.Toggle className="top-btn wallet-btn" id="dropdown-basic">+ New</Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item
                            href="#"
                            onClick={() => {
                                if (authReducer.isloggedin) {
                                    navigate(constants.BROWSER_ROUTES.ADD_COLLECTIVE);
                                } else {
                                    onToggleModal();
                                }
                            }}
                        >
                            New Collective
                        </Dropdown.Item>
                        {(details?.collected > 0 || details?.createdNo > 0) &&
                            <Dropdown.Item href="#"
                                onClick={() => {
                                    if (authReducer.isloggedin) {
                                        navigate(constants.BROWSER_ROUTES.ADD_NEW_PROPOSAL);
                                    } else {
                                        onToggleModal()
                                    }
                                }}
                            >
                                New Proposal
                            </Dropdown.Item>
                        }
                        <Dropdown.Item href="#" onClick={underDevAlert}>New Giveaway</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <div className="ham-btn">
                    <button className='' onClick={() => {
                        onToggleMenu(isOpen);
                    }}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
    </header>;
}

export default Component;
