import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import config from "../Config";
import authSlice from "./features/authSlice";
import commonSlice from "./features/commonSlice";

const persistConfig = {
  key: "root",
  storage: storage,
};


const appReducer = combineReducers({
  auth: persistReducer(persistConfig, authSlice),
  common: commonSlice,
});

const rootReducer = (state, action) => {
  // if (action.type === "auth/clearReduxState") {
  //   storage.removeItem("persist:root");
  //   state = undefined; // Set state to undefined to reset all state slices
  //   window.location.href = '/'
  // }
  return appReducer(state, action);
};

export var store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: !config.isProdEnv,
});

export const persistor = persistStore(store);
