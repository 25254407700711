/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { Logo } from "../../../assets/images/images";
import { constants } from "../../../helpers";
import HomeLink from "../home-link";

function Footer() {
    const isNotAllowed = [constants.BROWSER_ROUTES.REFERRAL].find(route => route === window.location.pathname);

    // const handleContentModal = useCallback((modalType) => {
    //     if (!authReducer.isloggedin) {
    //         dispatch(onToggleModal({ modalType }));
    //     }
    // }, [dispatch, authReducer]);

    if (isNotAllowed) {
        return null;
    }

    return <footer className='main-footer'>
        <div className='container'>
            <div className='footer-row'>
                <div className='footer-logo'>
                    <figure>
                        <HomeLink>
                            <img src={Logo} alt='Logo' />
                        </HomeLink>
                    </figure>
                    <p>Democratizing NFT ownership and utility for the masses.</p>
                </div>

                <div className='quick-links'>
                    <h6>Links</h6>

                    <div className='links'>
                        <Link
                            to={constants.BROWSER_ROUTES.TERMS_AND_CONDITIONS}
                            // onClick={() => {
                            //     handleContentModal(constants.MODAL.TERMS_OF_USE);
                            // }}
                        >Terms of Use</Link>
                        <Link to={constants.BROWSER_ROUTES.ABOUTUS} target="_blank">About Us</Link>
                        <Link
                            to={constants.BROWSER_ROUTES.PRIVACY_POLICY}
                            // onClick={() => {
                            //     handleContentModal(constants.MODAL.PRIVACY_POLICY);
                            // }}
                        >Privacy Policy</Link>
                        <Link to={constants.BROWSER_ROUTES.FAQ} target="_blank">FAQ</Link>
                    </div>
                </div>

                <div className='social'>
                    <a href='https://discord.com/invite/xhym4P6cMd' target="blank"><i className='discord-lg-icon'></i></a>
                    <a href='https://www.facebook.com/MyMetaFi/' target="blank"><i className='facebook-icon lg'></i></a>
                    <a href='https://twitter.com/mymetafi' target="blank"><i className='twitter-icon'></i></a>
                </div>
            </div>

            <p className='rights'>© 2023 MyMetaFi  |  All Right Reserved</p>
        </div>
    </footer>;
}

export default Footer;
