import { ethers, utils } from 'ethers';
import { Field, Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { constants } from '../../../../../../../helpers';
import { checkValidDecimals, setDecimalValue } from '../../../../../../../utils';
import Contribute from '../../../../../../wallet-transactions/contribute';
import FormikWrapper from '../../../../../formik-wrapper';
import ValidationMessage from '../../../../../validation-message';
import VotingDelegationDropdown from '../../../../../voting-delegation-dropdown';

/**
 * Component for handling contribution details.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @param {boolean} props.isOpen - Determines if the modal is open.
 * @param {Object} props.schema - The validation schema for the form.
 * @param {Object} props.authReducer - Authentication related data from Redux.
 * @param {Object} props.commonReducer - Common data from Redux.
 * @param {Object} props.detail - Details for the contribution.
 * @param {boolean} props.isSubmitting - Indicates if a submission is in progress.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {Function} props.onOpenModal - Function to open a modal.
 * @param {Function} props.onIsSubmitting - Function to control form submission state.
 * @returns {JSX.Element} - Rendered Component.
 */
function Component({ isOpen, schema, authReducer, commonReducer, detail, isSubmitting, onClose, onOpenModal, onIsSubmitting }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={detail}
        validationSchema={schema}
    >
        {({ values, errors, touched, resetForm, setFieldValue }) => {
            const errorLen = Object.keys(errors).length;
            const isDisabledSubmit = !values.contribute || isSubmitting || (errorLen ? Object.values(errors).some(err => !!err) : Object.keys(errors).length);

            // Calculate minimum and maximum contribution amounts
            const minContributionAmount = setDecimalValue({ value: values.contributionAmount, formatted: false });
            const maxContributionAmount = constants.COLLECTIVE.MAX_CONTRIBUTION_AMOUNT({
                val: values.contributionGoal,
                goalDetails: authReducer?.goalDetails,
            });

            // Props for the Contribute component
            const submitProps = {
                data: values,
                contributor: authReducer.wallet?.address || '',
                collectiveCrowdfundAddress: commonReducer.modal?.collective?.contractAddress || '',
                delegateAddress: values.selectedUser?.userData?.walletAddress || ethers.constants.AddressZero || '',
                amount: values.contribute ? utils.parseEther(String(values.contribute)) : '',
                isDisabledSubmit,
                isCollectiveType: commonReducer.modal?.collective?.collectiveType === constants.COLLECTIVE.TYPE.COLLECTION,
                isSubmitting,
                onIsSubmitting,
                resetForm,
            };

            return (
                <Form>
                    <Modal className='add-contribute' show={isOpen} onHide={onClose} backdrop="static">
                        <Modal.Header closeButton>
                            <h5>Contribute</h5>
                        </Modal.Header>

                        <Modal.Body>
                            <div className="eth-input full">
                                <h5>{constants.COLLECTIVE.PRICE.SYMBOL.ETH}</h5>
                                <Field
                                    type='number'
                                    name='contribute'
                                    placeholder={`Minimum ${minContributionAmount} ${constants.COLLECTIVE.PRICE.SYMBOL.ETH}`}
                                    min={minContributionAmount}
                                    max={maxContributionAmount}
                                    onChange={(event) => {
                                        if (checkValidDecimals({ event })) {
                                            setFieldValue('contribute', event.target.value);
                                        }
                                    }}
                                />
                            </div>
                            {!!(errors?.['contribute'] && touched?.['contribute']) && <ValidationMessage message={errors['contribute']} />}
                            {/* <div className='checkbox mb28'>
                            <input type="checkbox" />
                            <label className=''>
                                Use Gas Credits
                                <span className='text-text fw400 ml6'>
                                    (Available Credits: 1)
                                </span>
                            </label>
                        </div> */}
                            <VotingDelegationDropdown
                                value={values.selectedUser}
                                isH6Tag
                                onChange={(value) => { setFieldValue('selectedUser', value); }}
                            />
                        </Modal.Body>

                        <Modal.Footer>
                            <Contribute {...submitProps} />
                        </Modal.Footer>
                    </Modal>
                </Form>
            );
        }}
    </FormikWrapper>;
}

// Export the Component as the default export
export default Component;
