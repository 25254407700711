import { ethers } from 'ethers';
import { Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { constants } from '../../../../../../../helpers';
import DelegateVote from '../../../../../../wallet-transactions/voting-delegation';
import FormikWrapper from '../../../../../formik-wrapper';
import VotingDelegationDropdown from '../../../../../voting-delegation-dropdown';

/**
 * Component for voting delegation modal.
 * @param {boolean} isOpen - Flag indicating if the modal is open.
 * @param {object} detail - Initial state details for the form.
 * @param {boolean} isSubmitting - Flag indicating if the form is submitting.
 * @param {object} authReducer - Authentication reducer state.
 * @param {object} commonReducer - Common reducer state.
 * @param {function} onClose - Callback to handle modal close.
 * @param {function} onOpenModal - Callback to handle modal open.
 * @param {function} onIsSubmitting - Callback to handle form submission status.
 */
function Component({ isOpen, detail, isSubmitting, authReducer, commonReducer, onClose, onOpenModal, onIsSubmitting }) {
    return <FormikWrapper enableReinitialize initialValues={detail}>
        {({ values, errors, touched, resetForm, setFieldValue, handleSubmit }) => {
            const isDisabledSubmit = !values.selectedUser || isSubmitting;

            const submitProps = {
                contributor: authReducer.wallet?.address || '',
                collectiveCrowdfundAddress: commonReducer.modal?.collective?.contractAddress || '',
                delegateAddress: values.selectedUser?.userData?.walletAddress || ethers.constants.AddressZero || '',
                votingDelegationId: values.selectedUser?.votingDelegationId || '',
                isDisabledSubmit,
                isSubmitting,
                isCollectionType: commonReducer.modal.collective.collectiveType === constants.COLLECTIVE.TYPE.COLLECTION,
                onIsSubmitting,
                resetForm,
            };

            return <Form>
                <Modal className='add-contribute' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        {/* Header content (if any) */}
                    </Modal.Header>

                    <Modal.Body>
                        <VotingDelegationDropdown
                            value={values.selectedUser}
                            onChange={(value) => { setFieldValue('selectedUser', value); }}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <DelegateVote {...submitProps} />
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

// Export the Component as the default export
export default Component;
