import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import React from "react";
import { successAlert } from "../../../utils";
import { app1 } from "../../../firebaseConfig";
import {
  useAppDispatch,
  useFingerprint,
  useUserProfileDetails,
} from "../../custom-hooks";
import { socialLogin } from "../../../redux/services/auth";
import { setSocialLoginInfo } from "../../../redux/features/authSlice";

const TwitterLoginComponent = ({ type }) => {
  const { onFetchUserDetails } = useUserProfileDetails();
  const deviceId = useFingerprint();
  const dispatch = useAppDispatch();


  const SignInWithTwitter = async () => {
    const provider = new TwitterAuthProvider();
    const twitterAuthentication = getAuth(app1);
    return signInWithPopup(twitterAuthentication, provider)
      .then((res) => {
        return res.user;
      })
      .catch((error) => {
        console.log(error.code, error.email);
        // errorAlert(
        //   firebaseErrorMessages[error.code] ||
        //     error.message.replace("Firebase: ", "").replace(/\(auth.*\)\.?/, "")
        // );
      });
  };

  const handleTwitterLogin = async () => {
    const response = await SignInWithTwitter();
    dispatch(setSocialLoginInfo({ data: response }))
    if (response?.reloadUserInfo) {
      const request = {
        firstName: response?.reloadUserInfo?.displayName.split(" ")[0],
        lastName: response?.reloadUserInfo?.displayName.split(" ")[1],
        profileUrl: `https://twitter.com/${response?.reloadUserInfo?.screenName}`,
        socialLoginType: "twitter",
        socialId: response?.reloadUserInfo.providerUserInfo[0]?.rawId,
        deviceId: deviceId,
        deviceToken: "string",
        notify: false,
        callback: (response) => {
          if (response?.statusCode === 200 && response.message) {
            successAlert("Twitter Connected Successfully");
            onFetchUserDetails();
          }
        },
      };
      if (response?.reloadUserInfo?.email) {
        request["email"] = response?.reloadUserInfo?.email;
      }
      dispatch(socialLogin(request));
    }
  };

  return type === "icon" ? (
    <span className={"inactive"}>
      <div className='tooltip-div'>
        <i className='twitter-icon trig' onClick={handleTwitterLogin}></i>
        <p className='text xs fw500'>Twitter is not connected</p>
      </div>
      {/* <i className="twitter-icon cursor-pointer"></i> */}
    </span>
  ) : (
    <span
      className="link ml-auto align-self-center fs15 cursor-pointer"
      onClick={handleTwitterLogin}
    >
      <u>Connect Now</u>
    </span>
  );
};

export default TwitterLoginComponent;
