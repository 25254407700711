import { useEffect, useMemo, useState } from "react";
import { constants } from "../../helpers";
import useReduxStore from "./useReduxStore";

/**
 * Custom hook to calculate the contributed percentage and check if the user can contribute.
 * @param {Array} collectors - List of collectors.
 * @param {Object} collective - Collective details.
 * @param {string} collector_filter - Collector filter.
 * @param {boolean} isFetchedCollectors - Flag indicating whether collectors are fetched.
 * @returns {Object} - An object containing calculated contributed percentage and canContribute flag.
 */
function useFindContibutedPercentage({
  collectors,
  collective,
  collector_filter,
  isFetchedCollectors,
}) {
  const { authReducer } = useReduxStore();
  const [canContribute, setCanContribute] = useState(false);

  // Calculate the user's collector information based on their username
  const myCollector = useMemo(() => {
    if (!collectors.length) return {};

    return (
      collectors.find((col) => {
        const username = col.userData?.userName || col?.userName || "";

        return username === authReducer.username;
      }) || {}
    );
  }, [authReducer, collectors]);

  useEffect(() => {
    // Calculate the contributed percentage and check if the user can contribute
    if (
      // !myCollector?.contribution ||
      collector_filter !== constants.COLLECTIVE.COLLECTOR_FILTER.CONTRIBUTION ||
      !isFetchedCollectors
    )
      return;

    const percentage =
      ((myCollector?.contribution || 0) / collective.contributionGoal) * 100;

    // Set the canContribute flag based on the calculated percentage
    setCanContribute(myCollector ? percentage < 20 : true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collector_filter, isFetchedCollectors]);

  return {
    canContribute,
    myCollector,
  };
}

export default useFindContibutedPercentage;
