import { useCallback, useState } from 'react';
import { errorAlert } from '../../../../../utils';
import { useAppDispatch, useReduxStore } from '../../../../custom-hooks';
import Component from './Component';
import { postDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { onToggleModal } from '../../../../../redux/features/commonSlice';

/**
 * AddVote component for handling vote addition.
 *
 * @component
 * @param {Object} props - The properties passed down to the component.
 * @returns {JSX.Element} - Rendered AddVote component.
 */
function AddVote(props) {
    // Retrieve commonReducer and dispatch function from custom hooks
    const { commonReducer } = useReduxStore();
    const dispatch = useAppDispatch();

    // State to manage the vote
    const [vote, setVote] = useState('');

    /**
     * Handles the form submission for adding a vote.
     *
     * Sends a request to add a vote and handles the response accordingly.
     */
    const handleSubmit = useCallback(
        () => {
            const query = {
                proposalId: commonReducer.modal.proposal?.proposalId,
                type: commonReducer.modal.proposal?.type,
                callback: (response) => {
                    if (response?.statusCode === 201) {
                        dispatch(onToggleModal({ modalType: constants.MODAL.ADD_VOTE_SUCCESS }));
                        setVote('');
                    } else {
                        errorAlert(response.data?.message);
                    }
                }
            };
            if (vote) {
                query.vote = vote;
            }
            postDataApi({ path: constants.API_PATHS.ADD_VOTES, data: query });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [vote]
    );

    // Render the Component with the necessary props
    return (
        <Component
            {...props}
            vote={vote}
            setVote={setVote}
            onSubmit={handleSubmit}
            commonReducer={commonReducer.modal.proposal}
        />
    );
}

// Export the AddVote component as the default export
export default AddVote;
