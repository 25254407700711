import { useAccount } from 'wagmi';
import ConnectWallet from '../connect-wallet';

function CheckWalletConnection({ children }) {
    const { address } = useAccount();

    if (!address) {
        return (
            <ConnectWallet
                type="check-wallet-connection"
            />
        );
    }

    return children;
}

export default CheckWalletConnection;
