import { useCallback, useState } from 'react';
import { getBasicAuthDataApi } from '../../../../../api/methods';
import { constants } from '../../../../../helpers';
import { errorAlert } from '../../../../../utils';
import { useHandleCalls } from '../../../../custom-hooks';
import Component from './Component';

function TermsOfUse(props) {
    const [data, setData] = useState([]);
    const [currentPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);

    const handleFetchTermsAndConditions = useCallback(
        (page) => {
            setIsFetching(true);
            const query = {
                pageNo: page || currentPage,
                limit: 10,
                searchKey: "TermsOfUse",
                loaderType: constants.LOADER.TYPE.TABLE,
                callback: (response) => {
                    setIsFetching(false);
                    if (response?.statusCode === 200 || response?.statusCode === 201) {
                        setData(response.data[0].data);
                    } else {
                        errorAlert(response?.data.message);
                    }
                },
            };

            getBasicAuthDataApi({ path: constants.API_PATHS.GET_CONTENT, data: query });
        },
        [currentPage]
    );

    useHandleCalls(handleFetchTermsAndConditions, [], "fetchTermsAndConditions");

    return <Component {...props} isFetching={isFetching} data={data} />;
}

export default TermsOfUse;
