import { Form } from 'formik';
import { Modal } from 'react-bootstrap';
import { Logo } from '../../../../../assets/images/images';
import { InputField, PasswordValidationView } from '../../../../components';
import FormikWrapper from '../../../formik-wrapper';

function SetNewPasswordComponent({ isOpen, initialState, schema, onClose, onOpenModal, onSubmit }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={onSubmit}
    >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleSubmit }) => {
            const isDisabledSubmit = !(values.newPassword && values.confirmPassword) || isSubmitting;

            return <Form>
                <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        <figure><img src={Logo} alt="Logo" /></figure>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className='m6'>New Password</h5>
                        <p className='m20 text-text'>Your Identity has been verified! Set up your new password</p>

                        <InputField
                            type={values.isShowingNewPassword ? 'text' : 'password'}
                            name='newPassword'
                            label='New Password'
                            required
                            placeholder="Enter new password"
                            wrapperClass='form-in right-icon'
                            errors={errors}
                            touched={touched}
                        >
                            <span
                                className='icon-right'
                                onClick={() => setFieldValue('isShowingNewPassword', !values.isShowingNewPassword)}
                            >
                                <i className={values.isShowingNewPassword ? 'eye-closed-icon' : "eye-icon"}></i>

                                <PasswordValidationView name='newPassword' values={values} touched={touched} errors={errors} />
                            </span>
                        </InputField>

                        <InputField
                            type={values.isShowingConfirmPassword ? 'text' : 'password'}
                            name='confirmPassword'
                            label='Confirm Password'
                            required
                            placeholder="Enter confirm password"
                            wrapperClass='form-in right-icon'
                            errors={errors}
                            touched={touched}
                        >
                            <span
                                className='icon-right'
                                onClick={() => setFieldValue('isShowingConfirmPassword', !values.isShowingConfirmPassword)}
                            >
                                <i className={values.isShowingConfirmPassword ? 'eye-closed-icon' : "eye-icon"}></i>
                            </span>
                        </InputField>

                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btns'>
                            <button className='btn lg' disabled={isDisabledSubmit} onClick={handleSubmit}>
                                Update Password
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default SetNewPasswordComponent;
