import { useEffect } from "react";
import {
  setBuyNftProgressLoading,
  setRefreshAllCollectorList,
  setRefreshVotingDelegationList,
} from "../../redux/features/commonSlice";
import { useAppDispatch } from "./redux";
import useReduxStore from "./useReduxStore";

let autoRefreshBuyNftInterval = null;

function useAutomaticRefreshBuyNft({
  isNftPurchased,
  onFetchCollectiveDetails,
}) {
  const { commonReducer } = useReduxStore();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (commonReducer.loadProgress?.isBuyNftLoading) {
      autoRefreshBuyNftInterval = setInterval(() => {
        dispatch(setRefreshVotingDelegationList(true));
        dispatch(setRefreshAllCollectorList(true));

        setTimeout(() => {
          dispatch(setRefreshVotingDelegationList(false));
          dispatch(setRefreshAllCollectorList(false));
        }, 1000);

        onFetchCollectiveDetails();
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonReducer.loadProgress]);

  useEffect(() => {
    if (isNftPurchased && autoRefreshBuyNftInterval) {
      dispatch(setBuyNftProgressLoading(false));
      clearInterval(autoRefreshBuyNftInterval);
    }
  }, [isNftPurchased, dispatch]);
}

export default useAutomaticRefreshBuyNft;
