import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { resetPageCount, setPageCount } from '../../../redux/features/commonSlice';
import { useAppDispatch, useLoginFlow, useReduxStore } from '../../custom-hooks';

const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();
    const { authReducer, commonReducer } = useReduxStore();
    const { onOpenLoginFlowModal } = useLoginFlow();
    const dispatch = useAppDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (!authReducer.isloggedin) {
            if (commonReducer.pageCount + 1 === 5) {
                onOpenLoginFlowModal();
                dispatch(resetPageCount());
            } else {
                dispatch(setPageCount());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return children || null;
};

export default ScrollToTop;
