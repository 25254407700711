import { useCallback, useState } from 'react';
import { useReduxStore } from '../../../../../../custom-hooks';
import Component from './Component';

// Define the initial state for the component
const initialState = {
    selectedUser: null
};

// VotingDelegation functional component
function VotingDelegation(props) {
    // Use the useReduxStore hook to access the Redux store
    const { commonReducer, authReducer } = useReduxStore();

    // Use the useState hook to set the component's state
    const [detail] = useState(JSON.parse(JSON.stringify(initialState)));  // Initialize with a deep copy of initialState
    const [isSubmitting, setIsSubmitting] = useState(false);  // State to manage whether the component is in a submitting state or not

    // Define a callback function to update the isSubmitting state
    const handleIsSubmitting = useCallback((value) => {
        setIsSubmitting(value);
    }, []);

    // Render the Component component and pass necessary props to it
    return <Component
        {...props}
        detail={detail}
        isSubmitting={isSubmitting}
        authReducer={authReducer}
        commonReducer={commonReducer}
        onIsSubmitting={handleIsSubmitting}
    />;
}

// Export the VotingDelegation component as the default export
export default VotingDelegation;
