import { useCallback, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { constants, messages } from "../../../helpers";
import { calculateFeePlusPrice, errorAlert, getNftUrlDetails } from "../../../utils";
import { useAppNavigate, useReduxStore } from "../../custom-hooks";
import Component from "./Component";

function BuyNft(props) {
    const { details, selectedNft, buyNftList, isCollectionType } = props;
    const navigate = useAppNavigate();
    const { address } = useAccount();
    const { commonReducer } = useReduxStore();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleIsSubmitting = useCallback((value) => {
        setIsSubmitting(value);
    }, []);

    const handleProceedToBuyNft = useCallback(() => {
        if (Object.keys(details)?.length && !buyNftList?.length) {
            errorAlert(messages.COLLECTIVE.FLOOR_ITEM_MISSING);
        } else {
            navigate(`${constants.BROWSER_ROUTES.BUY_NFT}?collectiveId=${details.collectiveId}`);
        }
    }, [navigate, details, buyNftList]);

    const isCollectiveDetailPage = useMemo(() => {
        return window.location.pathname !== constants.BROWSER_ROUTES.BUY_NFT;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    const buttonConfig = useMemo(() => {
        let dataToReturn;
        if (isCollectiveDetailPage) {
            dataToReturn = {
                title: 'Buy NFT Now',
                className: 'btn lg',
            };
        } else {
            dataToReturn = {
                title: 'Buy Now',
                className: "lg w-100 btn",
            };
        }
        if (!address) {
            dataToReturn.title = 'Connect Wallet';
        }
        return dataToReturn;
    }, [isCollectiveDetailPage, address]);

    const nftDetails = useMemo(() => {
        if (isCollectionType) {
            return {
                amount: selectedNft.amount || selectedNft.currentPrice || 0,
                contractAddress: selectedNft.contractAddress || "",
                tokenId: selectedNft.tokenId || "",
                name: selectedNft.name || "",
                image: selectedNft.imageUrl || "",
            };
        }

        const urlDetails = getNftUrlDetails({ url: details.url });

        return {
            amount: details.amount || details.currentPrice || 0,
            contractAddress: urlDetails.contractAddress || "",
            tokenId: urlDetails.tokenId || "",
            name: details.name || "",
            image: details.image || "",
        };
    }, [details, selectedNft, isCollectionType]);

    const isValidPrice = useMemo(() => {
        const nftPrice = nftDetails?.amount || 0;
        const contribution = details?.goal?.collectors?.[0]?.totalContribution || details?.contributionGoal?.amount || details?.contributionGoal || 0;

        // Calculate the condition: nft price + 2.5% of nft price <= totalContribution
        const netNftPrice = calculateFeePlusPrice({ value: nftPrice });
        const conditionMet = netNftPrice ? netNftPrice <= contribution : false;

        return conditionMet;
    }, [nftDetails, details]);

    // const isBuyNftPage = useMemo(() => {
    //     return window.location.href.includes(constants.BROWSER_ROUTES.BUY_NFT);
    // }, []);

    // const isDisabledSubmit = !details.collectiveId ||
    //     !(Object.keys(nftDetails)?.length && nftDetails.tokenId) ||
    //     isSubmitting ||
    //     (
    //         isBuyNftPage ||
    //         (!isBuyNftPagest isDisabledSubmit = !details.collectiveId ||
    //     !(Object.keys(nftDetails)?.length && nftDetails.tokenId) ||
    //     isSubmitting ||
    //     (
    //         isBuyNftPage ||
    //         (!isBuyNftPage && (details.collectiveType === constants.COLLECTIVE.TYPE.COLLECTION) && (buyNftList.length === 1)) ||
    //         details.collectiveType === constants.COLLECTIVE.TYPE.SPECIFIC_NFT
    //     )
    //     ? !isValidPrice
    //     : false; && (details.collectiveType === constants.COLLECTIVE.TYPE.COLLECTION) && (buyNftList.length === 1)) ||
    //         details.collectiveType === constants.COLLECTIVE.TYPE.SPECIFIC_NFT
    //     )
    //     ? !isValidPrice
    //     : false;
    const isDisabledSubmit = !details.collectiveId ||
        !(Object.keys(nftDetails)?.length && nftDetails.tokenId) ||
        isSubmitting;

    const submitProps = {
        collectiveCrowdfundAddress: details.contractAddress || '',
        data: {
            collectiveId: details.collectiveId,
            contractAddress: nftDetails.contractAddress || "",
            tokenId: nftDetails.tokenId || "",
            name: nftDetails.name || "",
            image: nftDetails.image || "",
            amount: nftDetails.amount || details.currentPrice || details.amount || 0,
        },
        isSubmitting: isSubmitting || commonReducer.loadProgress?.isBuyNftLoading,
        isDisabledSubmit,
        isValidPrice,
        onIsSubmitting: handleIsSubmitting
    };

    return <Component
        {...props}
        buttonConfig={buttonConfig}
        isCollectiveDetailPage={isCollectiveDetailPage}
        submitProps={submitProps}
        buyNftList={buyNftList}
        onProceedToBuyNft={handleProceedToBuyNft}
    />;
}

BuyNft.defaultProps = {
    details: {},
    buyNftList: [],
};

export default BuyNft;
