import * as ethers from "ethers";
import { constants } from "../helpers";

/**
 * Abbreviates a large number with appropriate suffix (k, m, b, t).
 *
 * @param {number} number - The number to be abbreviated.
 * @returns {string} - The abbreviated number with a suffix.
 */
export function abbreviateNumber(number) {
  const abbreviations = {
    k: 1000,
    m: 1000000,
    b: 1000000000,
    t: 1000000000000,
  };

  for (let symbol in abbreviations) {
    if (number >= abbreviations[symbol]) {
      const roundedNumber = number / abbreviations[symbol];
      const decimalPlaces = Math.floor(roundedNumber) !== roundedNumber ? 1 : 0;
      const abbreviatedNumber =
        roundedNumber.toFixed(decimalPlaces).replace(/\.0$/, "") +
        symbol.toUpperCase();
      return abbreviatedNumber;
    }
  }

  return number.toString();
}

function getExponentialValue({ limit }) {
  switch (limit) {
    case constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE:
      return 1e2;
    case constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY:
      return 1e7;
    case constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION_GOAL:
      return 1e3;
    case constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION:
      return 1e7;
    default:
      return 1e18;
  }
}

export function truncateToDecimalDigits({ number, limit }) {
  // Convert string input to a number if it's a string
  if (typeof number === "string") {
    number = parseFloat(number);
    if (isNaN(number)) {
      // Handle invalid string input gracefully, e.g., return NaN or throw an error
      return number;
    }
  }

  if (typeof number !== "number") {
    // Handle non-numeric input gracefully, e.g., return NaN or throw an error
    return number;
  }

  // Check if the limit is less than or equal to the number of decimal places
  const decimalPlaces = countDecimalPlaces(number);

  if (limit >= decimalPlaces) {
    return number; // No truncation needed
  }

  if (String(number).includes("e-")) {
    const value = number.toFixed(
      constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION
    );

    const list = value.split(".");

    return `${list[0]}${list[1] ? "." : ""}${list[1].slice(0, limit)}`;
  }

  const exponentialValue = getExponentialValue({ limit });

  // Shift the decimal point to the right by 2/7/18 digits (truncate)
  const truncatedNumber =
    Math.trunc(number * exponentialValue) / exponentialValue;

  return truncatedNumber;
}

// Helper function to count the number of decimal places
function countDecimalPlaces(number) {
  if (!isFinite(number)) return 0;

  const strNumber = String(number);
  const decimalIndex = strNumber.indexOf(".");
  return decimalIndex === -1 ? 0 : strNumber.length - decimalIndex - 1;
}

/**
 * Sets and formats a decimal value with a specified limit of decimal places.
 *
 * @param {Object} options - The options object.
 * @param {number} options.value - The value to be formatted.
 * @param {number} [options.limit=18] - The limit of decimal places (optional).
 * @param {boolean} [options.formatted] - Whether to apply additional formatting (optional).
 * @returns {string|number} - The formatted decimal value or 0 if an error occurs.
 */
export function setDecimalValue({
  value,
  limit,
  formatted = true,
  isBigNumber,
}) {
  try {
    const parsedValue = Number(value);
    const isValid = !isNaN(parsedValue) && parsedValue >= 0; // Allow 0 as well

    if (!isValid) return 0;

    // Convert the parsed value to a fixed number of decimal places using toFixed
    let fixedValue = parsedValue.toFixed(
      limit || constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION
    );

    if (isBigNumber) {
      const minValue2 = parsedValue
        ? ethers.utils.parseEther(
            String(
              parsedValue.toFixed(
                constants.COLLECTIVE.DECIMAL_LIMIT.CONTRIBUTION
              )
            )
          )
        : 0;

      const parsedFixedValue = ethers.utils.parseEther(fixedValue);

      if (parsedFixedValue.lt(minValue2)) {
        const adjustedValue = parsedFixedValue.add(
          ethers.BigNumber.from("100000000000")
        );
        fixedValue = ethers.utils.formatEther(adjustedValue);
      }
    }

    // Remove trailing zeroes using a regular expression
    const formattedValue = formatted
      ? formattedDecimalValue(fixedValue)
      : fixedValue;

    return formattedValue;
  } catch (err) {
    console.log("setDecimalValue-err", err);
    return 0;
  }
}

/**
 * Removes trailing zeroes from the decimal portion of a numeric value.
 *
 * @param {number|string} value - The value to be formatted.
 * @returns {string|number} - The formatted value or the original value if an error occurs.
 */
export function formattedDecimalValue(value) {
  try {
    const str = value.toString();

    if (str.includes(".")) {
      return parseFloat(str.replace(/\.?0+$/, ""));
    }

    return str;
  } catch (err) {
    console.log("formattedDecimalValue-err", err);
    return value;
  }
}

export function calculateFeePlusPrice({ value }) {
  try {
    const parsedValue = Number(value);
    const isValid = !isNaN(parsedValue) && parsedValue >= 0; // Allow 0 as well

    if (!isValid) return 0;

    const parsedFixedValue = ethers.utils.parseEther(String(value));

    const fee = parsedFixedValue
      .mul(ethers.BigNumber.from(25))
      .div(ethers.BigNumber.from(1000));

    return Number(ethers.utils.formatEther(parsedFixedValue.add(fee)));
  } catch (err) {
    console.log("calculateFeePlusPrice-err", err);
    return 0;
  }
}
