export function isPastTime({ timestamp }) {
  // Get the current timestamp in milliseconds
  const currentTimestamp = Date.now();

  return timestamp < currentTimestamp;
}

export function gmtOffsetToUTC(offset) {
  const absoluteOffset = Math.abs(offset);
  const hours = Math.floor(absoluteOffset / 60);
  const minutes = absoluteOffset % 60;

  const formattedOffset = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}`;
  return formattedOffset;
}
