/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { Logo } from '../../../../../assets/images/images';
import { constants } from '../../../../../helpers';
import FormikWrapper from '../../../formik-wrapper';
import InputField from '../../../input-field';

function ForgotPasswordComponent({ isOpen, initialState, schema, onClose, onOpenModal, onContinue }) {
    return <FormikWrapper
        enableReinitialize
        initialValues={initialState}
        validationSchema={schema}
        onSubmit={onContinue}
    >
        {({ values, errors, isSubmitting, touched, handleSubmit }) => {
            const isDisabledSubmit = !values.email || isSubmitting;

            return <Form>
                <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
                    <Modal.Header closeButton>
                        <figure><img src={Logo} alt="Logo" /></figure>
                    </Modal.Header>

                    <Modal.Body>
                        <h5 className='m6'>Forgot Password</h5>
                        <p className='m20 text-text'>Provide your account’s email for which you want to reset the password.</p>
                        <InputField
                            name='email'
                            label='Email'
                            required
                            placeholder="Enter Email Address"
                            errors={errors}
                            touched={touched}
                        />
                    </Modal.Body>

                    <Modal.Footer>
                        <div className='btns'>
                            <button className='btn-white lg back-modal' onClick={() => {
                                onOpenModal(constants.MODAL.LOGIN);
                            }}>
                                Back
                            </button>
                            <button className='btn lg' disabled={isDisabledSubmit} onClick={handleSubmit}>
                                Continue
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </Form>;
        }}
    </FormikWrapper>;
}

export default ForgotPasswordComponent;
