/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { constants } from '../../../../../helpers';
import { onToggleModal } from '../../../../../redux/features/commonSlice';
import { userLogin } from '../../../../../redux/services/auth';
import { capitalizeFirstLetter, successAlert } from '../../../../../utils';
import { useAppDispatch, useAppSelector, useFingerprint, useRefreshPage } from '../../../../custom-hooks';
import LoginComponent from './Component';

const initialState = {
    name: '',
    email: '',
    password: '',
    isShowingPassword: false
};

function Login(props) {
    const { address } = useAccount();
    const { disconnect } = useDisconnect();
    const dispatch = useAppDispatch();
    const deviceId = useFingerprint();
    const commonReducer = useAppSelector(state => state.common);
    const { referral } = commonReducer?.modal || {};
    const { onRefreshViewCollectivePage } = useRefreshPage();

    const [detail, setDetails] = useState(JSON.parse(JSON.stringify(initialState)));

    const handleSubmit = useCallback((data, { setSubmitting, setErrors }) => {
        // disabled save changes before trigger api call
        setSubmitting(true);

        const request = {
            "email": data.email,
            "password": data.password,
            "deviceId": deviceId,
            "deviceToken": "string",
            notify: false,
            callback: (response) => {
                if (response?.statusCode === 200) {
                    if (address && (response.data?.walletAddress !== address)) {
                        disconnect();
                    }
                    dispatch(onToggleModal());

                    // setTimeout(() => {
                    //     if (window.location.href === constants.BROWSER_ROUTES.DISCOVER) {
                    //         navigate(constants.BROWSER_ROUTES.DISCOVER);
                    //     }
                    // }, 3000);

                    if (response.message) {
                        successAlert(response.message);
                    }

                    setTimeout(() => {
                        if (window.location.href.includes(constants.BROWSER_ROUTES.VIEW_COLLECTIVE)) {
                            onRefreshViewCollectivePage();
                        }
                    }, 1000);

                } else if (response?.data?.type === 'EMAIL_NOT_REGISTERED') {
                    setErrors({ email: capitalizeFirstLetter(response.data.message) });
                } else if (response?.data?.type === "INCORRECT_PASSWORD") {
                    setErrors({ password: capitalizeFirstLetter(response.data.message) });
                } else if (response?.data?.type === "EMAIL_NOT_VERIFIED_YET") {
                    setErrors({ email: capitalizeFirstLetter(response.data.message) });
                }
                // enabled save changes after api success
                setSubmitting(false);
            }
        };
        dispatch(userLogin(request));
    }, [deviceId, dispatch, address, disconnect, onRefreshViewCollectivePage]);

    useEffect(() => {
        if (referral && Object.keys(referral).length) {
            setDetails({
                ...detail,
                email: referral.email,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referral]);

    return <LoginComponent
        {...props}
        commonReducer={commonReducer}
        detail={detail}
        dispatch={dispatch}
        onSubmit={handleSubmit}
        deviceId={deviceId}
    />;
}

export default Login;
