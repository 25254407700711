/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { Verify } from '../../../../../assets/images/images';
import { constants } from '../../../../../helpers';
import ValidationMessage from '../../../validation-message';

function Component({ isOpen, otp, canResendOtp, email, otpError, onClose, onSetOtp, onVerifyOtp, onResendOtp, onOpenModal, onForceReRender }) {
    return <Modal className='onboarding' show={isOpen} onHide={onClose} backdrop="static">
        <Modal.Header closeButton>
            <figure><img src={Verify} alt="Logo" /></figure>
        </Modal.Header>

        <Modal.Body>
            <h5 className='m6'>Verify Your Email</h5>
            <p className='m20 text-text'>Please enter the 4 digit code we have sent to email address- <b>{email}</b></p>
            <div className='otp-input'>
                <OtpInput
                    value={otp}
                    onChange={onSetOtp}
                    numInputs={4}
                    renderInput={(props) => <input {...props} />}
                />
            </div>
            {!!(otpError) && <ValidationMessage message={otpError} />}
        </Modal.Body>

        <Modal.Footer>
            <div className='btns'>
                <button
                    className='btn-white lg back-modal'
                    onClick={() => {
                        onOpenModal(constants.MODAL.UPDATE_EMAIL);
                        onForceReRender();
                    }}
                >
                    Back
                </button>
                <button className='btn lg' disabled={otp.length !== 4} onClick={onVerifyOtp}>
                    Verify OTP
                </button>
            </div>
            <p className='mt16 text-text'>Didn’t get a code ? {" "}
                <a
                    href='javascript:;'
                    className={`link ${canResendOtp ? '' : 'disabled'}`}
                    onClick={onResendOtp}
                >
                    Resend OTP
                </a>
            </p>
        </Modal.Footer>
    </Modal>;
}

export default Component;
