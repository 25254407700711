function MultipleLinesTextView({ text, placeholder }) {
    const textArray = text?.trim().length ? text.split("\n") : [];

    return <>
        {textArray?.length ? (
            <p>
                {textArray.map((str) => {
                    return (
                        <p>
                            {str} <br />
                        </p>
                    );
                })}
            </p>
        ) : (
            <p style={{ color: "gray" }}>{placeholder}</p>
        )}
    </>;
}

export default MultipleLinesTextView;
