function UtilitiesView({ utilities, values, onSetFieldValue }) {
    return utilities.map(utility => {
        return <div className='checks'>
            <div
                className='checkbox'
                onChange={() => {
                    const temp = values.utilityAvailable.slice();
                    const index = temp.findIndex(item => item === utility.value);
                    if (index > -1) {
                        temp.splice(index, 1);
                    } else {
                        temp.push(utility.value);
                    }
                    onSetFieldValue('utilityAvailable', temp);
                }}
            >
                <input
                    type="checkbox"
                    checked={!!values.utilityAvailable.find(item => item === utility.value)}
                />
                <label>{utility.label}</label>
            </div>
        </div>;
    });
}

export default UtilitiesView;
