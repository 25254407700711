import { useCallback } from 'react';
// import { useDisconnect } from 'wagmi';
import { postDataApi } from '../../../../../api/methods';
import { useAppDispatch, useAppSelector,useAppNavigate } from '../../../../../common/custom-hooks';
import { constants } from '../../../../../helpers';
import { clearReduxState } from '../../../../../redux/features/authSlice';
import { clearCommonReducerState } from '../../../../../redux/features/commonSlice';
import { localStorageRemove, successAlert } from '../../../../../utils';
import ProfileSettingComponent from './Component';

function ProfileSettings() {
    // const { disconnect } = useDisconnect();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate()
    const { isloggedin } = useAppSelector(state => state.auth);

    const handleOnSelect = useCallback((event) => {
        // user logout
        if (event === constants.PROFILE.SETTING.LOGOUT.key && isloggedin) {
            postDataApi({
                path: constants.API_PATHS.LOGOUT_USER,
                data: {
                    callback: (response) => {
                        if (response?.statusCode === 200) {
                            dispatch(clearReduxState());
                            dispatch(clearCommonReducerState());
                            navigate(constants.BROWSER_ROUTES.DISCOVER);
                            localStorageRemove("profileTabs")
                            // disconnect();
                            if (response.message) {
                                successAlert(response.message);
                            }
                        }
                    }
                }
            });
        }
    }, [dispatch, navigate, isloggedin]);

    return <ProfileSettingComponent onSelect={handleOnSelect} />;
}

export default ProfileSettings;
