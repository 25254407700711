import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

const Handle = Slider.Handle;

const RangeSlider = ({
    min,
    max,
    value,
    step,
    disabled,
    backgroundColor,
    onChange
}) => {
    const handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={value}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    const trackStyle = {
        backgroundColor, // Customize the color of the line
        height: 12, // Customize the height of the line
    };

    const railStyle = {
        backgroundColor: "#DEDEDE", // Customize the color of the line
        height: 12, // Customize the height of the line
    };

    const handleStyle = {
        backgroundColor,
        borderColor: backgroundColor, // Customize the border color of the handle
        height: 20, // Customize the height of the handle
        width: 15, // Customize the width of the handle
        borderRadius: 4, // Customize the border radius to make it rectangular
    };

    return <div>
        <Slider
            min={min}
            max={max}
            value={value}
            step={step}
            trackStyle={trackStyle}
            railStyle={railStyle}
            handleStyle={handleStyle}
            disabled={disabled}
            handle={handle}
            onChange={onChange}
        />
    </div>;
};

RangeSlider.defaultProps = {
    step: 1,
    min: 1,
    max: 100,
};

export default RangeSlider;
