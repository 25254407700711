import { Modal, ModalBody, ProgressBar } from 'react-bootstrap';
import { ImageNotFound } from '../../../../../assets/images/images';
import { constants } from '../../../../../helpers';
import { calculateRemainingTime, setDecimalValue, truncateToDecimalDigits } from '../../../../../utils';
import ContributeSimilarCollective from '../../../../wallet-transactions/claim/contributeSimilarCollective';
import { utils } from 'ethers';
import Loader from '../../../loader';

function Component({ isOpen, onClose, details, collectiveList, selected, onSelectCollective
    , votingDelegationId, isSubmitting, onIsSubmitting, currentPage, onLodeMore, isLoading }) {
    const contributedAmount = selected?.contributionGoal?.amount && setDecimalValue({ value: (selected?.contributionGoal?.amount * (20 - selected?.contributeData?.userContributionPercent) / 100) });

    const submitProps = {
        data: {
            selectedUser: {
                votingDelegationId: votingDelegationId
            },
            contribute: (contributedAmount > details?.goal?.myClaim) ? details?.goal?.myClaim : contributedAmount
        },
        collectiveCrowdfundAddress: details.contractAddress || '',
        amount: details?.goal?.myClaim ? utils.parseEther(String(details?.goal?.myClaim)) : '',
        isDisabledSubmit: isSubmitting || !selected,
        isCollectiveType: details?.collectiveType === constants.COLLECTIVE.TYPE.COLLECTION,
        isSubmitting,
        onIsSubmitting,
    };


    return (
        <Modal className='contribute-another' show={isOpen} onHide={onClose} backdrop="static">
            <Modal.Header className='pb0' closeButton>
                <div>
                    <h6 className='f20 mb4'>Contribute to another collective</h6>
                    <p className='lg text-text'>Select another collective from list</p>
                </div>

                <div className='ml-auto bg-grey'>
                    <h6><i className='etherium-icon md'></i> {details?.goal?.myClaim ? truncateToDecimalDigits({
                        number: details?.goal?.myClaim,
                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                    }) : '-'}</h6>
                    <p className='text-text fw500'>Available ETH</p>
                </div>
            </Modal.Header>

            <ModalBody>
                <div className='nft-another'>
                    {collectiveList?.list?.length > 0 && !isLoading && collectiveList.list.map((data) => (
                        <div onClick={() => onSelectCollective(data)} key={data?._id} >
                            <div className={data?._id === selected?._id ? 'nft-card active' : "nft-card"}>
                                <figure className='img'><img src={data?.image || ImageNotFound} alt="Nft1" /></figure>

                                <div className='text'>
                                    <figure><img src={data?.featured_image_url || ImageNotFound} alt="smLogo" /></figure>

                                    <div className='title'>
                                        <div className='title-name'>
                                            <p className='fw600'>{data?.title}</p>
                                            <span className='by-name'>with <b className='fw400'>{data?.userData?.userName}</b></span>
                                        </div>
                                    </div>

                                </div>
                                <div className="mb12">
                                    <p><i className="etherium-icon mr4 va-1"></i>{data?.contributionGoal?.amount ? truncateToDecimalDigits({
                                        number: data?.contributionGoal?.amount,
                                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                                    }) : '0'}{' '}{data?.contributionGoal?.type}</p>
                                </div>

                                <div className='card-details'>
                                    <div className='progress-div'>
                                        <p>{data?.contributeData?.contributionPercent
                                            ? Number.isInteger(data.contributeData.contributionPercent) === true
                                                ? data.contributeData.contributionPercent
                                                : truncateToDecimalDigits({
                                                    number: data.contributeData.contributionPercent,
                                                    limit: constants.COLLECTIVE.DECIMAL_LIMIT.PERCENTAGE
                                                })
                                            : '-'
                                        }% Contributed</p>
                                        <ProgressBar now={data?.contributeData?.contributionPercent || 0} />
                                    </div>

                                    <div className='lower'>
                                        <p><i className=''></i> <span>{calculateRemainingTime(
                                            {
                                                created: data?.created,
                                                period: data?.contributionPeriod,
                                                // isCompleted: data?.status === constants.COLLECTIVE.STATUS.ACQUIRED
                                                endTimeStamp: data?.collective_close_timing
                                            }
                                        )}</span> Left</p>
                                        <p><i className='etherium-icon'></i>
                                            <span>{data.status === constants.COLLECTIVE.STATUS.ACQUIRED
                                                ? '0'
                                                : data?.contributionGoal.amount ?
                                                    truncateToDecimalDigits({
                                                        number: setDecimalValue({ value: data?.contributionGoal?.amount - data?.contributeData?.totalContribution }),
                                                        limit: constants.COLLECTIVE.DECIMAL_LIMIT.DISPLAY
                                                    })
                                                    : '-'
                                            }</span> Left</p>

                                        <p><span>{data?.contributeData?.totalCollectors}</span> collectors</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                {/* {collectiveList?.list?.length === 0 &&
                    <div className='text-center'><h6 className='m12'>No Collective Found</h6></div>
                } */}

                {isLoading ?
                    <Loader />
                    : (collectiveList?.list?.length === 0 && !isLoading) ? <div className='text-center'><h6 className='m12'>No Collective Found</h6></div>
                        : ""
                }

                {((collectiveList.total !== currentPage) && collectiveList?.list?.length > 0) &&
                    <div className='viewMore_div'>
                        <p role='button' className='link' onClick={onLodeMore}>View More</p>
                    </div>
                }
                {/* <button className="btn lg w-100 mb18" disabled={selected?._id === undefined} onClick={onContributionModal}>Contribute</button> */}
            </ModalBody>
            <div className='modal-float-btn'><ContributeSimilarCollective {...submitProps} details={details} selectedCollectiveDetails={selected} /></div>
        </Modal>
    );
}

export default Component;
